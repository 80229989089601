import {
    find,
    max,
    findIndex,
    isNumber,
    head,
    isEmpty,
    omit,
    mapValues,
    isArray,
    cloneDeep,
    includes,
    dropRightWhile,
    isNull,
    union,
    difference,
    indexOf,
    take,
    times,
    isUndefined,
    partition,
    flow,
    map,
    intersection,
    zipWith,
    flatten,
    size,
    compact,
    flatMap,
    reduce,
    isObject,
    each,
    some,
    every,
    get,
    remove,
} from "lodash/fp";
import {v4 as uuidv4} from "uuid";
import log, {serializeError} from "@atg-shared/log";
import type {GameType} from "@atg-horse-shared/game-types";
import {GameTypes, COMBINATION_GAMES} from "@atg-horse-shared/game-types";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
import type {Coupon, VXYCoupon} from "@atg-horse-shared/coupon-types";
import {CouponTypes} from "@atg-horse-shared/coupon-types";
import type {BaseStart} from "@atg-horse-shared/racing-info-api/game/types";
import * as StartUtils from "@atg-horse-shared/utils/start";
import * as GameUtils from "@atg-horse-shared/utils/game";
import {getStartNumberFromId} from "@atg-horse-shared/utils/start";
import {
    parseRaceId,
    getStartById,
    getTracks,
    getRaces,
} from "@atg-horse-shared/utils/game";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    isReductionValuesSet,
    isOrderChanged,
    hasSetRestrictions,
    DEFAULT_RESERVE_TYPE,
    ReducedCoupon,
} from "@atg-horse/reduced-bets";
import {isReducedCouponTypes} from "@atg-horse-shared/utils/bet/betUtils";
import {
    getGameType,
    getGameTypefromCouponGame,
} from "@atg-horse-shared/utils/game/v3Utils";
// eslint-disable-next-line import/no-cycle
import CouponDefs from "../coupon-defs/couponDefs";
import {mapToSystem, findSystemBasedOnProperty} from "./top7SystemUtils";
import * as HarryBetLimitConverters from "./harry/harryBetLimitConverters";

export const MIN_TRIO_FLEX_VALUE = 10;
export const MAX_TRIO_FLEX_VALUE = 80000;

/**
 * Max system size (<number of coupons> * <system multiplier>) allowed to place a file bet or
 * reduced bet.
 */
export const systemsLimit: {[key in keyof Partial<typeof GameTypes>]: number} = {
    V75: 5000,
    V86: 5000,
    GS75: 5000,
    V64: 2000,
    V65: 2000,
    V5: 500,
    V4: 500,
    V3: 500,
};

/**
 * Generate a coupon client ID.
 */
export const nextCid = uuidv4;

const betAttributes = [
    "betMethod",
    "cost",
    "bets",
    "reserves",
    "baseBets",
    "firstPlaceBets",
    "secondPlaceBets",
    "thirdPlaceBets",
];

function getHorseData(start: BaseStart) {
    if (!start) return null;

    const horse = get("horse", start);
    return {
        number: get("number", start),
        horse: {
            id: get("id", horse),
            name: get("name", horse),
            nationality: get("nationality", horse),
        },
    };
}

export function withGameData(game: any, bet: any) {
    const gameType = game.type;
    const gameRaces = getRaces(game);

    /*
     * !bet.races was added due to Varenne.
     * When fetching games on the page /harryboy previously no race info was returned in the response.
     * This had to be added to the harry games request since the receipt is dependent on this data which was received in the response when placing the bet.
     * The guidelines for the GraphQL BE does not allow this kind of data in the resonse so an alternative solution had to be found.
     * So now there is a scenario where we do have gameRaces but still no races on the bet itself.
     */
    if (!gameRaces || !bet.races) {
        // todo: harry specific
        return {
            ...bet,
            game: {
                id: game.id,
                type: gameType,
                tracks: getTracks(game),
                startTime: game.startTime,
            },
        };
    }

    const races = bet.races.map((betRace: any) => {
        const race: any = find({id: betRace.id}, gameRaces);
        const {starts} = race;

        betAttributes.forEach((attribute) => {
            const bets = betRace[attribute];
            if (!bets) return;

            betRace[attribute] = bets.map((startId: number | string) => {
                const start = isNumber(startId)
                    ? find({number: startId}, starts)
                    : getStartById(game, startId);
                return getHorseData(start);
            });
        });

        return {
            ...betRace,
            raceNumber: parseRaceId(betRace.id).raceNumber,
        };
    });

    return {
        ...bet,
        races,
        game: {
            id: game.id,
            startTime: head(gameRaces)?.startTime,
            type: gameType,
            tracks: getTracks(game),
        },
    };
}

export function addCidToCoupon(coupon: any, cid: string) {
    coupon.cid = cid;
    if (coupon.races) {
        coupon.races.forEach((race: any) => {
            race.cid = cid;
        });
    }
    return coupon;
}

export function createBet(coupon: Coupon, game: any) {
    // Varenne V3 Revert this to {CouponDefs[coupon.game.type]} when V3Legacy support is dropped
    const couponDef = CouponDefs[getGameTypefromCouponGame(coupon.game)];
    const {betTransformer} = couponDef;
    const bet = betTransformer(coupon, {
        game,
    })({});

    if (isEmpty(bet.races)) delete bet.races;

    return bet;
}

export function getGameId(coupon: any) {
    return coupon.game.id;
}

/**
 * Given a `gameId` and possibly a `couponId`, find the cid (coupon "client ID") corresponding to
 * the "current" coupon.
 *
 * @deprecated
 * This function is not idempotent, and will create a new `cid` if no coupon could be found. This
 * can cause race conditions with React's render heuristics, causing infinite render loops and such
 * (since we use this function inside `mapStateToProps`). Try to switch to the new idempotent
 * `getCid` version instead.
 */
export function deprecated_getCid(
    state: any,
    gameId: string,
    couponId?: string,
    localOnly = false,
): string {
    if (couponId && state.couponIdToCouponCidMap[couponId]) {
        return state.couponIdToCouponCidMap[couponId];
    }
    const couponCidsForGame = state.couponCidsForGame[gameId];

    return (
        // this line is problematic – use `getCid` instead
        (couponCidsForGame &&
            find(
                (cid) => localOnly === Boolean(state.coupons[cid].localOnly),
                couponCidsForGame,
            )) ||
        nextCid()
    );
}

function createCouponRace(cid: string, race: any, couponRaceTemplate: any) {
    return {
        ...couponRaceTemplate,
        cid,
        id: race.id,
    };
}

export function createCoupon(cid: any, game: any, localOnly = false, type = "PRIVATE") {
    const {gameType = game.type ?? "", date} = parseGameId(game.id) || {};

    // Varenne V3 Revert this to {CouponDefs[gameType as GameType]} when V3Legacy support is dropped
    const couponTemplate = CouponDefs[getGameType(game) as GameType]?.coupon;
    const baseCoupon = omit(["races"], couponTemplate);

    const couponRaces = map(
        (race) => createCouponRace(cid, race, couponTemplate?.races),
        game.races,
    );

    const coupon = {
        ...baseCoupon,
        game: {
            id: game.id,
            type: gameType,
            date,
        },
        races: couponRaces,
        cid,
        amountSelector: cid === "hotGame",
        type,
    };

    // @ts-expect-error localOnly does not exist in coupon
    if (localOnly) coupon.localOnly = true;
    return coupon;
}

export const createHarryCoupon = ({
    cid,
    game,
    localOnly = true,
    harryBetLimit,
    stake,
    boost,
}: any) => {
    const baseCoupon = createCoupon(cid, game, localOnly);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useHarryBoy(baseCoupon, {
        harryBetLimit,
        stake,
        boost,
        harryFlavour: game.harryFlavour,
    });
};

/**
 * Exposing a method in order to unify how we detect if the coupon is new (ie not synced to the server)
 * There might be several approaches (e.g. adding an isNewCoupon flag on coupon creation, etc)
 * For now we are choosing to check if it has the id added in the backend
 */
export function isNew(coupon: any) {
    return !coupon.id;
}

/**
 * Creates a race object compatible with reduced races.
 * Also sets the race values to a passed default value.
 *
 * @param value - The default value to set to all races
 */
export const addRaceDefaultValues = (value: any, races: any) =>
    map(
        ({id, starts}) => ({
            id,
            starts: reduce((acc, {number}) => ({...acc, [number]: value}), {}, starts),
        }),
        races,
    );

/**
 * Creates a race object compatible with reduced races.
 * Also sets the race values to the bet percentage for a passed gametype.
 */
export const addRaceBetPercentageValue = (gameType: any, races: any) =>
    map(
        ({id, starts}) => ({
            id,
            starts: reduce(
                (acc, {number, pools}) => ({
                    ...acc,
                    [number]: pools?.[gameType]?.betDistribution || 0,
                }),
                {},
                starts,
            ),
        }),
        races,
    );

/**
 * define odds thresholds for the letter selection
 */
const A_BET_DISTRIBUTION_THRESHOLD = 3000;
const B_BET_DISTRIBUTION_THRESHOLD = 1000;

const getLetterFromBetDistribution = (betDistribution: number) => {
    if (betDistribution >= A_BET_DISTRIBUTION_THRESHOLD) return "A";
    if (betDistribution >= B_BET_DISTRIBUTION_THRESHOLD) return "B";
    return "C";
};

const A_ODDS_THRESHOLD = 300;
const B_ODDS_THRESHOLD = 1000;

const getLetterFromOdds = (odds: number) => {
    if (odds < A_ODDS_THRESHOLD) return "A";
    if (odds < B_ODDS_THRESHOLD) return "B";
    return "C";
};

/**
 * Creates a race object compatible with reduced races.
 * Also sets the race values to the default letter values based on their bet percentage or odds
 */
const addRaceDefaultLetterValues = (races: any, gameType: GameType) =>
    map(
        ({id, starts}) => ({
            id,
            starts: reduce(
                (acc, {number, pools}) => {
                    const odds = pools?.vinnare?.odds ?? 0;
                    const betDistribution = pools?.[gameType]?.betDistribution ?? 0;
                    const letterRank = includes(gameType, [GameTypes.ld, GameTypes.dd])
                        ? getLetterFromOdds(odds)
                        : getLetterFromBetDistribution(betDistribution);
                    return {
                        ...acc,
                        [number]: letterRank,
                    };
                },
                {},
                starts,
            ),
        }),
        races,
    );

const createRanking = (races: any, type: GameType) =>
    map(({starts}) => ReducedCoupon.getDefaultStartRanking(starts, type))(races);

export const createEmptyReductionterms = ({races, type}: any) => ({
    ranking: createRanking(races, type),
    letters: {
        races: addRaceDefaultLetterValues(races, type),
        restrictions: [
            {
                enabled: true,
                letterRestrictions: {
                    A: {max: null, min: null},
                    B: {max: null, min: null},
                    C: {max: null, min: null},
                    D: {max: null, min: null},
                    E: {max: null, min: null},
                },
            },
        ],
    },
    points: {
        races: ReducedCoupon.resortPoints(races, createRanking(races, type)),
        restrictions: {min: null, max: null},
        enabled: true,
    },
    expectedOutcome: {
        races: addRaceBetPercentageValue(type, races),
        enabled: true,
        restrictions: {
            min: null,
            max: null,
        },
    },
    baseSelections: [
        {
            enabled: true,
            restrictions: {
                min: null,
                max: null,
            },
            races: addRaceDefaultValues(false, races),
        },
    ],
    info: null,
    rows: 0,
    stake: 0,
    system: "simple",
    systemId: {},
    teamId: "string",
    userDefinedSystem: true,
    activeSet: 0,
    reserveType: DEFAULT_RESERVE_TYPE.value,
});

export function createCouponFromJSON(couponJson: any) {
    if (!couponJson.races) return couponJson;

    // this happens from time to time, for unknown reasons
    if (!couponJson.game.type) {
        log.error("createCouponFromJSON", {couponJson});
    }

    couponJson.races = couponJson.races.map((couponRace: any) =>
        mapValues((raceAttribute) => {
            const convertStartToStartId = (start: number | Record<string, any>) =>
                start &&
                StartUtils.createStartId(
                    couponRace.id,
                    isNumber(start) ? start : start.number,
                );

            return isArray(raceAttribute)
                ? raceAttribute.map(convertStartToStartId)
                : raceAttribute;
        }, couponRace),
    );
    return couponJson;
}

export function clearCoupon(coupon: any, game: any) {
    const newCoupon = createCoupon(coupon.cid, game);

    const top7System =
        game.type === "top7"
            ? {
                  userDefinedSystem: coupon.userDefinedSystem,
                  systemId: coupon.systemId,
                  stake: coupon.stake,
              }
            : {};

    return updateCoupon(newCoupon, {
        id: coupon.id,
        game: coupon.game,
        ...top7System,
    });
}

/**
 * Creates a new coupon by copying an existing one
 *
 * A copied coupon is a **new coupon**, that has the same horses (and some other things) selected as
 * the coupon it copies.
 */
export function copyCoupon(coupon: any): any {
    const couponWithFilteredFields = omit(
        [
            // server coupon ID – since this is a new coupon we don't want to reuse any existing ID (we will need to sync this new coupon and get a new server id)
            "id",
            "teamId", // the copied coupon also does not need to inherit the readOnly, it's possible to clone a readOnly betted coupon
            "readOnly", // a placed reduced coupon will have a corresponding batchBetId, but we don't want the copy to use this
            "batchBetId",
        ],
        coupon,
    );

    return {
        ...couponWithFilteredFields,
        races: map(
            (race) => ({
                ...race,
                cid: coupon.cid,
            }),
            couponWithFilteredFields.races,
        ),
    };
}

/**
 * Creates a clone of a coupon
 *
 * A cloned coupon still represents the same coupon. If you want a copy – a coupon that just has the
 * same horses selected (plus some other fields) , see `copyCoupon` instead.
 */
export const cloneCoupon = (
    coupon: Coupon | null | undefined,
    {cid = nextCid(), localOnly}: {cid?: string; localOnly?: boolean} = {},
): Coupon => {
    if (!coupon) {
        // @ts-expect-error TODO: does this even happen?
        return {
            cid,
            localOnly,
        };
    }
    const clone = cloneDeep(coupon);
    clone.cid = cid;
    clone.localOnly = localOnly;
    clone.parentCid = coupon?.cid;

    if (clone.races) {
        (clone as VXYCoupon).races.forEach((race: any) => {
            race.cid = cid;
        });
    }

    return clone;
};

export function getBetAttribute(prefix?: string) {
    return prefix ? `${prefix}Bets` : "bets";
}

function getHarryOpenAttribute(prefix?: string) {
    return prefix ? `${prefix}HarryOpen` : "harryOpen";
}

function convertCouponRaceStartIdsToStartNumbers(couponRace: any, betAttribute: string) {
    if (!couponRace[betAttribute]) return;
    couponRace[betAttribute] = couponRace[betAttribute].map(
        (startOrStartId: number | any) => {
            if (!startOrStartId) return null;
            return startOrStartId.number
                ? startOrStartId.number
                : getStartNumberFromId(startOrStartId);
        },
    );
}

/**
 * Prepare the bet object for post to bet api
 */
export const prepareBetForSave = (bet: any) => {
    if (!bet.races) return bet;

    const races = map((race) => {
        const reserves = remove(isNull, race.reserves);
        return {...race, reserves};
    }, bet.races);

    return {...bet, races};
};

export function prepareForSave(coupon: any) {
    try {
        const mutableCoupon = cloneDeep(coupon);

        // Varenne V3 Revert this to {CouponDefs[coupon.game.type as GameType]} when V3Legacy support is dropped
        const {prefixes} = CouponDefs[getGameTypefromCouponGame(coupon.game) as GameType];
        each((couponRace) => {
            prefixes.forEach((prefix) => {
                const betAttribute = getBetAttribute(prefix);
                convertCouponRaceStartIdsToStartNumbers(couponRace, betAttribute);
            });
            convertCouponRaceStartIdsToStartNumbers(couponRace, "reserves");
        }, mutableCoupon.races);
        return mutableCoupon;
    } catch (error: unknown) {
        log.warn("coupon:prepareForSave failed", {error: serializeError(error), coupon});
        return null;
    }
}

export function updateRace(coupon: any, raceId: any, raceAttributes: any) {
    const raceIndex = findIndex((race: any) => race.id === raceId, coupon.races);
    const races = [...coupon.races];
    const couponRace = races[raceIndex];
    races[raceIndex] = {
        ...couponRace,
        ...raceAttributes,
    };

    return updateCoupon(coupon, {races});
}

export function updateCoupon(coupon: any, couponAttributes: any) {
    const newCoupon = {
        ...coupon,
        ...couponAttributes,
    };

    const attributes = Object.keys(couponAttributes);

    const shouldChange = some(
        (attribute) => !coupon || coupon[attribute] !== couponAttributes[attribute],
        attributes,
    );
    return shouldChange ? newCoupon : coupon;
}

export function useHarryBoy(
    coupon: any,
    {harryBetLimit, stake, boost, harryFlavour}: any = {},
) {
    return updateCoupon(coupon, {
        betMethod: "harry",
        races: null,
        rows: null,
        harryBetLimit: harryBetLimit || 0,
        stake: stake || 0,
        addOns: boost ? ["boost"] : [],
        harryFlavour,
    });
}

export const isFlexValueValid = (coupon: any): boolean => {
    const {combinations, flexValue} = coupon;
    if (!combinations) return true;

    return flexValue >= MIN_TRIO_FLEX_VALUE && flexValue < MAX_TRIO_FLEX_VALUE;
};

/**
 * Calculate the cost of the boost addon
 *
 * The boost cost is 10% of the total system cost (<cost per coupon> * <user-selected `systems` multiplier>).
 * However each system will always add at least 30 SEK (sv-SE).
 */
export const getBoostCost = (coupon: Partial<Coupon>): number => {
    // If the coupon is already a placed bet, we should always show the actual stored cost to the
    // user, for example on the receipt page. This is important for example if the boost cost
    // calculation changes in the future.
    // @ts-expect-error
    if (coupon.boostCost) return coupon.boostCost;

    const systems = coupon.systems ?? 1;
    const couponCost = coupon.betMethod === "harry" ? coupon.harryBetLimit : coupon.cost;
    // @ts-expect-error
    const singleBetCost = couponCost / systems;

    return (
        // @ts-expect-error will always return a number
        max([
            // 10% of coupon cost, or...
            0.1 * singleBetCost, // ...at least 30 SEK (sv-SE)
            3000,
        ]) * systems
    );
};

/**
 * Calculate the cost of the coupon, including the `systems` multiplier and any selected addons
 */
export const getCostWithAddons = (
    coupon: Pick<Coupon, "addOns" | "harryBetLimit" | "cost" | "betMethod" | "systems">,
): number => {
    const isBoostSelected = includes("boost", coupon.addOns);
    const couponCost = coupon.betMethod === "harry" ? coupon.harryBetLimit : coupon.cost;
    // @ts-expect-error will always return a number
    return isBoostSelected ? couponCost + getBoostCost(coupon) : couponCost;
};

const trimNulls = dropRightWhile(isNull);

export function updateBets(coupon: any, raceId: string, startIds: any, prefix?: string) {
    return updateRace(coupon, raceId, {[getBetAttribute(prefix)]: trimNulls(startIds)});
}

export function updateHarryOpen(
    coupon: any,
    raceId: string,
    prefix?: string,
    harryOpen?: boolean,
) {
    const harryOpenAttribute = getHarryOpenAttribute(prefix);
    return updateRace(coupon, raceId, {[harryOpenAttribute]: harryOpen});
}

export function updateReserves(coupon: any, raceId: string, reserves: Array<any>) {
    return updateRace(coupon, raceId, {reserves});
}

export const updateCouponId = (coupon: any, couponId: string) =>
    updateCoupon(coupon, {id: couponId});

export function isSelected(
    couponRace: any,
    startId: any,
    prefix?: string,
    raketBetType?: GameTypes.vinnare | GameTypes.plats | string,
) {
    const isStartSelected = includes(startId, couponRace[getBetAttribute(prefix)]);
    if (!raketBetType) return isStartSelected;
    return couponRace.betType === raketBetType && isStartSelected;
}

export function getRaceById(coupon: any, raceId: string) {
    return coupon.races.find((race: any) => race.id === raceId);
}

export function toggleStart(
    coupon: any,
    raceId: string,
    startId: string,
    prefix?: string,
) {
    const currentCouponRace = getRaceById(coupon, raceId);
    const selected = !isSelected(currentCouponRace, startId, prefix);
    const betAttribute = getBetAttribute(prefix);
    const updatedBets = selected
        ? union(currentCouponRace[betAttribute], [startId])
        : difference(currentCouponRace[betAttribute], [startId]);

    return updateBets(
        coupon,
        currentCouponRace.id,
        // @ts-expect-error
        updatedBets.sort(
            // @ts-expect-error
            (a, b) => getStartNumberFromId(a) - getStartNumberFromId(b),
        ),
        prefix,
    );
}

export function toggleReserve(coupon: any, raceId: any, startId: any) {
    const couponRace = getRaceById(coupon, raceId);
    const {reserves} = couponRace;
    let updatedReserves;
    const reserveIndex = indexOf(startId, reserves);

    // @ts-expect-error is always a number
    const isFirstReserve = reserveIndex === 0;
    // @ts-expect-error is always a number
    const isSecondReserve = reserveIndex === 1;

    if (isFirstReserve) {
        updatedReserves = [];
    } else if (isSecondReserve) {
        updatedReserves = [reserves[0]];
    } else if (reserves.length === 2) {
        updatedReserves = [reserves[0], startId];
    } else {
        updatedReserves = reserves.concat(startId);
    }

    return updateReserves(coupon, raceId, updatedReserves);
}

function top7UpdateStartAtIndex(bets: any, index: number, startId: any) {
    const indexDiff = -(bets.length - index);
    const updatedBets =
        indexDiff > 0 ? [...bets, ...times(() => null, indexDiff)] : [...bets];
    updatedBets[index] = startId;
    return updatedBets;
}

/**
 * Given an array of Top7 bets and reserves (max: 7 bets + 2 reserves, update the coupon's `bets`
 * and `reserves` properties.
 */
export function top7UpdateStartOrder(coupon: any, raceId: string, betsAndReserves: any) {
    // @ts-expect-error maxNumberOfBets does not exist
    const {maxNumberOfBets} = CouponDefs[coupon.game.type];
    const updatedCoupon = updateBets(
        coupon,
        raceId,
        betsAndReserves.slice(0, maxNumberOfBets),
    );
    return updateReserves(updatedCoupon, raceId, betsAndReserves.slice(maxNumberOfBets));
}

/**
 * Create a new Top 7 "bet array" with selected horses
 * Examples:
 * - `[1, 3, 4, null, null, null, null]`: 3 horses selected, 4 unselected
 * - `[1]`: 1 horse selected, rest is autofilled
 * - `[1, null, 3, 4, 5, 6, 7, 8, 9]`: 6 of 7 horses selected, 2 reserves
 */
export function top7GetNonSparsedBets(
    bets: any,
    maxNumberOfBets = 0,
): Array<number | null> {
    if (!bets) return bets;
    return take(maxNumberOfBets, [...bets, ...times(() => null, maxNumberOfBets)]);
}

export function top7GetMaxBetsAndReservers(coupon: Coupon, isReserveMode?: boolean) {
    if (coupon && coupon.betMethod === "harry") {
        return {
            maxNumberOfBets: 1,
            maxNumberOfReserves: 0,
        };
    }
    // @ts-expect-error maxNumberOfBets, maxNumberOfReserves does not exist
    const {maxNumberOfBets, maxNumberOfReserves} = CouponDefs[coupon.game.type];
    if (!isUndefined(isReserveMode) && isReserveMode === false) {
        return {
            maxNumberOfBets,
            maxNumberOfReserves: 0,
        };
    }
    return {maxNumberOfBets, maxNumberOfReserves};
}

function top7GetOrderedBets(couponRace: any, coupon: any) {
    const maxNumberOfBets =
        // @ts-expect-error maxNumberOfBets does not exist
        coupon.betMethod === "harry" ? 1 : CouponDefs[coupon.game.type].maxNumberOfBets; // if it is harry the bet can only be one long since it can only contain a `spik`
    const {bets, reserves} = couponRace;
    const nonSparsesBets = top7GetNonSparsedBets(bets, maxNumberOfBets);

    return [...nonSparsesBets, ...reserves];
}

/**
 * Add, remove or move a start inside a Top 7 coupon.
 *
 * `position` can be left empty (`undefined`), in which case the next available (empty) bet position
 * will be used.
 */
export function toggleTop7StartOrder(
    coupon: any,
    raceId: string,
    startId: string,
    position: any,
    harryAndBanker?: any,
) {
    const couponRace = getRaceById(coupon, raceId);
    const betsAndReserves = top7GetOrderedBets(couponRace, coupon); // return all bets ordered with reserves
    const startIndex = betsAndReserves.indexOf(startId);
    if (harryAndBanker) {
        const betToAdd = startIndex === 0 ? null : startId; // If the bet is the current index it should be removed
        return top7UpdateStartOrder(coupon, raceId, [betToAdd]);
    }

    let newPosition = position;

    if (position === undefined) {
        // selected start is already in the bet list, so it should be *toggled off*
        if (startIndex !== -1) {
            newPosition = startIndex + 1;
        } // add the start to the first empty bet if any, otherwise replace the last bet
        else {
            const firstEmptyBet = findIndex(isNull, betsAndReserves);
            newPosition =
                firstEmptyBet !== -1 ? firstEmptyBet + 1 : betsAndReserves.length;
        }
    }
    // Whenever a new horse is selected,
    // it means it doesn exist in any place `index` of the current bet
    if (startIndex === -1) {
        const updatedBets = top7UpdateStartAtIndex(
            betsAndReserves,
            newPosition - 1,
            startId,
        );
        return top7UpdateStartOrder(coupon, raceId, updatedBets);
    }
    const betsWithoutStart = top7UpdateStartAtIndex(betsAndReserves, startIndex, null);

    // Whenever a new horse is removed from a specific position in the race `TOGGLE OFF`
    // this function will return the unselected bet (null value)
    if (startIndex === newPosition - 1) {
        return top7UpdateStartOrder(coupon, raceId, betsWithoutStart);
    }
    // Whenever a horse is selected but already exist in the bet at another index,
    // it will then changed the index to the new selected one and remove the old selected bet
    const updatedBets = top7UpdateStartAtIndex(
        betsWithoutStart,
        newPosition - 1,
        startId,
    );
    return top7UpdateStartOrder(coupon, raceId, updatedBets);
}

export function getNextStartOrder(coupon: any, couponRace: any, isReserveMode: boolean) {
    const bets = top7GetOrderedBets(couponRace, coupon);
    const {maxNumberOfBets, maxNumberOfReserves} = top7GetMaxBetsAndReservers(
        coupon,
        isReserveMode,
    );
    const totalMaxNumberOfBets = maxNumberOfBets + maxNumberOfReserves;
    const nullIndex = bets.indexOf(null);
    const nonNullBets = compact(bets);
    if (nullIndex >= 0 && nonNullBets.length < totalMaxNumberOfBets) return nullIndex + 1;
    if (bets.length < totalMaxNumberOfBets) return bets.length + 1;

    return totalMaxNumberOfBets;
}

export function getStartOrder(
    coupon: Coupon,
    couponRace: any,
    startId: string,
    prefix?: string,
) {
    const startIndex = couponRace[getBetAttribute(prefix)].indexOf(startId);
    if (startIndex > -1) return startIndex + 1;
    // @ts-expect-error maxNumberOfBets does not exist
    const {maxNumberOfBets} = CouponDefs[coupon.game.type];
    const reserveIndex = couponRace.reserves.indexOf(startId);
    return reserveIndex > -1 ? maxNumberOfBets + reserveIndex + 1 : null;
}

export function getStartOrderWithType(coupon: Coupon, startOrder?: number | null) {
    if (!startOrder) return {isSelected: false, isReserve: false};
    // @ts-expect-error maxNumberOfBets does not exist
    const {maxNumberOfBets} = CouponDefs[coupon.game.type];
    if (startOrder <= maxNumberOfBets) {
        return {
            isSelected: true,
            isReserve: false,
            order: startOrder,
        };
    }
    return {
        isSelected: false,
        isReserve: true,
        order: startOrder - maxNumberOfBets,
    };
}

export function getBetsByPrefix(couponRace: any, prefix?: string) {
    return couponRace[getBetAttribute(prefix)] || [];
}

export function areAllHorsesSelected(couponRace: any, starts: any, prefix: string) {
    const selectedStartIds = getBetsByPrefix(couponRace, prefix);

    const [scratchedStarts] = partition((start) => start.scratched, starts);

    const scratchedStartIds = scratchedStarts.map((start) => start.id);

    const selectedOrScratchedNumbers = union(scratchedStartIds, selectedStartIds);
    return selectedOrScratchedNumbers.length === starts.length;
}

export function toggleAllStarts(
    coupon: any,
    raceId: string,
    starts: any,
    prefix: string,
) {
    const couponRace = getRaceById(coupon, raceId);
    const selectedStartIds = getBetsByPrefix(couponRace, prefix);

    const [scratchedStarts, normalStarts] = partition((start) => start.scratched, starts);

    const scratchedStartIds = scratchedStarts.map((start) => start.id);
    const normalStartIds = normalStarts.map((start) => start.id);
    const selectedOrScratchedNumbers = union(scratchedStartIds, selectedStartIds);

    const isAllSelected = selectedOrScratchedNumbers.length === starts.length;

    return isAllSelected
        ? updateBets(coupon, raceId, [], prefix)
        : updateBets(coupon, raceId, normalStartIds, prefix);
}

export function numStartOrderBets(couponRace: any) {
    return compact(couponRace.bets).length;
}

export const isAnySelected = (
    couponRace: any,
    startId: any,
    prefixes: Array<string | undefined>,
): boolean =>
    // @ts-expect-error
    prefixes.reduce((acc, prefix) => acc || isSelected(couponRace, startId, prefix), "");

export function isHarryOpen(couponRace: any, prefix: string) {
    return couponRace[getHarryOpenAttribute(prefix)];
}

export function isReserve(couponRace: any, startId: any) {
    const reserveIndex = indexOf(startId, couponRace.reserves);
    // @ts-expect-error is always a number
    return reserveIndex > -1 ? reserveIndex + 1 : null;
}

export function hasBetsByPrefix(couponRace: any, prefix: string) {
    return getBetsByPrefix(couponRace, prefix).length > 0;
}

export function getDubbelCombinations(coupon: any) {
    const firstRaceBets = coupon.races[0].bets;
    const secondRaceBets = coupon.races[1].bets;

    const permutationsArray: any = [];
    each((firstPlaceStart) => {
        each((secondPlaceStart) => {
            permutationsArray.push([firstPlaceStart, secondPlaceStart]);
        }, secondRaceBets);
    }, firstRaceBets);
    return permutationsArray;
}

export function getKombCombinations(coupon: any) {
    // when user has not picked any marks (harry), coupon.races will be null
    if (!coupon.races) return 0;

    const couponRace = coupon.races[0];
    const {firstPlaceBets, secondPlaceBets} = couponRace;
    const permutationsArray: any = [];

    each((firstPlaceStart) => {
        each((secondPlaceStart) => {
            if (firstPlaceStart !== secondPlaceStart) {
                permutationsArray.push([firstPlaceStart, secondPlaceStart]);
            }
        }, secondPlaceBets);
    }, firstPlaceBets);
    return permutationsArray;
}

export function numKombCombinations(coupon: any) {
    return getKombCombinations(coupon).length;
}

export function numTrioCombinations(coupon: any) {
    // when user has not picked any marks (harry), coupon.races will be null
    if (!coupon.races) return 0;
    const couponRace = coupon.races[0];
    const {firstPlaceBets, secondPlaceBets, thirdPlaceBets} = couponRace;

    const firstPlaceBetCount = firstPlaceBets.length;
    const secondPlaceBetCount = secondPlaceBets.length;
    const thirdPlaceBetCount = thirdPlaceBets.length;
    const totalCombinations =
        firstPlaceBetCount * secondPlaceBetCount * thirdPlaceBetCount;

    if (totalCombinations === 0) return 0;

    let alsoInSecond: any;
    let alsoInThird: any;

    let invalidCombinations = 0;
    firstPlaceBets.forEach((firstPlaceStart: any) => {
        alsoInSecond = includes(firstPlaceStart, secondPlaceBets);
        alsoInThird = includes(firstPlaceStart, thirdPlaceBets);
        // in some cases, eg on Tips page, we get data in a slightly different format
        // so {firstPlaceBets, secondPlaceBets, thirdPlaceBets} will be not arrays of strings, but arrays of objects.
        // in that case we need a different way of checking the presence of an item in an array
        // ideally, we should not even do any calculation for coupons on Tips page. But atm they are stored in the same reducer as other coupons.
        if (typeof firstPlaceStart !== "string") {
            alsoInSecond = some(firstPlaceStart, secondPlaceBets);
            alsoInThird = some(firstPlaceStart, thirdPlaceBets);
        }

        if (alsoInSecond) invalidCombinations += thirdPlaceBetCount;

        if (alsoInThird) invalidCombinations += secondPlaceBetCount;

        if (alsoInSecond && alsoInThird) invalidCombinations -= 2;
    });

    secondPlaceBets.forEach((secondPlaceStart: any) => {
        alsoInThird = includes(secondPlaceStart, thirdPlaceBets);
        // same comment as the one a few lines up.
        if (typeof secondPlaceStart !== "string") {
            alsoInThird = some(secondPlaceStart, thirdPlaceBets);
        }

        if (alsoInThird) invalidCombinations += firstPlaceBetCount;
    });

    return totalCombinations - invalidCombinations;
}

function getCombinedSelectedStarts(
    starts: any,
    numSelected: number,
    index: number,
    pair: any,
    i: number,
    combos = [],
): any {
    if (index === 2) {
        // @ts-expect-error
        combos.push(pair.slice(0));
        return null;
    }

    if (i >= numSelected) {
        return combos;
    }

    pair[index] = starts[i];
    getCombinedSelectedStarts(starts, numSelected, index + 1, pair, i + 1, combos);
    return getCombinedSelectedStarts(starts, numSelected, index, pair, i + 1, combos);
}

function getAllCombos(selectedStarts: any) {
    const data = new Array(2);
    const numSelectedStarts = selectedStarts.length;

    return getCombinedSelectedStarts(selectedStarts, numSelectedStarts, 0, data, 0);
}

function getAllCombosWhenUsingBaseStarts(selectedBaseStarts: any, selectedStarts: any) {
    const combos: any = [];
    selectedBaseStarts.forEach((baseStart: any) => {
        const baseAlsoSelected: any = includes(baseStart, selectedStarts);
        selectedStarts.forEach((start: any) => {
            // Same start?  No combo.
            if (baseStart === start) {
                return;
            }

            // This is a bit of an optimisation on our part to make things a lot simpler.
            //   As our bets & baseBets are in-order, we know that we've already counted this baseCarriage in combinations.
            if (
                includes(start, selectedBaseStarts) &&
                baseAlsoSelected &&
                baseStart <= start
            ) {
                return;
            }
            combos.push([baseStart, start]);
        });
    });
    return combos;
}

export function getTvillingCombinations(coupon: any) {
    // when user has not picked any marks (harry), coupon.races will be null
    if (!coupon.races) return 0;

    const race = coupon.races[0];
    const {bets, baseBets} = race;

    const betCount = bets.length;
    const baseBetCount = baseBets.length;

    if (!betCount) return [];
    if (!baseBetCount) return getAllCombos(bets);

    return getAllCombosWhenUsingBaseStarts(baseBets, bets);
}

export function numTvillingCombinations(coupon: any) {
    return getTvillingCombinations(coupon).length;
}

export function numTop7Combinations(coupon: any, feeBrackets: any = null) {
    if (!feeBrackets) return coupon.stake / 100 / 4;

    return (
        HarryBetLimitConverters.getStakeWithoutFee(coupon.stake, feeBrackets) / 100 / 4
    );
}

export function numDivisionGameRows(coupon: any) {
    if (
        isReducedCouponTypes(coupon.type) &&
        coupon.reductionTerms?.reductionMetadata?.rowsAfterReducing
    ) {
        return coupon.reductionTerms.reductionMetadata.rowsAfterReducing;
    }
    return reduce(
        (numRows, race) => {
            const numRaceBets = race.bets.length;
            if (numRaceBets === 0) return numRows;

            const multiplicator = numRows === 0 ? 1 : numRows;
            return multiplicator * numRaceBets;
        },
        0,
        coupon.races,
    );
}

export function numVpBets(coupon: any) {
    return coupon.races[0].bets.length;
}

/**
 * Joins the coupon, modified timestamp and reductionTerms into one object for syncing
 * @param {Coupon} coupon - our coupon
 * @param {string} modified - a timestamp with modified info
 * @param {ReductionTerms} reductionTerms - reduction terms
 */
export function joinCouponData(coupon: any, modified: any, reductionTerms: any) {
    // ensure that timestamp sent as fnc param is set as modified
    // in some cases we want it to be null or undefined
    let newCoupon = {
        ...coupon,
        modified,
    };

    if (reductionTerms) {
        // comibation games + V3 don't support `expectedOutcome`, and the backend will not accept
        // coupons with this data, so we remove it here
        // Varenne V3 should have both "Reserver" and "Utdelning"
        // Varenne V3 Change this to {includes(coupon.game.type, [...COMBINATION_GAMES])} when V3Legacy support is dropped
        if (
            includes(getGameTypefromCouponGame(coupon.game), [
                ...COMBINATION_GAMES,
                GameTypes.V3Legacy,
            ])
        ) {
            newCoupon = {
                ...newCoupon,
                reductionTerms: flow(
                    omit(["expectedOutcome"]), // `activeSet` is a purely visual state that doesn't need to be persisted
                    omit(["activeSet"]),
                )(reductionTerms),
            };
        } else {
            newCoupon = {
                ...newCoupon,
                reductionTerms: omit(["activeSet"], reductionTerms),
            };
        }
    }

    return newCoupon;
}

export function hasReserves(coupon: any) {
    return some((race) => race.reserves && race.reserves.length > 0, coupon.races);
}

export function isSubscriptionPossible(coupon: any) {
    const {game, betMethod} = coupon;
    if (!game) return false;
    if (betMethod !== "harry") return false;

    const {type} = game;
    if (type !== GameTypes.top7) return true;
    return !isBankerSystem(coupon);
}

export function getTop7Cost(coupon: any) {
    return coupon.betMethod === "harry" ? coupon.harryBetLimit : coupon.stake;
}

export function getSystemId(coupon: any, feeBrackets: any) {
    const cost = getTop7Cost(coupon);
    // @ts-expect-error costToSystemIds does not exist in CouponDefs
    const {costToSystemIds} = CouponDefs[coupon.game.type];
    const costToMap =
        feeBrackets && coupon.betMethod === "harry"
            ? HarryBetLimitConverters.getStakeWithoutFee(cost, feeBrackets)
            : cost;

    const systems = costToSystemIds[costToMap];
    if (!systems) return null;

    if (!coupon.userDefinedSystem) {
        const type = coupon.banker ? "banker" : "default";
        return findSystemBasedOnProperty(systems, type);
    }

    if (coupon.systemId) {
        return coupon.systemId;
    }

    return systems[0];
}

export function isBankerSystem(coupon: any) {
    const {banker} = coupon;
    return banker;
}

export function getSystemPositions(coupon: any, feeBrackets?: any) {
    const systemId = coupon.systemId || getSystemId(coupon, feeBrackets);
    const system = mapToSystem(systemId);

    return flatMap((positions) => times(() => positions, positions.length), system);
}

// Generates a multidimensional array of bets based on Top7 systems
export function getBoxedBets(coupon: any) {
    const {bets} = coupon.races[0];
    const pos = getSystemPositions(coupon);
    return flow(
        map((positions: Array<number>) =>
            positions
                .map((position) => {
                    const start = bets[position - 1];
                    // @ts-expect-error number does not exist in object
                    if (isObject(start)) return start.number;
                    return getStartNumberFromId(start);
                })
                .sort((a, b) => a - b),
        ),
    )(pos);
}

export function getPositionInterval(positions: Array<number>) {
    return {from: positions[0], to: positions[positions.length - 1]};
}

export function getStartNumbers(bets: any) {
    return bets.map((start: any) => {
        // @ts-expect-error number does not exist in object
        if (isObject(start)) return start.number;
        return start;
    });
}

export const isEmptyCoupon = (coupon: any, reductionTerms?: any) => {
    if (coupon.type === CouponTypes.REDUCED && reductionTerms) {
        const hasRestrictions = hasSetRestrictions(reductionTerms);
        const hasSelectedHorses = every((race: any) => race.bets.length === 0)(
            coupon.races,
        );
        const hasSetReductionValues = isReductionValuesSet(reductionTerms);
        const hasChangedOrder = isOrderChanged(reductionTerms);

        if (
            hasRestrictions ||
            hasSelectedHorses ||
            hasSetReductionValues ||
            hasChangedOrder
        )
            return false;
    }

    if (coupon.game.type === "komb") {
        const couponRace = coupon.races[0];

        return (
            couponRace.firstPlaceBets.length === 0 &&
            couponRace.secondPlaceBets.length === 0
        );
    }

    if (coupon.game.type === "trio") {
        const couponRace = coupon.races[0];

        return (
            couponRace.firstPlaceBets.length === 0 &&
            couponRace.secondPlaceBets.length === 0 &&
            couponRace.thirdPlaceBets.length === 0
        );
    }

    if (coupon.game.type === "tvilling") {
        const couponRace = coupon.races[0];

        return couponRace.bets.length === 0 && couponRace.baseBets.length === 0;
    }

    return every((race: any) => race.bets.length === 0)(coupon.races);
};

export const getStartsByBetAttribute = (betAttribute: any) => (couponRace: any) =>
    couponRace[betAttribute];
export const getStartsByPrefix = (prefix: any) =>
    getStartsByBetAttribute(getBetAttribute(prefix));

export const getBets = getStartsByBetAttribute("bets");
export const getBaseBets = getStartsByBetAttribute("baseBets");
export const getReserves = getStartsByBetAttribute("reserves");

export const getMaxBetsForRace = (race: any) => {
    if (!race.starts) return 0;
    const numberOfScratched = GameUtils.getScratchedStartIdsInRace(race.starts).length;
    const numberOfStarts = race.starts.length;
    return numberOfStarts - numberOfScratched;
};

export const getBetsAndReserves = (couponRace: any) =>
    union(getBets(couponRace), getReserves(couponRace));

export const getBetsAndBaseBets = (couponRace: any) =>
    union(getBets(couponRace), getBaseBets(couponRace));

const getCouponRaces = (coupon: any) => coupon.races;

export const getScratchedStartsInCouponRace =
    (getStarts: any) => (couponRace: any, race: any) => {
        const scratchedStarts = GameUtils.getScratchedStartIdsInRace(race.starts);
        const selectedStarts = getStarts(couponRace);

        return intersection(selectedStarts, scratchedStarts);
    };

export const getScratchedStartsInCouponRaceByPrefix = (prefix: any) =>
    getScratchedStartsInCouponRace(getStartsByPrefix(prefix));

export const getScratchedReservesInCouponRace =
    getScratchedStartsInCouponRace(getReserves);

export const getScratchedStarts = (getStarts: any) => (coupon: any, game: any) =>
    zipWith(getScratchedStartsInCouponRace(getStarts))(
        getCouponRaces(coupon),
        getRaces(game),
    );

export function hasScratchedStarts(scratchedStarts: any) {
    return flow(flatten, size)(scratchedStarts) > 0;
}

export const getNumBets = (coupon: any) =>
    reduce((prev, race) => prev + getBetsAndBaseBets(race).length, 0, coupon.races);

export function hasBets(coupon?: any) {
    if (!coupon) return false;

    return getNumBets(coupon) > 0;
}

export function getHarryWarningText(coupon: any) {
    if (coupon.game.type === GameTypes.top7) {
        const couponRace = coupon.races[0];
        const numBets = numStartOrderBets(couponRace);
        const shouldKeepBanker = coupon.banker && numBets;
        if (numBets === 0) return null;

        return shouldKeepBanker
            ? "Din högst rankade häst kommer att placeras som spik. Övriga hästar tas bort från systemet."
            : "Observera att valda markeringar kommer tas bort vid Top7 Autofyll spel.";
    }

    return hasReserves(coupon)
        ? "Observera att reservmarkering inte är möjligt på Harry Boy-spel. Turordning gäller vid strykningar."
        : null;
}
