import {combineReducers, type Reducer} from "redux";
import type {HorseState} from "@atg-horse-shared/horse-state";
import horseFavoritesReducer from "@atg-horse-shared/favorites/domain/horseFavoritesReducer";
import countryReducer from "@atg-horse-shared/country/domain/countryReducer";
import fileBetReducer from "@atg-horse/bet-history-receipts/src/domain/fileBetReducer";
import betSpeedPayoutReducer from "@atg-horse/bet-history-receipts/src/domain/speedPayoutReducer";
import {HarryBoyFeeBracket} from "@atg-horse-shared/coupon";
import harryReducers from "@atg-horse-shared/horse-harry/domain/harryReducer";
import * as harryConfirmReducer from "@atg-horse-shared/horse-harry/domain/harryConfirmReducer";
import {highlightsReducer} from "@atg-horse-shared/highlights";
import quickplayReducer from "@atg-horse-shared/video-domain/src/quickplay/quickplayReducer";
import {hotGamesReducer} from "@atg-horse-shared/hotgames";
import {storeApiReducer} from "../storeApiReducer";

type HorseAsyncType = {
    reducedBets: Reducer<any, any>;
};
export const createHorseReducer = (
    // @ts-expect-error
    asyncReducers: HorseAsyncType = {},
): Reducer<HorseState, any> => {
    const staticReducers = {
        highlights: highlightsReducer,
        hotGames: hotGamesReducer,
        horseFavorites: horseFavoritesReducer,
        proxyFileBet: fileBetReducer,
        speedPayout: betSpeedPayoutReducer,
        quickplay: quickplayReducer,
        country: countryReducer,
        harryBoyFeeBrackets: HarryBoyFeeBracket,
        ...harryReducers,
        ...harryConfirmReducer,
        storeApiReducer,
    };
    return combineReducers<any, any>({...staticReducers, ...asyncReducers});
};

export default createHorseReducer;
