import {findIndex} from "lodash";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type Channel} from "@atg-play-shared/live-channels";
import {PlayerActionTypes} from "./actionconstants";

export enum PlayerKeys {
    WARMUP_PLAYER = "warmupPlayer",
    LIVE_PLAYER = "live",
}

/**
 * Defines which type of menu that is active in the controlbar
 * For now: it should only be one visible menu at one time
 */
export enum ActiveMenu {
    VIDEO_SETTINGS = "video_settings",
    RADAR_MENU = "radar_menu",
}

export type VideoPlayerState = {
    playing: boolean;
    trackCompleted: boolean;
    streams?: any;
    imageURL: string | null;
    channel: Channel | null;
    streamUrl: string | null;
    source: string | null;
    playlist?: any;
    playlistPos: number;
    streamName?: string | null;
    // `live` in players state has a position inside the live state,
    position?: number;
    chromecastPlayer: string | null;
    activeMenu: ActiveMenu | null;
    inFullscreen: boolean;
    quality?: string;
};

export const initialState = () => ({
    playing: false,
    trackCompleted: false,
    streams: null,
    imageURL: null,
    channel: null,
    streamUrl: null,
    source: null,
    playlist: null,
    playlistPos: 0,
    streamName: null,
    chromecastPlayer: null,
    activeMenu: null,
    inFullscreen: false,
});

export const createPlayerReducer =
    (id: PlayerKeys) =>
    (
        state: VideoPlayerState = initialState(),
        action: {type: PlayerActionTypes | PlayerActionTypes; payload: any},
    ) => {
        const {type, payload} = action;
        if (payload && payload.player === id) {
            switch (type) {
                case PlayerActionTypes.PLAYER_PLAYER_INIT_ON_LOAD:
                    return {
                        ...state,
                        playing: true,
                        trackCompleted: false,
                    };
                case PlayerActionTypes.PLAYER_PLAY:
                    return {
                        ...state,
                        playing: true,
                        trackCompleted: false,
                    };
                case PlayerActionTypes.PLAYER_PAUSE:
                    return {
                        ...state,
                        playing: false,
                    };
                case PlayerActionTypes.PLAYER_SOURCE: {
                    const {playlist, source, imageURL, streamName} = payload;
                    const playlistPos = playlist
                        ? findIndex(playlist, (x) => x === streamName)
                        : 0;
                    return {
                        ...state,
                        position: 0,
                        source,
                        playlist,
                        playlistPos,
                        imageURL,
                        streamName,
                    };
                }
                case PlayerActionTypes.PLAYER_IN_FULLSCREEN:
                    return {
                        ...state,
                        inFullscreen: payload.inFullscreen,
                    };
                case PlayerActionTypes.PLAYER_NEXT_TRACK:
                case PlayerActionTypes.PLAYER_PREVIOUS_TRACK:
                    return {
                        ...state,
                        playlistPos: payload.position,
                    };
                case PlayerActionTypes.PLAYER_TRACK_COMPLETED:
                    return {
                        ...state,
                        trackCompleted: true,
                    };

                case PlayerActionTypes.SET_LIVE_CHANNEL:
                    return {
                        ...state,
                        channel: payload.channel,
                    };

                case PlayerActionTypes.SET_LIVE_STREAM:
                    return {
                        ...state,
                        streamUrl: payload.streamUrl,
                    };

                case PlayerActionTypes.SET_LIVE_QUALITY:
                    return {
                        ...state,
                        quality: payload.quality,
                    };

                case PlayerActionTypes.OPEN_RADAR_MENU: {
                    return {
                        ...state,
                        activeMenu: ActiveMenu.RADAR_MENU,
                    };
                }
                case PlayerActionTypes.CLOSE_RADAR_MENU: {
                    const {activeMenu} = state;

                    if (activeMenu && ActiveMenu.RADAR_MENU !== activeMenu) return state;
                    return {
                        ...state,
                        activeMenu: null,
                    };
                }
                default:
                    return state;
            }
        }
        return state;
    };

const playerReducerFactory = (id: PlayerKeys) => createPlayerReducer(id);

export default playerReducerFactory;
