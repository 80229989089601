export enum PlayerActionTypes {
    CLOSE_RADAR_MENU = "video/closeRadarMenu",
    OPEN_RADAR_MENU = "video/openRadarMenu",
    PLAYER_NEXT_TRACK = "PLAYER_NEXT_TRACK",
    PLAYER_PAUSE = "PLAYER_PAUSE",
    PLAYER_PLAY = "PLAYER_PLAY",
    PLAYER_PLAYER_INIT_ON_LOAD = "PLAYER_PLAYER_INIT_ON_LOAD",
    PLAYER_PREVIOUS_TRACK = "PLAYER_PREVIOUS_TRACK",
    PLAYER_SOURCE = "PLAYER_SOURCE",
    PLAYER_TRACK_COMPLETED = "PLAYER_TRACK_COMPLETED",
    START_PLAYER_MEDIA_SOURCE = "video/startPlayerMediaSource",
    PLAYER_IN_FULLSCREEN = "player/fullscreenChange",
    SET_LIVE_CHANNEL = "player/SET_LIVE_CHANNEL",
    SET_LIVE_STREAM = "player/SET_LIVE_STREAM",
    SET_LIVE_QUALITY = "player/SET_LIVE_QUALITY",
}

export enum PlayerPlayTargets {
    ANDROID_PLAYER_TOUCH_FULLSCREEN = "ANDROID_PLAYER_TOUCH_FULLSCREEN",
    ANDROID_PLAYER_PLAY_BUTTON = "ANDROID_PLAYER_PLAY_BUTTON",
}
