import _ from "lodash";
import type {Action} from "redux";
import atgRequest from "@atg-shared/fetch";
import {
    isLoading,
    getStatusFromResponse,
} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import type {Coupon} from "@atg-horse-shared/coupon-types";
import {USER_SERVICE_URL} from "@atg-shared/service-url";
import * as FetchAuthorizedActions from "@atg-shared/auth/domain/fetchAuthorizedActions";
import type {ReductionTerms} from "@atg-horse-shared/reduced-bet-types";
import {
    REQUEST_LEGACY_BET_SUMMARIES,
    RECEIVE_LEGACY_BET_SUMMARIES,
    CLEAR_LEGACY_BET_SUMMARIES,
    REQUEST_BET,
    RECEIVE_BET,
    PLACE_BET,
    REQUEST_CORRECTED_BET,
    RECEIVE_CORRECTED_BET,
    REQUEST_UPDATED_BET,
    RECEIVE_UPDATED_BET,
    RESET_BET_ACTION_STATUS,
    REQUEST_BET_REFUND,
    RECEIVE_BET_REFUND,
    SET_LIVE_BETS_FILTER,
    START_REQUEST_LIVE_BETS,
    STOP_REQUEST_LIVE_BETS,
} from "@atg-horse-shared/horse-bet-types/";
import * as Bet from "../domain/legacyBet";

export enum BetFilters {
    CURRENT = "CURRENT",
    SETTLED = "SETTLED",
    ALL = "ALL",
}

type SetFilterActions = {
    type: typeof SET_LIVE_BETS_FILTER;
    payload: string;
};

type ClearBetSummariesAction = {
    type: typeof CLEAR_LEGACY_BET_SUMMARIES;
    payload: {id: string};
};

type RequestCorrectedBetAction = {
    type: typeof REQUEST_CORRECTED_BET;
    payload: {betId: string};
};

const requestBetSummaries = (id: string) => ({
    type: REQUEST_LEGACY_BET_SUMMARIES,
    payload: id,
});

const receiveBetSummaries = (
    id: string,
    res: {
        [key: string]: any;
    },
) => ({
    type: RECEIVE_LEGACY_BET_SUMMARIES,
    payload: {
        id,
        ...res,
    },
});

const startRequestLiveBets = () => ({type: START_REQUEST_LIVE_BETS});
const stopRequestLiveBets = () => ({type: STOP_REQUEST_LIVE_BETS});

const receiveBetSummariesError = (id: string, res: any) => ({
    type: RECEIVE_LEGACY_BET_SUMMARIES,
    error: true,
    payload: {
        id,
        status: getStatusFromResponse(res),
    },
});

const fetchAuthorized = (dispatch: any, url: string, requestOptions: any) =>
    new Promise((resolve, reject) => {
        dispatch(
            FetchAuthorizedActions.fetchAuthorized(resolve, reject, url, requestOptions),
        );
    });

export const fetchFileBetSummaries =
    (id: string, options: {groupId?: string}) => (dispatch: any) => {
        dispatch(requestBetSummaries(id));
        // forceBetHistory flag tells backend it is ok to fetch from the horse bet history
        const url = `${USER_SERVICE_URL}/bets?forceBetHistory=true${
            options.groupId ? `&groupId=${options.groupId}` : ""
        }`;

        fetchAuthorized(dispatch, url, null)
            .then((res: any) => dispatch(receiveBetSummaries(id, res)))
            .catch((res) => dispatch(receiveBetSummariesError(id, res)));
    };

export const clearBetSummaries = (id: string): ClearBetSummariesAction => ({
    type: CLEAR_LEGACY_BET_SUMMARIES,
    payload: {id},
});

const requestBet = (id: string) => ({
    type: REQUEST_BET,
    payload: id,
});

export const receiveBet = (
    id: string,
    bet: {
        [key: string]: any;
    },
) => ({
    type: RECEIVE_BET,
    payload: {
        id,
        bet,
    },
});

const receiveBetError = (id: string, res: any) => ({
    type: RECEIVE_BET,
    error: true,
    payload: {
        id,
        status: getStatusFromResponse(res),
    },
});

const getBet = (dispatch: any, id: string) =>
    fetchAuthorized(
        dispatch,
        `${USER_SERVICE_URL}/bets/${id}?forceBetHistory=true`,
        null,
    ); // forceBetHistory flag tells backend it is ok to fetch from the horse bet history

export function fetchBetsForLive(listOptions: any): unknown {
    return (dispatch: any, getState: any) => {
        const date = listOptions.gameDate; // Todo: store on another key than date to allow for data re-use
        const receiptsAlreadyLoaded = getState().legacyBet?.bets?.date !== undefined;
        if (receiptsAlreadyLoaded) return;

        dispatch(startRequestLiveBets());

        const url = `${USER_SERVICE_URL}/bets/details?limit=100&gameDate=${listOptions.gameDate}`;

        const recieveBetSummaries = (res: any) => {
            const bets = _.filter(res.data, Bet.isStandardBetOverview);
            dispatch(receiveBetSummaries(date, res));
            bets.forEach((bet) => {
                // @ts-expect-error
                dispatch(receiveBet(Bet.createId(bet, {minutePrecision: true}), bet));
            });
            dispatch(stopRequestLiveBets());
        };

        if (listOptions.skipAuth) {
            atgRequest(url)
                .then(({data: res}) => recieveBetSummaries(res))
                .catch((res) => dispatch(receiveBetSummariesError(date, res)));
        } else {
            fetchAuthorized(dispatch, url, null)
                .then((res) => recieveBetSummaries(res))
                .catch((res) => dispatch(receiveBetSummariesError(date, res)));
        }
    };
}

export function setFilter(filter: string): SetFilterActions {
    return {
        type: SET_LIVE_BETS_FILTER,
        payload: filter,
    };
}

function privateFetchBet(id: string) {
    return (dispatch: any) => {
        dispatch(requestBet(id));
        return getBet(dispatch, id)
            .then((res: any) => dispatch(receiveBet(id, res)))
            .catch((res) => dispatch(receiveBetError(id, res)));
    };
}

function requestUpdatedBet(id: string) {
    return {
        type: REQUEST_UPDATED_BET,
        payload: id,
    };
}

export function receiveUpdatedBet(id: string, bet: any) {
    return {
        type: RECEIVE_UPDATED_BET,
        payload: {
            id,
            bet,
        },
    };
}

function receiveUpdatedBetError(id: string, res: any) {
    return {
        type: RECEIVE_UPDATED_BET,
        error: true,
        payload: {
            id,
            status: getStatusFromResponse(res),
        },
    };
}

function updateBet(betId: string) {
    return (dispatch: any) => {
        dispatch(requestUpdatedBet(betId));
        return getBet(dispatch, betId)
            .then((res) => dispatch(receiveUpdatedBet(betId, res)))
            .catch((res) => dispatch(receiveUpdatedBetError(betId, res)));
    };
}

export function fetchBet(betId: string): Action<any> {
    // @ts-expect-error
    return (dispatch: any, getState: any) => {
        const state = getState();
        const loadingStatus = state.legacyBet.betLoadingStatuses[betId];
        if (isLoading(loadingStatus)) return null;
        const bet = state.legacyBet.bets[betId];

        if (bet) {
            if (Bet.hasPayout(bet)) return null;
            return dispatch(updateBet(betId));
        }
        return dispatch(privateFetchBet(betId));
    };
}

export function requestCorrectedBet(betId: string): RequestCorrectedBetAction {
    return {
        type: REQUEST_CORRECTED_BET,
        payload: {betId},
    };
}

export function receiveCorrectedBet(id: any, bet: any) {
    return {
        type: RECEIVE_CORRECTED_BET,
        payload: {
            id,
            bet,
        },
    };
}

/**
 * When we make a request against `/users/bet/<betId>` with `?check=true`, the request reaches
 * Spelsystemet. Since it's easily overloaded, we want to try to prevent the end-user from spamming
 * "refresh" against this endpoint. So we tweak the error message here.
 */
export function receiveCorrectedBetError(id: any, res: any) {
    const formattedResponse = res.response ? res.response : res;

    return {
        type: RECEIVE_CORRECTED_BET,
        error: true,
        payload: {
            id,
            status: getStatusFromResponse(formattedResponse, {
                // these two are subtly different on purpose: if an end-user sends a screenshot or
                // similar we can easily identify which error they got (without needing to write
                // "error code foobar")
                "500": "Tjänsten är inte tillgänglig just nu, försök igen om 5 minuter",
                "503": "Tjänsten är inte tillgänglig, försök igen om 5 minuter",
            }),
        },
    };
}

export function resetBetActionStatus(id: string) {
    return {
        type: RESET_BET_ACTION_STATUS,
        payload: {
            id,
        },
    };
}

function requestBetRefund(betId: string) {
    return {
        type: REQUEST_BET_REFUND,
        payload: {
            id: betId,
        },
    };
}

function receiveBetRefund(betId: string, res: any) {
    return {
        type: RECEIVE_BET_REFUND,
        payload: {
            id: betId,
            payout: res,
        },
    };
}

function receiveBetRefundError(betId: string, res: any) {
    const formattedResponse = res.response ? res.response : res;

    return {
        type: RECEIVE_BET_REFUND,
        error: true,
        payload: {
            id: betId,
            status: getStatusFromResponse(formattedResponse, {
                400: "Utbetalning ej möjlig.",
                503: "Kvitton kan inte återbetalas för tillfället, försök igen senare.",
            }),
        },
    };
}

export function refund(betId: string): any {
    return (dispatch: any) => {
        dispatch(requestBetRefund(betId));

        return fetchAuthorized(dispatch, `${USER_SERVICE_URL}/bets/${betId}/refund`, {
            method: "POST",
            body: JSON.stringify({}),
        })
            .then((res) => dispatch(receiveBetRefund(betId, res)))
            .catch((res) => dispatch(receiveBetRefundError(betId, res)));
    };
}

export const placeBet = (
    bet: {
        [key: string]: any;
    },
    token: number | string,
    coupon: Coupon,
    reductionTerms?: ReductionTerms | null,
    accessToken = "",
    analyticsContext?: any,
) => ({
    type: PLACE_BET,
    payload: {
        bet,
        token,
        coupon,
        reductionTerms,
        accessToken,
        analyticsContext,
    },
});

export {
    REQUEST_BET,
    RECEIVE_BET,
    REQUEST_LEGACY_BET_SUMMARIES,
    RECEIVE_LEGACY_BET_SUMMARIES,
    CLEAR_LEGACY_BET_SUMMARIES,
    PLAYED_BET,
    PLAYED_BETS,
    REQUEST_CORRECTED_BET,
    RECEIVE_CORRECTED_BET,
    REQUEST_UPDATED_BET,
    RECEIVE_UPDATED_BET,
    RESET_BET_ACTION_STATUS,
    REQUEST_BET_SPEED_PAYOUT,
    RECEIVE_BET_SPEED_PAYOUT,
    REQUEST_BET_REFUND,
    RECEIVE_BET_REFUND,
    SET_LIVE_BETS_FILTER,
    START_REQUEST_LIVE_BETS,
    STOP_REQUEST_LIVE_BETS,
    BET_REFUND_SUCCESS_STATUS,
} from "@atg-horse-shared/horse-bet-types/";
