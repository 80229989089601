import {USER_SERVICE_URL, VARENNE_COUPON_TEAMS_URL} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import type {Coupon} from "@atg-horse-shared/coupon-types";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import type {ReductionTerms} from "@atg-horse-shared/reduced-bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getCost} from "@atg-horse/horse-bet/src/domain/bet";

const SHARED_BET_URL = `${USER_SERVICE_URL}/bets/shared`;

export const EDIT_TOTAL_NR_SHARES_REQUEST = "sharedBet/EDIT_TOTAL_NR_SHARES_REQUEST";
export const EDIT_TOTAL_NR_SHARES_RESPONSE = "sharedBet/EDIT_TOTAL_NR_SHARES_RESPONSE";
export const EDIT_TOTAL_NR_SHARES_RESET = "sharedBet/EDIT_TOTAL_NR_SHARES_RESET";

export type SharedBetEditNrSharesContext = {couponId: string; totalNrShares: number};

type SharedBetCreateParams = {
    gameId: string;
    shareCost: number;
    totalNrShares: number;
    nrShares: number;
    couponId: string;
    systemSizeLimit?: number;
};

export type SharedBetEditTotalNrSharesAction = FetchAction<
    typeof EDIT_TOTAL_NR_SHARES_REQUEST,
    typeof EDIT_TOTAL_NR_SHARES_RESPONSE,
    SharedBetEditNrSharesContext
>;

export type SharedBetEditTotalNrSharesResetAction = {
    type: typeof EDIT_TOTAL_NR_SHARES_RESET;
};

export const createSharedBet = (params: SharedBetCreateParams, token: string) =>
    fetchAuthorized(`${VARENNE_COUPON_TEAMS_URL}/create`, {
        method: "POST",
        headers: {token},
        body: JSON.stringify(params),
    });

export const editSharedBet = (
    couponId: string,
    totalNrShares: number,
): SharedBetEditTotalNrSharesAction => ({
    type: FETCH,
    payload: {
        requestAction: EDIT_TOTAL_NR_SHARES_REQUEST,
        receiveAction: EDIT_TOTAL_NR_SHARES_RESPONSE,
        callApi: call(fetchAuthorized, `${VARENNE_COUPON_TEAMS_URL}/create`, {
            method: "PATCH",
            body: JSON.stringify({couponId, totalNrShares}),
        }),
        context: {couponId, totalNrShares},
    },
});

export const placeReducedSharedBet = (
    coupon: Coupon,
    reductionTerms: ReductionTerms,
    game?: Game,
) => {
    const {rowsBeforeReducing, rowsAfterReducing, amountOfCoupons, effectiveSystems} =
        reductionTerms.reductionMetadata || {};
    const cost = getCost(coupon, reductionTerms);

    return fetchAuthorized(`${VARENNE_COUPON_TEAMS_URL}/reduced-bet`, {
        method: "POST",
        body: JSON.stringify({
            couponId: coupon.id,
            cost,
            rowsBeforeReducing,
            rowsAfterReducing,
            amountOfCoupons,
            effectiveSystems,
            game,
        }),
    });
};

type SharedBetInviteParams = {
    url: string;
    friends: {
        [key: string]: any;
    };
    message: string;
};

export const inviteFriends = (params: SharedBetInviteParams, token: string) =>
    fetchAuthorized(`${SHARED_BET_URL}/invite`, {
        method: "POST",
        headers: {token},
        body: JSON.stringify({
            url: params.url,
            message: params.message,
            email: params.friends.email,
            mobilePhone: params.friends.mobilePhone,
        }),
    });

export const fetchConditions = (id: string) =>
    fetchAuthorized(`${VARENNE_COUPON_TEAMS_URL}/${id}`, {
        method: "GET",
    });

export const purchaseShare = (
    bet: {
        [key: string]: any;
    },
    token: string,
) =>
    fetchAuthorized(`${VARENNE_COUPON_TEAMS_URL}/${bet.couponId}/buy`, {
        method: "POST",
        headers: {token},
        body: JSON.stringify({
            ...bet,
        }),
    });

export const cancelSharedBet = (couponId: string) =>
    fetchAuthorized(`${SHARED_BET_URL}/cancel`, {
        method: "DELETE",
        body: JSON.stringify({
            id: couponId,
        }),
    });
