import {LOADING} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import {
    START_REQUEST_LIVE_BETS,
    STOP_REQUEST_LIVE_BETS,
    VARENNE_BET_SUCCESS,
    PLAYED_BET,
    RECEIVE_LEGACY_BET_SUMMARIES,
} from "@atg-horse-shared/horse-bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {type LoadingStatus, OK} from "@atg-horse-shared/legacy/domain/loadingstatus";
import * as LiveActions from "../liveactions";
import type {Race} from "./types";

type TrackHorseInfo = {
    races: [Race];
    name: string;
};
type LiveReceiptTrackHorseInfo = Record<string, TrackHorseInfo>;
export type LiveBetLoadingStatusState = {
    status: {
        code: string;
        isLoading: boolean;
    };
    isLoadingVarenne: boolean;
    trackHorseInfoLoadingStatus: Record<number, LoadingStatus>;
    shouldLoadVarenneBets: boolean;
};

const initLiveBetLoadingState = {
    status: {
        code: "",
        isLoading: false,
    },
    isLoadingVarenne: false,
    trackHorseInfoLoadingStatus: {},
    shouldLoadVarenneBets: false,
};
export function liveBetLoadingStatus(
    state: LiveBetLoadingStatusState = {...initLiveBetLoadingState},
    action: {
        type: string;
        error: boolean;
        raceDayIndex?: string;
        payload?: LiveReceiptTrackHorseInfo | {bet?: {isVarenne: boolean}}; // LiveReceiptTrackHorseInfo OR PLAYED_BET action from sharedBetApi
    },
) {
    const {type, error} = action;
    switch (type) {
        case LiveActions.REQUEST_HBH_LIVE_BETS:
        case START_REQUEST_LIVE_BETS:
            return {
                ...state,
                status: LOADING,
            };
        case LiveActions.RECEIVE_HBH_LIVE_BETS:
        case STOP_REQUEST_LIVE_BETS:
            return {
                ...state,
                status: OK,
            };
        case RECEIVE_LEGACY_BET_SUMMARIES:
            if (!error) return state;
            return {
                ...state,
                status: OK,
            };
        case VARENNE_BET_SUCCESS:
            return {
                ...state,
                shouldLoadVarenneBets: true,
            };
        case PLAYED_BET:
            // @ts-expect-error a few different action types here
            if (!action.payload?.bet?.isVarenne) return state;
            return {
                ...state,
                shouldLoadVarenneBets: true,
            };

        case LiveActions.START_LOADING_VARENNE:
            return {
                ...state,
                isLoadingVarenne: true,
                shouldLoadVarenneBets: false,
            };
        case LiveActions.STOP_LOADING_VARENNE:
            return {
                ...state,
                isLoadingVarenne: false,
            };

        case LiveActions.FETCHING_TRACK_HORSE_INFO:
            if (!action.raceDayIndex) return state;
            return {
                ...state,
                trackHorseInfoLoadingStatus: {
                    ...state.trackHorseInfoLoadingStatus,
                    [action.raceDayIndex]: {code: "loading", isLoading: false},
                },
            };
        case LiveActions.TRACK_HORSE_INFO_LOADED:
            if (!action.payload || !action.raceDayIndex) {
                return state;
            }
            return {
                ...state,
                trackHorseInfoLoadingStatus: {
                    ...state.trackHorseInfoLoadingStatus,
                    [action.raceDayIndex]: {
                        code: "ok",
                        isLoading: false,
                    },
                },
            };
        case LiveActions.TRACK_HORSE_INFO_LOAD_ERROR:
            if (!action.raceDayIndex) return state;
            return {
                ...state,
                trackHorseInfoLoadingStatus: {
                    ...state.trackHorseInfoLoadingStatus,
                    [action.raceDayIndex]: {
                        code: "failed",
                        isLoading: false,
                    },
                },
            };
        default:
            return state;
    }
}
