import type {Response} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import {getStatusFromResponse} from "@atg-shared/response-mapping/deprecated_loadingStatus";
import fetchAuthorized from "@atg-shared/auth/domain/fetchAuthorized";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {GameType} from "@atg-horse-shared/game-types";
import type {Race} from "@atg-horse-shared/bet-types";
import {BET_HISTORY_SINGLE_BETS_TODAY} from "@atg-shared/service-url";
import type {TypesOfMediaContent} from "@atg/utils/player-util/types";

export const FETCH_TRACK_HORSE_INFO = "live/fetchTrackHorseInfo";
export const FETCHING_TRACK_HORSE_INFO = "live/fetchingTrackHorseInfo";
export const TRACK_HORSE_INFO_LOAD_ERROR = "live/trackHorseInfoLoadError";
export const TRACK_HORSE_INFO_LOADED = "live/trackHorseInfoLoaded";
export const START_LOADING_VARENNE = "live/startLoadingVarenne";
export const STOP_LOADING_VARENNE = "live/stopLoadingVarenne";
export const LIVE_BET_CORRECTED = "live/betCorrected";
export const FAILED_MESSAGE_CLOSE = "live/failedMessageClose";
export const ONGOING_RACE = "live/ongoingRace";
export const FINISHED_RACE = "live/finishedRace";
export const RESULT_TIMER_STARTED = "live/resultTimerStarted";
export const RESULT_TIMER_ELAPSED = "live/resultTimerElapsed";
export const CHANGE_BET_SORT_OPTIONS = "live/changeBetSortOptions";
export const REQUEST_CORRECT_LIVE_BET = "live/requestCorrectLiveBet";
export const PLAYER_PLAYER_INIT_ON_LOAD = "player/PLAYER_PLAYER_INIT_ON_LOAD";
export const PLAYER_PLAY = "player/PLAYER_PLAY";
export const REQUEST_HBH_LIVE_BETS = "live/REQUEST_HBH_LIVE_BETS"; // use to show loader
export const RECEIVE_HBH_LIVE_BETS = "live/RECEIVE_HBH_LIVE_BETS"; // use to hide loader

export type SortOptions = {
    column: string;
    ascending: boolean;
};

export function failedMessageClosed(id: string) {
    return {
        type: FAILED_MESSAGE_CLOSE,
        payload: {
            id,
        },
    };
}

export function ongoingRace(id: string) {
    return {
        type: ONGOING_RACE,
        payload: {
            id,
        },
    };
}

export function finishedRace(id: string) {
    return {
        type: FINISHED_RACE,
        payload: {
            id,
        },
    };
}

export function resultTimerStarted(id: string) {
    return {
        type: RESULT_TIMER_STARTED,
        payload: {
            id,
        },
    };
}

export function resultTimerElapsed(id: string) {
    return {
        type: RESULT_TIMER_ELAPSED,
        payload: {
            id,
        },
    };
}

export function setLiveFreeTimeOnLoad(id: TypesOfMediaContent) {
    return {
        type: PLAYER_PLAYER_INIT_ON_LOAD,
        payload: {
            id,
        },
    };
}

export function changeReceiptSortOptions(sortOptions: SortOptions) {
    return {
        type: CHANGE_BET_SORT_OPTIONS,
        payload: sortOptions,
    };
}

type LiveBetPayload = {
    gameType: GameType;
    id: string;
    race: Race;
};

export function liveBetCorrected(payload: LiveBetPayload) {
    return {
        type: LIVE_BET_CORRECTED,
        payload,
    };
}

export function liveBetCorrectedError(payload: LiveBetPayload, response: Response) {
    const {id, gameType} = payload;
    return {
        type: LIVE_BET_CORRECTED,
        error: true,
        payload: {
            id,
            gameType,
            status: getStatusFromResponse(response),
        },
    };
}

export const requestCorrectLiveBet = (payload: any) => ({
    type: REQUEST_CORRECT_LIVE_BET,
    payload,
});
export type GetTrackHorseInfoActionType = {
    type: string;
    track: number;
    date: string;
};
export const getTrackHorseInfo = (
    track: number,
    date: string,
): GetTrackHorseInfoActionType => ({
    type: FETCH_TRACK_HORSE_INFO,
    track,
    date,
});

type FetchingTrackHorseInfoActionType = {
    type: string;
    raceDayIndex: string;
};
export const fetchingTrackHorseInfo = (
    raceDayIndex: string,
): FetchingTrackHorseInfoActionType => ({
    type: FETCHING_TRACK_HORSE_INFO,
    raceDayIndex,
});

export const trackHorseInfoLoaded = (payload: any) => ({
    type: TRACK_HORSE_INFO_LOADED,
    payload,
});

export const trackHorseInfoLoadError = (raceDayIndex: string, error?: any) => ({
    type: TRACK_HORSE_INFO_LOAD_ERROR,
    raceDayIndex,
    error,
});

export const startLoadingVarenne = () => ({
    type: START_LOADING_VARENNE,
});

export const stopLoadingVarenne = () => ({
    type: STOP_LOADING_VARENNE,
});

export const fetchBetsForLive = () => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_HBH_LIVE_BETS,
        receiveAction: RECEIVE_HBH_LIVE_BETS,
        callApi: call(fetchAuthorized, BET_HISTORY_SINGLE_BETS_TODAY),
    },
});
