type ACTIONS = {type: "forceReload"};

type State = {
    forceReload: number;
};

const INITIAL_STATE: State = {
    forceReload: Date.now(),
};

export const storeApiReducer = (state: State = INITIAL_STATE, action: ACTIONS): State => {
    switch (action.type) {
        case "forceReload":
            return {
                ...state,
                forceReload: Date.now(),
            };

        default:
            return state;
    }
};
