import atgRequest from "@atg-shared/fetch";
import type {GameType} from "@atg-horse-shared/game-types";
import {TRACK_SERVICE_URL} from "@atg-shared/service-url";

export type DriverChange = {
    driverName: string;
    horseName: string;
    originalDriverName: string;
    startNumber: number;
    weight?: number;
};

export type ScratchedHorse = {
    horseName: string;
    startNumber: number;
};

type ProgramChangesGame = {
    type: GameType;
    leg: number;
};

export type ProgramChangesRace = {
    raceNumber: number;
    games: Array<ProgramChangesGame>;
    driverChanges?: Array<DriverChange>;
    scratchedHorses?: Array<ScratchedHorse>;
};

export type ProgramChanges = {
    date: string;
    trackId: number;
    trackName: string;
    updatedTimestamp: string;
    races: Array<ProgramChangesRace>;
};

export const fetchProgramChanges = (trackId: string, date: string) =>
    atgRequest<ProgramChanges>(`${TRACK_SERVICE_URL}/${trackId}/programchanges/${date}`);
