import {type DivisionGameTypeWithoutLegacy} from "@atg-horse-shared/game-types";
import {type HarryFlavor} from "@atg-horse-shared/bet-types";
import type {
    DivisionGameCoupon,
    KombCoupon,
    RaketCoupon,
    Top7Coupon,
    TrioCoupon,
} from "@atg-horse-shared/coupon-types";
import * as BetTransformers from "./utils/couponToBetTransformers";

type BaseCoupon = {
    betCost: 0;
    systems: 1;
    betMethod: null;
    stake: number;
    addOns: [];
    teamId: null;
};

type DefaultDivisionGameCoupon = {
    stake: 100;
    rows: 0;
    races: {
        bets: [];
        reserves: [];
        harryOpen: true;
    };
} & BaseCoupon;

type SimpleDivisionGameCoupon = {
    stake: 1000;
    rows: 0;
    races: {
        bets: [];
        harryOpen: true;
    };
};

type VpGameCoupon = {
    stake: 1000;
    races: {
        bets: [];
    };
} & BaseCoupon;

type VinnareGameCoupon = {
    stake: 2000;
    races: {
        bets: [];
    };
} & BaseCoupon;

type PlatsGameCoupon = {
    stake: 2000;
    races: {
        bets: [];
    };
} & BaseCoupon;

type TvillingGameCoupon = {
    combinations: 0;
    races: {
        bets: [];
        harryOpen: true;
        baseBets: [];
        baseHarryOpen: false;
    };
} & BaseCoupon;

type Top7GameCoupon = {
    stake: 2400;
    banker: false;
    userDefinedSystem: false;
    races: {
        bets: [];
        reserves: [];
        boxedBets: [];
    };
} & BaseCoupon;

const baseCoupon: BaseCoupon = {
    betCost: 0,
    systems: 1,
    betMethod: null,
    stake: 1000,
    addOns: [],
    teamId: null,
};

const defaultDivisionGameCoupon: DefaultDivisionGameCoupon = {
    ...baseCoupon,
    stake: 100,
    rows: 0,
    races: {
        bets: [],
        reserves: [],
        harryOpen: true,
    },
};

const simpleDivisionGameCoupon: SimpleDivisionGameCoupon = {
    ...baseCoupon,
    stake: 1000,
    rows: 0,
    races: {
        bets: [],
        harryOpen: true,
    },
};

const tvillingGameCoupon: TvillingGameCoupon = {
    ...baseCoupon,
    combinations: 0,
    races: {
        bets: [],
        harryOpen: true,
        baseBets: [],
        baseHarryOpen: false,
    },
};

const vpGameCoupon: VpGameCoupon = {
    ...baseCoupon,
    stake: 1000,
    races: {
        bets: [],
    },
};

const vinnareGameCoupon: VinnareGameCoupon = {
    ...baseCoupon,
    stake: 2000,
    races: {
        bets: [],
    },
};

const platsGameCoupon: PlatsGameCoupon = {
    ...baseCoupon,
    stake: 2000,
    races: {
        bets: [],
    },
};

const top7GameCoupon: Top7GameCoupon = {
    ...baseCoupon,
    stake: 2400,
    banker: false,
    userDefinedSystem: false,
    races: {
        bets: [],
        reserves: [],
        boxedBets: [],
    },
};

const standardPrefixes: [""] = [""];

type BaseCouponDefs = {
    allowCustomHarryBetLimit: boolean;
    allowReserves: boolean;
    harry: boolean;
    harryBetLimits: Array<number>;
    prefixes: Array<string>;
};

type BaseCouponDefsWithStake = BaseCouponDefs & {
    minStake: number;
    stakeOptions: Array<number>;
};

type DivisionCouponDefs = BaseCouponDefs & {
    betTransformer: typeof BetTransformers.createDivisionGameBet;
    boost?: boolean;
    coupon: DivisionGameCoupon<DivisionGameTypeWithoutLegacy>;
    harryFlavour?: HarryFlavor;
    maxHarryBetLimit: number;
    minHarryBetLimit: number;
};

type V3LegacyCouponDefs = Omit<
    BaseCouponDefs,
    "allowCustomHarryBetLimit" | "harry" | "harryBetLimits"
> & {
    betTransformer: typeof BetTransformers.createSimpleDivisionGameBet;
    coupon: DivisionGameCoupon<DivisionGameTypeWithoutLegacy>;
};

type V3CouponDefs = BaseCouponDefs & {
    betTransformer: typeof BetTransformers.createDivisionGameBet;
    coupon: DivisionGameCoupon<DivisionGameTypeWithoutLegacy>;
};

type DubbelCouponDefs = BaseCouponDefsWithStake & {
    betTransformer: typeof BetTransformers.createDubbelBet;
    coupon: DivisionGameCoupon<DivisionGameTypeWithoutLegacy>;
};

type KombCouponDefs = BaseCouponDefsWithStake & {
    betTransformer: typeof BetTransformers.createKombBet;
    coupon: KombCoupon;
};

export type Top7CouponDefs = BaseCouponDefsWithStake & {
    betTransformer: typeof BetTransformers.createTop7Bet;
    costToSystemIds: Record<string, Array<string>>;
    coupon: Top7Coupon;
    hasExactHarryBetLimit: boolean;
    maxHarryBetLimit: number;
    maxNumberOfBets: number;
    maxNumberOfReserves: number;
    minHarryBetLimit: number;
    rowCost: number;
    systems: Array<string>;
};

type TrioCouponDefs = BaseCouponDefsWithStake & {
    betTransformer: typeof BetTransformers.createTrioBet;
    coupon: TrioCoupon;
    flexBetCosts: Array<number>;
};

type TvillingCouponDefs = BaseCouponDefsWithStake & {
    betTransformer: typeof BetTransformers.createTvillingBet;
    coupon: TvillingGameCoupon;
    frenchMinStake: number;
    frenchStakeOptions: Array<number>;
    harryOpenDisabled: boolean;
};

type VinnareCouponDefs = Omit<
    BaseCouponDefsWithStake,
    "allowCustomHarryBetLimit" | "harry" | "harryBetLimits"
> & {
    betTransformer: typeof BetTransformers.createSingleRaceGameBet;
    coupon: VinnareGameCoupon;
};

type PlatsCouponDefs = Omit<
    BaseCouponDefsWithStake,
    "allowCustomHarryBetLimit" | "harry" | "harryBetLimits"
> & {
    betTransformer: typeof BetTransformers.createSingleRaceGameBet;
    coupon: PlatsGameCoupon;
};

type VpCouponDefs = Omit<
    BaseCouponDefsWithStake,
    "allowCustomHarryBetLimit" | "harry" | "harryBetLimits"
> & {
    betTransformer: typeof BetTransformers.createSingleRaceGameBet;
    coupon: VpGameCoupon;
};

type RaketCouponDefs = Omit<
    BaseCouponDefsWithStake,
    "allowCustomHarryBetLimit" | "harry" | "harryBetLimits"
> & {
    betTransformer: typeof BetTransformers.createRaketBet;
    coupon: RaketCoupon;
    systemMultiplicators: Record<string, number>;
    systems: Record<
        string,
        {
            label: string;
            requiredBetCount: number;
            priceMultiplier: number;
        }
    >;
};

type CouponDefs = {
    dd: DubbelCouponDefs;
    GS75: DivisionCouponDefs;
    komb: KombCouponDefs;
    ld: DubbelCouponDefs;
    plats: PlatsCouponDefs;
    raket: RaketCouponDefs;
    top7: Top7CouponDefs;
    trio: TrioCouponDefs;
    tvilling: TvillingCouponDefs;
    V3Legacy: V3LegacyCouponDefs;
    V3: V3CouponDefs;
    V4: DivisionCouponDefs;
    V5: DivisionCouponDefs;
    V64: DivisionCouponDefs;
    V65: DivisionCouponDefs;
    V75: DivisionCouponDefs;
    V86: DivisionCouponDefs;
    vinnare: VinnareCouponDefs;
    vp: VpCouponDefs;
};

// Have added a new type V3Legacy that defines the current V3 coupons and V3 defines the Varenne V3 coupons
// All translations to V3Legacy are marked with "// Varenne V3 Revert this to {whatever the code was before} when V3Legacy support is dropped"
// see `createBet()` and `withGameData()` in coupon.js to understand how `betTransformer` and more are used
const couponDefs: CouponDefs = {
    V75: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        boost: true,
        // @ts-expect-error
        coupon: {
            ...defaultDivisionGameCoupon,
            stake: 50,
        },
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V86: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: {
            ...defaultDivisionGameCoupon,
            stake: 25,
        },
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    GS75: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: {
            ...defaultDivisionGameCoupon,
            stake: 100,
        },
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V64: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: defaultDivisionGameCoupon,
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V65: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: defaultDivisionGameCoupon,
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V5: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: defaultDivisionGameCoupon,
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V4: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: {
            ...defaultDivisionGameCoupon,
            stake: 200,
        },
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    V3Legacy: {
        prefixes: standardPrefixes,
        allowReserves: false,
        // @ts-expect-error
        coupon: simpleDivisionGameCoupon,
        betTransformer: BetTransformers.createSimpleDivisionGameBet,
    },
    V3: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        minHarryBetLimit: 5000,
        maxHarryBetLimit: 1000000,
        harryBetLimits: [5000, 10000, 15000, 20000, 30000, 40000, 50000, 100000],
        allowCustomHarryBetLimit: true,
        // @ts-expect-error
        coupon: {
            ...defaultDivisionGameCoupon,
            stake: 1000,
        },
        betTransformer: BetTransformers.createDivisionGameBet,
    },
    dd: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000],
        harry: true,
        allowCustomHarryBetLimit: false,
        harryBetLimits: [2000, 6000, 10000, 20000, 30000, 40000, 50000, 100000],
        // @ts-expect-error
        coupon: simpleDivisionGameCoupon,
        betTransformer: BetTransformers.createDubbelBet,
    },
    ld: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000],
        harry: true,
        allowCustomHarryBetLimit: false,
        harryBetLimits: [2000, 6000, 10000, 20000, 30000, 40000, 50000, 100000],
        // @ts-expect-error
        coupon: simpleDivisionGameCoupon,
        betTransformer: BetTransformers.createDubbelBet,
    },
    vinnare: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000],
        coupon: vinnareGameCoupon,
        betTransformer: BetTransformers.createSingleRaceGameBet,
    },
    plats: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000],
        coupon: platsGameCoupon,
        betTransformer: BetTransformers.createSingleRaceGameBet,
    },
    vp: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000],
        coupon: vpGameCoupon,
        betTransformer: BetTransformers.createSingleRaceGameBet,
    },
    tvilling: {
        prefixes: ["", "base"],
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 5000, 7500, 10000, 20000, 50000],
        frenchMinStake: 1000,
        frenchStakeOptions: [1000, 2000, 3000, 5000, 7500, 10000, 20000, 50000],
        harry: true,
        allowCustomHarryBetLimit: false,
        harryBetLimits: [3000, 5000, 10000, 15000, 30000, 40000, 50000, 100000],
        harryOpenDisabled: true,
        coupon: tvillingGameCoupon,
        betTransformer: BetTransformers.createTvillingBet,
    },
    komb: {
        prefixes: ["firstPlace", "secondPlace"],
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 3000, 5000, 10000, 20000, 50000],
        harry: true,
        allowCustomHarryBetLimit: false,
        harryBetLimits: [3000, 6000, 9600, 20000, 30000, 40000, 50000, 100000],
        coupon: {
            ...baseCoupon,
            combinations: 0,
            races: {
                // @ts-expect-error
                firstPlaceBets: [],
                firstPlaceHarryOpen: true,
                secondPlaceBets: [],
                secondPlaceHarryOpen: true,
            },
        },
        betTransformer: BetTransformers.createKombBet,
    },
    trio: {
        prefixes: ["firstPlace", "secondPlace", "thirdPlace"],
        allowReserves: false,
        flexBetCosts: [5000, 7500, 10000, 15000, 20000, 30000, 40000, 50000],
        minStake: 200,
        stakeOptions: [200, 500, 1000, 2000, 5000, 10000, 20000, 50000],
        harry: true,
        allowCustomHarryBetLimit: false,
        harryBetLimits: [3600, 4800, 9600, 14400, 19200, 28800, 38400, 48000],
        coupon: {
            ...baseCoupon,
            stake: 200,
            combinations: 0,
            flexBetCost: 10000,
            flexValue: Number("0.0"),
            races: {
                // @ts-expect-error
                firstPlaceBets: [],
                firstPlaceHarryOpen: true,
                secondPlaceBets: [],
                secondPlaceHarryOpen: true,
                thirdPlaceBets: [],
                thirdPlaceHarryOpen: true,
            },
        },
        betTransformer: BetTransformers.createTrioBet,
    },
    raket: {
        prefixes: standardPrefixes,
        allowReserves: false,
        minStake: 500,
        stakeOptions: [500, 1000, 2000, 3000, 5000, 10000, 20000, 50000],
        systemMultiplicators: {
            simple: 1,
            "2of3": 3,
            "2of4": 6,
            "3of4": 4,
            "3of5": 10,
            "4of5": 5,
        },
        coupon: {
            ...baseCoupon,
            // @ts-expect-error
            system: "simple",
            races: {
                // @ts-expect-error
                bets: [],
                betType: null,
            },
        },
        betTransformer: BetTransformers.createRaketBet,
        systems: {
            simple: {
                label: "Enkelt",
                requiredBetCount: 2,
                priceMultiplier: 1,
            },
            "2of3": {
                label: "2 av 3",
                requiredBetCount: 3,
                priceMultiplier: 3,
            },
            "2of4": {
                label: "2 av 4",
                requiredBetCount: 4,
                priceMultiplier: 6,
            },
            "3of4": {
                label: "3 av 4",
                requiredBetCount: 4,
                priceMultiplier: 4,
            },
            "3of5": {
                label: "3 av 5",
                requiredBetCount: 5,
                priceMultiplier: 10,
            },
            "4of5": {
                label: "4 av 5",
                requiredBetCount: 5,
                priceMultiplier: 5,
            },
        },
    },
    top7: {
        prefixes: standardPrefixes,
        allowReserves: true,
        harry: true,
        allowCustomHarryBetLimit: false,
        hasExactHarryBetLimit: true,
        minHarryBetLimit: 2400,
        maxHarryBetLimit: 484000,
        harryBetLimits: [2400, 4800, 9600, 48000],
        minStake: 1600,
        rowCost: 400,
        maxNumberOfBets: 7,
        maxNumberOfReserves: 2,
        // @ts-expect-error
        coupon: top7GameCoupon,
        stakeOptions: [
            1600, 2400, 3200, 4800, 9600, 14400, 19200, 48000, 57600, 96000, 288000,
            2016000,
        ],
        betTransformer: BetTransformers.createTop7Bet,
        costToSystemIds: {
            "2016000": ["1-7"],
            "288000": ["1-6,7", "1,2-7"],
            "96000": ["1-5,6-7", "1-2,3-7"],
            "57600": ["1-4,5-7", "1-3,4-7"],
            "48000": ["1-5,6,7", "1,2-6,7", "1,2,3-7"],
            "19200": [
                "1-4,5-6,7",
                "1,2-3,4-7",
                "1,2-5,6-7",
                "1-2,3,4-7",
                "1-2,3-6,7",
                "1-4,5,6-7",
            ],
            "14400": ["1-3,4-6,7", "1,2-4,5-7", "1-3,4,5-7"],
            "9600": [
                "1-4,5,6,7",
                "1,2-5,6,7",
                "1-2,3-4,5-7",
                "1-2,3-5,6-7",
                "1-3,4-5,6-7",
                "1,2,3,4-7",
                "1,2,3-6,7",
            ],
            "4800": [
                "1-3,4-5,6,7",
                "1,2-4,5-6,7",
                "1,2,3-4,5-7",
                "1,2,3-5,6-7",
                "1,2-3,4,5-7",
                "1,2-3,4-6,7",
                "1,2-4,5,6-7",
                "1-2,3,4,5-7",
                "1-2,3,4-6,7",
                "1-2,3-5,6,7",
                "1-3,4,5,6-7",
                "1-3,4,5-6,7",
            ],
            "3200": ["1-2,3-4,5-6,7", "1,2-3,4-5,6-7", "1-2,3,4-5,6-7", "1-2,3-4,5,6-7"],
            "2400": [
                "1-3,4,5,6,7",
                "1,2-4,5,6,7",
                "1,2,3,4,5-7",
                "1,2,3,4-6,7",
                "1,2,3-5,6,7",
            ],
            "1600": [
                "1-2,3-4,5,6,7",
                "1,2,3,4-5,6-7",
                "1,2,3-4,5,6-7",
                "1,2,3-4,5-6,7",
                "1,2-3,4,5,6-7",
                "1,2-3,4,5-6,7",
                "1,2-3,4-5,6,7",
                "1-2,3,4,5,6-7",
                "1-2,3,4,5-6,7",
                "1-2,3,4-5,6,7",
            ],
        },
        systems: [
            "1,2,3,4-5,6-7",
            "1,2,3-4,5,6-7",
            "1,2,3-4,5-6,7",
            "1,2-3,4,5,6-7",
            "1,2-3,4,5-6,7",
            "1,2-3,4-5,6,7",
            "1-2,3,4,5,6-7",
            "1-2,3,4,5-6,7",
            "1-2,3,4-5,6,7",
            "1-2,3-4,5,6,7",
            "1,2,3,4,5-7",
            "1,2,3,4-6,7",
            "1,2,3-5,6,7",
            "1,2-4,5,6,7",
            "1-3,4,5,6,7",
            "1,2-3,4-5,6-7",
            "1-2,3,4-5,6-7",
            "1-2,3-4,5,6-7",
            "1-2,3-4,5-6,7",
            "1,2,3-4,5-7",
            "1,2,3-5,6-7",
            "1,2-3,4,5-7",
            "1,2-3,4-6,7",
            "1,2-4,5,6-7",
            "1,2-4,5-6,7",
            "1-2,3,4,5-7",
            "1-2,3,4-6,7",
            "1-2,3-5,6,7",
            "1-3,4,5,6-7",
            "1-3,4,5-6,7",
            "1-3,4-5,6,7",
            "1-2,3-4,5-7",
            "1-2,3-5,6-7",
            "1-3,4-5,6-7",
            "1,2,3,4-7",
            "1,2,3-6,7",
            "1,2-5,6,7",
            "1-4,5,6,7",
            "1,2-4,5-7",
            "1-3,4,5-7",
            "1-3,4-6,7",
            "1,2-3,4-7",
            "1,2-5,6-7",
            "1-2,3,4-7",
            "1-2,3-6,7",
            "1-4,5,6-7",
            "1-4,5-6,7",
            "1,2,3-7",
            "1,2-6,7",
            "1-5,6,7",
            "1-3,4-7",
            "1-4,5-7",
            "1-2,3-7",
            "1-5,6-7",
            "1,2-7",
            "1-6,7",
            "1-7",
        ],
    },
};

export default couponDefs;
