// eslint-disable-next-line @nx/enforce-module-boundaries
import {type Channel} from "@atg-play-shared/live-channels";
import type {TypesOfMediaContent} from "@atg/utils/player-util/types";
import {PlayerActionTypes} from "./actionconstants";
import type {PlayerPlayTargets} from "./actionconstants";
import {PlayerKeys} from "./playerReducer";

interface SourceOptions {
    player?: string;
    playlist?: Array<string>;
    autoPlay: boolean;
    source: string;
}

export interface PlayAction {
    type: PlayerActionTypes.PLAYER_PLAY;
    payload: {
        player: string;
        target?: PlayerPlayTargets; // specific which user event that triggers this action
    };
}

export interface PlayActionInitLoad {
    type: PlayerActionTypes.PLAYER_PLAYER_INIT_ON_LOAD;
    payload: {
        player: TypesOfMediaContent;
    };
}

export function play(player: string, target?: PlayerPlayTargets): PlayAction {
    return {
        type: PlayerActionTypes.PLAYER_PLAY,
        payload: {
            player,
            target,
        },
    };
}

export function pause(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_PAUSE,
        payload: {
            player,
        },
    };
}

export function trackCompleted(player: string) {
    return {
        type: PlayerActionTypes.PLAYER_TRACK_COMPLETED,
        payload: {
            player,
        },
    };
}

export function source(player: string, options: SourceOptions) {
    return {
        type: PlayerActionTypes.PLAYER_SOURCE,
        payload: {
            player,
            ...options,
        },
    };
}

export function startMediaSource(autoPlay: boolean, mediaType: string) {
    return {
        type: PlayerActionTypes.START_PLAYER_MEDIA_SOURCE,
        payload: {
            autoPlay,
            mediaType,
        },
    };
}

export function previous(player: string, position?: number) {
    return {
        type: PlayerActionTypes.PLAYER_PREVIOUS_TRACK,
        payload: {
            player,
            position,
        },
    };
}

export function next(player: string, position?: number) {
    return {
        type: PlayerActionTypes.PLAYER_NEXT_TRACK,
        payload: {
            player,
            position,
        },
    };
}

export function setFullscreen(player: string, inFullscreen: boolean) {
    return {
        type: PlayerActionTypes.PLAYER_IN_FULLSCREEN,
        payload: {
            player,
            inFullscreen,
        },
    };
}

export function setLiveChannel(channel: Channel) {
    return {
        type: PlayerActionTypes.SET_LIVE_CHANNEL,
        payload: {
            player: PlayerKeys.LIVE_PLAYER,
            channel,
        },
    };
}

export function setLiveStream(streamUrl: string) {
    return {
        type: PlayerActionTypes.SET_LIVE_STREAM,
        payload: {
            player: PlayerKeys.LIVE_PLAYER,
            streamUrl,
        },
    };
}

export function setLiveQuality(quality: string) {
    return {
        type: PlayerActionTypes.SET_LIVE_CHANNEL,
        payload: {
            player: PlayerKeys.LIVE_PLAYER,
            quality,
        },
    };
}
/**
 * Actions for setting radar menu current state in the controlbar
 */
export function openRadarMenu(player: string) {
    return {
        type: PlayerActionTypes.OPEN_RADAR_MENU,
        payload: {
            player,
        },
    };
}
export function closeRadarMenu(player: string) {
    return {
        type: PlayerActionTypes.CLOSE_RADAR_MENU,
        payload: {
            player,
        },
    };
}

export function setLiveFreeTimeOnLoad(player: TypesOfMediaContent): PlayActionInitLoad {
    return {
        type: PlayerActionTypes.PLAYER_PLAYER_INIT_ON_LOAD,
        payload: {
            player,
        },
    };
}
