import {combineReducers, type Reducer} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {serverTime} from "@atg-shared/server-time";
import log from "@atg-shared/log";
import {
    REQUESTED_EXTENDED_RACE,
    RECEIVED_EXTENDED_RACE,
} from "./extendedStartActionTypes";
import type {ExtendedStartAction} from "./extendedStartActions.flow";
import type {
    ExtendedRace,
    ExtendedStartRaces,
    ExtendedStartData,
    ExtendedStartState,
    ExtendedStartPending,
    ExtendedStartError,
    ExtendedStarts,
} from "./extendedStart.flow";

type IsPendingReducer = Reducer<ExtendedStartPending, ExtendedStartAction>;
type ExtendedStartErrorReducer = Reducer<ExtendedStartError, ExtendedStartAction>;
type ExtendedStartDataReducer = Reducer<ExtendedStartRaces, ExtendedStartAction>;

const getResults = (extendedRace: ExtendedRace): ExtendedStarts => {
    const raceId = extendedRace.id;
    const currentTime = serverTime().valueOf();
    try {
        const starts = extendedRace.starts.reduce((results, start: ExtendedStartData) => {
            const startId = `${raceId}_${start.number}`;
            // @ts-expect-error
            results[startId] = {
                previousResults: start.horse.results,
                comments: start.comments,
            };
            return results;
        }, {});

        return {
            ...starts,
            createdAt: currentTime,
        };
    } catch (e: unknown) {
        log.error("Failed to parse starts from previous results", {
            additionalData: {error: e, raceId},
        });
        return {createdAt: currentTime};
    }
};

const isPending: IsPendingReducer = (state = {}, action) => {
    switch (action.type) {
        case REQUESTED_EXTENDED_RACE:
            return {
                ...state,
                [action.payload.raceId]: true,
            };
        case RECEIVED_EXTENDED_RACE:
            return {
                ...state,
                [action.payload.raceId]: false,
            };
        default:
            return state;
    }
};

const error: ExtendedStartErrorReducer = (state = {}, action) => {
    // @ts-expect-error
    if (action.type !== RECEIVED_EXTENDED_RACE || !action.error) return state;

    return {
        ...state,
        // @ts-expect-error
        [action.payload.raceId]: action.error,
    };
};

const data: ExtendedStartDataReducer = (state = {}, action) => {
    switch (action.type) {
        case RECEIVED_EXTENDED_RACE: {
            const listOfRaceIds = Object.keys(state);
            const numberOfStoredPreviousStarts = listOfRaceIds.length;
            if (numberOfStoredPreviousStarts > 24) {
                delete state[listOfRaceIds[0]];
            }
            // @ts-expect-error
            if (action.error) return {};
            return {
                ...state,
                // @ts-expect-error
                [action.payload.raceId]: getResults(action.payload.data),
            };
        }
        default:
            return state;
    }
};

const extendedStartPersistConfig = {
    storage,
    key: "extendedStart",
    version: 0,
    blacklist: ["isPending", "error"],
};

const extendedStartReducer: Reducer<ExtendedStartState, ExtendedStartAction> =
    combineReducers({
        data,
        error,
        isPending,
    });

export default persistReducer(extendedStartPersistConfig, extendedStartReducer);
