import {find, isEmpty, isUndefined, max} from "lodash/fp";
import type {ReducedCoupon} from "@atg-horse-shared/coupon-types";
import * as gameUtils from "@atg-horse-shared/utils/game";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {CouponState as CouponStateSlice} from "@atg-horse-shared/coupon";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {HorseState} from "@atg-horse-shared/horse-state";
import type {
    ReducedBetGrading,
    ReductionMetadata,
    ReductionTerms,
} from "@atg-horse-shared/reduced-bet-types";
import type {ReducedCouponSettings} from "./reducedBet";

type GlobalState = CouponStateSlice & {
    horse: HorseState;
};

export const getReducedBets = (state: GlobalState) => state.horse.reducedBets;

export const getReductionTerms = (
    state: GlobalState,
    cid: string | null | undefined,
): ReductionTerms | null | undefined => state.horse.reducedBets?.[cid || ""];

export const getGrading =
    (cid: string | null | undefined) =>
    (state: GlobalState): ReducedBetGrading | null | undefined =>
        state.horse.reducedBets?.[cid || ""]?.grading;

export const showRaceWinner =
    (cid: string, raceNumber: number) => (state: GlobalState) => {
        const grading = getGrading(cid)(state);
        if (grading?.resultsToShow) {
            return !isUndefined(
                find((result) => result === raceNumber, grading.resultsToShow),
            );
        }
        return false;
    };

/**
 * calculates the number of correct guesses so far in the grading
 */
export const getNumberOfCorrectWinners =
    (cid: string) =>
    (state: GlobalState): number => {
        const grading = getGrading(cid)(state);
        if (
            !grading?.data ||
            isEmpty(grading.data.betType) ||
            isEmpty(grading?.resultsToShow)
        )
            return -1;

        const {data} = grading;

        const latestOpenRaceIndex = max(grading.resultsToShow);

        const amountOfRaces = gameUtils.getNumberOfRacesFromGameType(data.betType);
        const incorrectRaces =
            // @ts-expect-error
            amountOfRaces - data.races[latestOpenRaceIndex].potentialCorrects;

        return (
            // resultsToShow is 0-based
            // @ts-expect-error
            // and substract amount of incorrect guessed races
            latestOpenRaceIndex + // so need to add 1 for real race number
            1 -
            incorrectRaces
        );
    };

export const getReductionRankings = (
    state: GlobalState,
    cid: string,
    raceNumber: number,
): Array<number> => state.horse.reducedBets[cid].ranking[raceNumber];

export const getReducedCouponSettings =
    (cid: string) =>
    (state: GlobalState): ReducedCouponSettings => {
        // bet method and systems are in the coupons
        const coupon = state.coupons?.[cid];
        // newer field reserveType added only for reduced coupons
        const reducedCoupon = state.horse.reducedBets[cid];

        return {
            systems: coupon?.systems,
            betMethod: coupon?.betMethod,
            reserveType: reducedCoupon?.reserveType,
        };
    };

export const getReducedBetLoading = (state: GlobalState) =>
    Boolean(state.horse.reducedBets?.loading);

export const getReducedBetErrorResponse = (state: GlobalState) =>
    state.horse.reducedBets?.errorResponse;

export const getHorseLetter =
    (cid: string, raceNumber: number, startNumber: number) => (state: GlobalState) =>
        state.horse.reducedBets?.[cid]?.letters?.races?.[raceNumber]?.starts?.[
            startNumber
        ];

export const getHorsePoints =
    (cid: string, raceNumber: number, startNumber: number) => (state: GlobalState) =>
        state.horse.reducedBets?.[cid]?.points?.races?.[raceNumber]?.starts?.[
            startNumber
        ];

export const getHorseIsBaseSelection =
    (cid: string, raceNumber: number, startNumber: number) => (state: GlobalState) => {
        const reductionTerms = state.horse.reducedBets[cid];
        return reductionTerms?.baseSelections?.[reductionTerms?.activeSet]?.races?.[
            raceNumber
        ]?.starts?.[startNumber];
    };

export const getNumberOfSelectedHorses =
    (cid: string, raceNumber: number) => (state: GlobalState) =>
        // @ts-expect-error
        state.coupons?.[cid]?.races?.[raceNumber]?.bets?.length;

export const getRanking = (cid: string, raceNumber: number) => (state: GlobalState) =>
    state.horse.reducedBets?.[cid]?.ranking?.[raceNumber];

export const getHorseIncludedPercentage =
    (cid: string | null | undefined, row: number, horseNumber: number) =>
    (state: GlobalState) => {
        if (!cid) return undefined;
        const horse = find(
            ({startNumber}) => startNumber === horseNumber,
            state.horse.reducedBets?.[cid]?.reductionMetadata?.horseRowCounts[row],
        );
        return horse?.percentageOfRowsIncludedIn;
    };

/**
 * Selector for getting reductionMetdata if it exist, otherwise default data is returned
 */
export const getReductionMetadata =
    (cid: string) =>
    (state: GlobalState): ReductionMetadata => {
        const coupon = state.coupons[cid] as ReducedCoupon;
        const reductionTerms = state.horse.reducedBets[cid];
        if (coupon && reductionTerms?.reductionMetadata) {
            return reductionTerms.reductionMetadata;
        }
        return {
            willBeRejected: false,
            loading: false,
            error: null,
            rowsAfterReducing: coupon?.rows || 0,
            rowsBeforeReducing: coupon?.rows || 0,
            amountOfCoupons: 1,
            horseRowCounts: [],
            outcomeInterval: {
                I1_500: 0,
                I501_1000: 0,
                I1001_5000: 0,
                I5001_10000: 0,
                I10001_50000: 0,
                I50001_100000: 0,
                I100001_500000: 0,
                I500001_MAX: 0,
            },
        };
    };
