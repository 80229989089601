import {curry} from "lodash/fp";
import type {GlobalVideoState} from "../video";
import * as PlayerReducer from "./playerReducer";

export const getPlayer = (id: PlayerReducer.PlayerKeys) => (state: GlobalVideoState) => {
    if (!state?.video?.players) return undefined;
    return state.video.players[id];
};
export const isPlaying = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerKeys,
): boolean => getPlayer(id)(state)?.playing ?? false;
export const getIsPlaying =
    (id: PlayerReducer.PlayerKeys) =>
    (state: GlobalVideoState): boolean =>
        getPlayer(id)(state)?.playing ?? false;
export const isTrackCompleted = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerKeys,
): boolean => getPlayer(id)(state)?.trackCompleted ?? false;
export const getStreams = (state: GlobalVideoState, id: PlayerReducer.PlayerKeys) =>
    getPlayer(id)(state)?.streams ?? null;
export const getSource = (state: GlobalVideoState, id: PlayerReducer.PlayerKeys) =>
    getPlayer(id)(state)?.source ?? null;
export const getPlaylist = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerKeys,
): Array<string> | null => getPlayer(id)(state)?.playlist ?? null;
export const getPlaylistPos = (state: GlobalVideoState, id: PlayerReducer.PlayerKeys) =>
    getPlayer(id)(state)?.playlistPos ?? null;
export const getWarmupPlaylistPos = (state: GlobalVideoState) =>
    getPlaylistPos(state, PlayerReducer.PlayerKeys.WARMUP_PLAYER);

export const getStreamName = (state: GlobalVideoState, id: PlayerReducer.PlayerKeys) =>
    getPlayer(id)(state)?.streamName ?? "";
export const getImageURL = (state: GlobalVideoState, id: PlayerReducer.PlayerKeys) =>
    getPlayer(id)(state)?.imageURL ?? "";
export const isPlayerInFullscreen = (
    state: GlobalVideoState,
    id: PlayerReducer.PlayerKeys,
) => getPlayer(id)(state)?.inFullscreen ?? false;

export const isRadarMenuVisible = curry(
    (id: PlayerReducer.PlayerKeys, state: GlobalVideoState) =>
        getPlayer(id)(state)?.activeMenu === PlayerReducer.ActiveMenu.RADAR_MENU,
);

export const getLiveChannelQuality = (state: GlobalVideoState) =>
    state.video.players.live.quality;
export const getLiveChannel = (state: GlobalVideoState) =>
    state.video.players.live.channel;

export const getLiveStream = (state: GlobalVideoState) =>
    state.video.players.live.streamUrl;
