import {find, findKey, flow, get, includes, join, map, uniqBy} from "lodash/fp";
import dayjs from "dayjs";
import type {
    Game,
    GameRace,
    MergedPool,
} from "@atg-horse-shared/racing-info-api/game/types";
import type {GameType} from "@atg-horse-shared/game-types";
import {GameTypes} from "@atg-horse-shared/game-types";
import * as gameUtils from "../game";
import {getNameFromGameType} from "../game";

// TODO: reuse existing pool instead
type Pool = {
    id: string;
    mergedPool?: MergedPool;
    turnover: number;
    betType: GameType;
};

type CalendarPoolInput = {
    [key: string]: Pool & {
        timestamp: string;
    };
};

export type CalendarPool = Pool & {
    updateTime: string;
    isMergedPool: boolean;
};

export function getVinnarePoolWinners(pools: {[key: string]: any}): Array<{
    [key: string]: any;
}> {
    const vinnarePool = pools.vinnare;
    if (!vinnarePool || !vinnarePool.result) return [];
    return vinnarePool.result.winners;
}

export function getTop7Place(
    racePool: {
        [key: string]: any;
    },
    startNumber: number,
): number | null | undefined {
    if (!racePool || !racePool.result || !racePool.result.winners) return undefined;
    const {winners} = racePool.result;
    const placeString = findKey(includes(startNumber), winners);
    if (!placeString) return undefined;
    return parseInt(placeString, 10);
}

// indirectly tested by components, e.g. SingleRaceGameTurnover
// TODO: just take `Game` as input?
export const reduceByMergedPools = (
    pools: CalendarPoolInput,
    race: GameRace | null | undefined,
): Array<CalendarPool> => {
    const mergedPools = get(["mergedPools"], race);

    // pools that are within merged pools will have the same data (like turnover)
    // so we filter out duplicates of {mergedPool.name}
    // @ts-expect-error
    return uniqBy(
        get(["mergedPool", "name"]),
        map((pool) => {
            // find if pool is in any of the merged pools
            const mergedPool: MergedPool | null | undefined = find(
                (mp) => mp.betTypes.includes(pool.betType),
                mergedPools,
            );
            return {
                betType: pool.betType, // will be meaningless for merged pools
                turnover: pool.turnover,
                updateTime: dayjs(pool.timestamp).format("HH:mm"),
                id: pool.id, // for merged pools it will be just an id of one of the games in the pool
                mergedPool: mergedPool || {name: pool.id}, // if not a merged pool, supply a unique name for uniqBy
                isMergedPool: Boolean(mergedPool),
            };
        }, pools),
    );
};

export const getCurrentPool = (currentGame: Game, pools: Array<CalendarPool>) =>
    pools.find((pool) => {
        const mergedBetTypes = get(["mergedPool", "betTypes"], pool);
        if (currentGame.type === GameTypes.vp && pool.betType) {
            return pool.betType === GameTypes.vinnare || pool.betType === GameTypes.plats;
        }
        if (mergedBetTypes) {
            return mergedBetTypes.includes(currentGame.type);
        }
        return currentGame.id === pool.id;
    });

export const labelMergedPools = (
    mergedPool?: MergedPool,
    divider = "+",
    shouldUseShortNames?: boolean,
) =>
    flow([
        get("betTypes"),
        map((betType: GameType | "top7pro") =>
            shouldUseShortNames
                ? gameUtils.getMergedPoolsShortName(betType)
                : getNameFromGameType(betType),
        ),
        join(divider),
    ])(mergedPool);
