import {
    replace,
    omit,
    flow,
    reverse,
    chunk,
    reduce,
    split,
    find,
    get,
    toNumber,
    head,
    add,
    map,
    sortBy,
    uniq,
    slice,
    indexOf,
} from "lodash/fp";
import produce from "immer";
import {formatCurrency} from "@atg-shared/currency";
import type {AtgRequestError} from "@atg-shared/fetch-types";
import {type GameType, GameTypes, COMBINATION_GAMES} from "@atg-horse-shared/game-types";
import type {BetMethod} from "@atg-horse-shared/bet-types";
import type {ErrorItem} from "@atg-horse/error-messages";
import type {
    Game,
    GameRace,
    Start as GameStart,
} from "@atg-horse-shared/racing-info-api/game/types";
import * as StartUtils from "@atg-horse-shared/utils/start";
import {
    CouponTypes,
    type ReducedCoupon,
    type CombinationGameCouponRace,
    type DivisionGameCouponRace,
} from "@atg-horse-shared/coupon-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import {getGameTypefromCouponGame} from "@atg-horse-shared/utils/game/v3Utils";
import type {
    LetterRank,
    LettersRestrictions,
    MinMax,
    OutcomeInterval,
    ReductionTerms,
    ReserveType,
    ExpectedOutComesRestrictions,
    ReductionMetadata,
} from "@atg-horse-shared/reduced-bet-types";

export const LETTER_RESTRICTION = "letters" as const;
export const BASE_SELECTIONS_RESTRICTION = "baseSelections" as const;
export const POINTS_RESTRICTION = "points" as const;
export const EXPECTED_OUTCOME_RESTRICTION = "expectedOutcome" as const;
export const GRADING = "grading" as const;
export const RESERVES = "reserves" as const;

export enum ReducedBatchBetJobStatus {
    /** added to queue */
    ACCEPTED = "ACCEPTED",

    /** currently being processed */
    PROCESSING = "PROCESSING",

    /** batch bet was successfully handed in */
    DONE = "DONE",

    /** technical problem, see `errors` */
    FAILED = "FAILED",

    /** rejected due to business logic constraint (e.g. too expensive, too many systems) */
    REJECTED = "REJECTED",

    /** no batch bet with that ID was found */
    MISSING = "MISSING",
}

export enum SortingValues {
    betDistribution = "betDistribution",
    odds = "odds",
    startNo = "startNo",
}
export type ReductionType =
    | typeof LETTER_RESTRICTION
    | typeof POINTS_RESTRICTION
    | typeof BASE_SELECTIONS_RESTRICTION
    | typeof EXPECTED_OUTCOME_RESTRICTION;

export type Tab = ReductionType | typeof RESERVES | typeof GRADING;

export type OutcomeIntervalKeys = keyof OutcomeInterval;

/**
 * Response from the batch bet service "bet" endpoint (`/horse-batch-betting/api/v1/reduce/bet`)
 *
 * @param id the batch-betting service job ID
 * @param batchBetId
 * The ID generated in the betting system, which can be used in other (older) APIs. It will only
 * exist once the job is completed with status `DONE`
 */
export type ReducedBatchBetResponse = {
    id: string;
    batchBetId: number | null;
    status: ReducedBatchBetJobStatus;
    jobRequestedAt: string;
    jobCompletedAt: string;
    errors: Array<ErrorItem>;
    type: string;
};

export interface ReducedCouponSettings {
    systems: number;
    reserveType: ReserveType;
    betMethod: BetMethod | null | undefined;
}

interface ReductionTermsBet {
    loading?: boolean;
    error: boolean | null;
    errorResponse: AtgRequestError;

    // Add a loading field to check if bet is still loading when polling the reduced jobs
    bet: ReductionTermsBet;
}

export interface ReductionTermsState extends ReductionTermsBet {
    // @ts-expect-error Type issues
    [key: string]: ReductionTerms;
}

// @ts-expect-error
const mapWithIndex = map.convert({cap: false});

/**
 * Type from batch bet
 *
 * API endpoint: `horse-batch-betting/api/v1/reduce/bet`
 * {@link https://qa-batch-betting.dev.horsedigital.aws.atg.se/swagger-ui.html Batch bet API doc}.
 */
export const reducedBetType = "reductionBatchBettingJob" as const;

export const getRaceCondition = (condition?: string): string => {
    switch (condition) {
        case "light":
        case "light-trot":
            return "lätt bana";
        case "dead-trot":
            return "något tung bana";
        case "abandoned-trot":
            return "struket lopp som ej omköres";
        case "heavy-trot":
            return "tung bana";
        case "winter-trot":
            return "vinterbana";
        case "light-gallop":
            return "lätt";
        case "dead-gallop":
            return "något tung";
        case "heavy-gallop":
            return "tung";
        case "fast-gallop":
            return "snabb";
        case "good-gallop":
            return "god";
        case "slow-gallop":
            return "långsam";
        case "wet-gallop":
            return "blöt";
        case "soft-gallop":
            return "mjuk";
        case "good-light-gallop":
            return "god till lätt";
        case "good-soft-gallop":
            return "god till mjuk";
        case "standard-gallop":
            return "standard";
        default:
            return "";
    }
};

export const getStartMethod = (startMethod?: string): string => {
    switch (startMethod) {
        case "auto":
            return "autostart";
        case "volte":
            return "voltstart";
        case "line":
            return "linjestart";
        default:
            return "";
    }
};

export const getRaceSport = (race: GameRace): string => {
    const translateSport = {
        trot: "Trav",
        monté: "Monté",
        gallop: "Galopp",
    };

    return translateSport[race.sport];
};

/**
 * Return spaces on every third char in a string
 */
export const addSpacingOnThridCharInString: (arg: string) => string = flow(
    split(""),
    reverse,
    chunk(3),
    reduce((a, b) => `${reverse(b).join("")} ${a}`, ""),
);
export const getPrizeText = (prizeString: string): string =>
    flow(replace(/^Pris: /, ""), replace(/kr\.$/, ""), replace(/-/g, " - "))(prizeString);

/**
 * Return all added outcomes as number from `outcomeInterval`
 */
// @ts-expect-error
export const addAllOutcomes: (value: OutcomeInterval) => number = reduce<number, number>(
    add,
    0,
);

/**
 * Return start value and end value of the interval as Array of number where the first element is start and second is the end value
 * @param value from backend form of `I1_500` were `I` is start of the interval after `_` is the end of the interval
 */
const selectStartAndEndOfTheOutcomeInterval: (
    value: OutcomeIntervalKeys,
) => Array<number> = flow(replace("I", ""), split("_"), map(toNumber));

/**
 * Sort the `outcomInterval` based on the size of the intervals start value
 */
export const sortOutcomeIntervalByIntervalStartSize: (
    value: Array<OutcomeIntervalKeys>,
) => Array<OutcomeIntervalKeys> = sortBy(
    flow(selectStartAndEndOfTheOutcomeInterval, head),
);

/**
 * Add decimal spaces and format currency for start and end of interval
 * (1000 -> 1 000, 100000 -> 100 000)
 */
const addDecimalSpacesToStartAndEndInterval = (start: number, end: number) => {
    const newStart = formatCurrency(start * 100, {
        hideDecimals: true,
        hideCurrency: true,
    });
    const newEnd = formatCurrency(end * 100, {
        hideDecimals: true,
        hideCurrency: true,
    });
    return `${newStart} - ${newEnd} kr`;
};

export const getDistributionIntervalText = (interval: OutcomeIntervalKeys) => {
    const [start, end] = selectStartAndEndOfTheOutcomeInterval(interval);
    return addDecimalSpacesToStartAndEndInterval(start, end);
};

/**
 * Calculate the total cost for a reduced bet
 *
 * This function can be used both to calculate the "frame cost" (how much the bet would cost before
 * any reductions) or the actual bet cost (after reduction).
 *
 * @param coupon - the coupon that contains:
 * - stake - predefined (division games) or user selected (eg ld, dd) cost per row
 * - systems - How many systems the user has selected **NOTE:** This is not the same as
 *   `effectiveSystems`, which we get as part of the reduction, which is how many systems will be
 *   placed into the betting system (not the same number in case of V6, GS7 and other "VXOnly bets").
 * - rows - the number of rows in the bet before reduction (used in case there is no reduction)
 * @param reductionTerms - reduction terms for the coupon. contains rowsAfterReducing
 */
export const getReducedCost = (
    coupon: ReducedCoupon,
    reductionTerms: ReductionTerms | null | undefined,
) => {
    const {stake, systems, rows} = coupon;
    // If the user hasn't performed a reduction yet (no restrictions added), there will be no
    // `reductionMetadata`, and we just use the normal `coupon.rows` since that will be correct in
    // this scenario.
    const rowsAfterReducing =
        reductionTerms?.reductionMetadata?.rowsAfterReducing || rows;

    // Systems can be undefined for single race games such as `LD` `DD`
    const correctSystems = systems ?? 1;
    return rowsAfterReducing * correctSystems * stake;
};

export const getReductionPercentage = (
    rowsBeforeReducing: number,
    rowsAfterReducing: number,
) => Math.round((rowsAfterReducing / rowsBeforeReducing) * 100) || 0;

/**
 * In analytics it is important to get the correct quantity based on amount of coupons
 * after a reduced bets has been calculated.
 * Also to get correct analytic price which is the price divided by amount of coupons.
 *
 * This function calculate the correct `product_price` and `product_quantity` for reducedCoupon
 *
 */
export const getReducedPurchaseAnalyticsValue = (
    coupon: ReducedCoupon,
    reductionTerms?: ReductionTerms,
) => {
    // event `product_details` sometimes contain empty reductionTerms,
    // gtm then takes quantity and price as undefined,
    if (!reductionTerms) {
        return {
            quantity: undefined,
            price: undefined,
        };
    }
    const quantity = reductionTerms.reductionMetadata?.amountOfCoupons || 1;
    return {
        quantity: quantity.toString(),
        price: (getReducedCost(coupon, reductionTerms) / 100).toString(),
    };
};

/**
 * Calculate how many systems will be placed into the betting system for a reduced coupon. This
 * number is called `effectiveSystems`.
 *
 * - for a normal coupon this is usually just `1`
 * - if the user has selected a "system multiplier" that is included in the calculation
 * - if the game type is V64, V65 or GS75 **and** the user has selected `VXOnly`, an internal
 *   multiplier is added
 * - for a reduced system, the amount of coupons (and therefore systems) that will be placed is
 *   usually more than `1`
 *
 * Explanation of "internal multiplier" (e.g. V6 only):
 *
 * Example: For a V64 pool the money is split into 40%/20%/40% for 6 correct, 5 correct and 4
 * correct, respectively. If you play "V6 only" your whole bet goes into the V6 pool, so if you win
 * you should get a 2.5 times larger share compared to a player who placed a normal V64 and won with
 * 6 correct horses, to compensate for your higher risk.
 */
export const calculateEffectiveSystems = (
    coupon: ReducedCoupon,
    reductionTerms: ReductionTerms | null | undefined,
) => {
    let vxOnlyMultiplier = 1;
    if (coupon.betMethod === "onlyVx") {
        if (coupon.game.type === GameTypes.V64 || coupon.game.type === GameTypes.GS75)
            vxOnlyMultiplier = 2.5;
        if (coupon.game.type === GameTypes.V65) vxOnlyMultiplier = 2;
    }
    // Systems can be undefined for single race games such as `LD` `DD`
    const systems = coupon.systems ?? 1;
    return (
        // user-selected "systems multiplier"
        // if the user is doing a reduction the system probably includes more than one coupon, so
        // take that into consideration as well
        systems * // V6 only V64/V65 games have internal multipliers
        vxOnlyMultiplier *
        (reductionTerms?.reductionMetadata?.amountOfCoupons ?? 1)
    );
};

/**
 * Create a batch bet object that can be sent to the backend
 * See: `horse-batch-betting/api/v1/reduce/bet`
 */
export const createBatchBetApiBody =
    (
        reductionTerms: ReductionTerms,
        inputGame: Game,
        returnToPlayer: number | null | undefined,
    ) =>
    (coupon: ReducedCoupon) => {
        const transformedReductionTerms = flow(
            omit(["activeSet"]),
            omit(["reductionMetadata"]),
            // Varenne V3 should have both "Reserver" and "Utdelning"
            // Varenne V3 Change this to {[...COMBINATION_GAMES].includes(coupon.game.type as GameTypes,)} when V3Legacy support is dropped
            omit(
                [...COMBINATION_GAMES, GameTypes.V3Legacy].includes(
                    // Needed to cast to GameTypes because ReducedCoupon.game.type is not correct enum. Can be removed when fixed.
                    getGameTypefromCouponGame(coupon.game) as GameTypes,
                )
                    ? ["expectedOutcome", "activeSet"]
                    : [],
            ),
        )(reductionTerms);
        // Sanitize races from game because a) it is not needed and b) it breaks on certain horse names because sql injection protection, e.g. union
        const game = omit(["races"], inputGame);
        const bet = CouponUtils.createBet(coupon, {game});

        /*
         * Backend needs the pool object in expectedOutcome.
         * If the coupon lacks reduction then expectedOutcome lacks a pool object
         * There is a pool object in game.pools we can use instead if there are no reductions
         */
        if (
            transformedReductionTerms.expectedOutcome &&
            // @ts-expect-error
            !transformedReductionTerms.expectedOutcome.pool
        ) {
            const gamePools = {...game.pools};
            // @ts-expect-error
            transformedReductionTerms.expectedOutcome.pool = gamePools[bet.game.type];
        }

        return {
            ...bet,
            game: produce(game, (draftGame) => {
                // @ts-expect-error
                draftGame.returnToPlayer = returnToPlayer;
            }),
            cost: getReducedCost(coupon, reductionTerms),
            id: coupon.id,
            type: CouponTypes.REDUCED,
            reductionTerms: transformedReductionTerms,
        };
    };

// @ts-expect-error
const allRankingsFromCouponRace: (
    races: Array<DivisionGameCouponRace> | Array<CombinationGameCouponRace>,
) => Array<Array<number>> = map(
    flow(get("bets"), map(StartUtils.parseStartId), map("startNumber")),
);

const sortByBetDistribution = (
    starts: Array<GameStart>,
    gameType: string,
): Array<number> =>
    flow(
        sortBy(get(`pools[${gameType}].betDistribution`)),
        reverse, // always place scratched horses last
        // Note: _.sortBy is a "stable sort", so if two elements get the same sort value (e.g. `0`
        // below), they will keep their existing sort order, which is why this works.
        sortBy((start) => (start.scratched ? Infinity : 0)),
        map("number"),
    )(starts);

const sortByOdds = (starts: Array<GameStart>): Array<number> =>
    flow(
        sortBy(get("pools.vinnare.odds")),
        sortBy((start) => (start.scratched ? Infinity : 0)),
        map("number"),
    )(starts);

const sortByBetStartnumber = (starts: Array<GameStart>): Array<number> =>
    flow(
        sortBy((start: GameStart) => start.number),
        sortBy((start) => (start.scratched ? Infinity : 0)),
        map("number"),
    )(starts);

export const sortStarts = (
    starts: Array<GameStart>,
    sortType: SortingType,
    gameType: string,
): Array<number> => {
    switch (sortType) {
        case SortingValues.betDistribution: {
            return sortByBetDistribution(starts, gameType);
        }
        case SortingValues.odds: {
            return sortByOdds(starts);
        }
        case SortingValues.startNo: {
            return sortByBetStartnumber(starts);
        }
        default: {
            return [];
        }
    }
};

// @ts-expect-error
export const numberOfSelectedHorses: (
    races: Array<DivisionGameCouponRace> | Array<CombinationGameCouponRace>,
) => Array<number> = map((couponRace) => couponRace.bets.length);

const sortRanking: (
    game: Game,
    sortValue: SortingType,
) => (rannking: Array<Array<number>>) => Array<Array<number>> = (game, sortValue) =>
    // @ts-expect-error
    mapWithIndex((raceRanking, index: number) => {
        const {races, type} = game;
        const race = races[index];
        // @ts-expect-error
        const startsToBeSorted: Array<GameStart> = map(
            (startNumber) => find<GameStart>({number: startNumber}, race.starts),
            raceRanking,
        );
        return sortStarts(startsToBeSorted, sortValue, type);
    });

/**
 * Return selected bets for each race as `startNumber` instead of `raceId` and
 * add it first in the reductionTerms ranking array
 * @returns [ race -> [horseNumber], reducionTerms.ranking -> [ranking] ]
 */
export const sortRankingBasedOnCouponBets = (
    game: Game,
    reductionTerms: ReductionTerms,
    races: Array<DivisionGameCouponRace> | Array<CombinationGameCouponRace>,
    sortValue: SortingType,
) =>
    flow(
        allRankingsFromCouponRace,
        sortRanking(game, sortValue),
        // @ts-expect-error
        mapWithIndex((couponBetRanking, index) =>
            uniq([...couponBetRanking, ...reductionTerms.ranking[index]]),
        ),
    )(races);

const selectedHorses = (
    ranking: Array<Array<number>>,
    numberOfSlectedHorse: Array<number>,
): Array<Array<number>> =>
    mapWithIndex(
        // @ts-expect-error
        (selectedHorse, index) => slice(0, selectedHorse, ranking[index]),
        numberOfSlectedHorse,
    );

const unselectedHorses: (
    ranking: Array<Array<number>>,
) => (currentlySelectedHorses: Array<number>) => Array<Array<number>> = (ranking) =>
    // @ts-expect-error
    mapWithIndex((selectedHorse, index) =>
        slice(selectedHorse, ranking[index].length, ranking[index]),
    );

/**
 * Return rankings sorted based on given sortValue `betDistribution` `odds` `startNo`
 * only sort unselected horses, keep the same order for selectedHorses
 */
export const sortUnselectedHorses = (
    reductionTerms: ReductionTerms,
    game: Game,
    currentlySelectedHorses: Array<number>,
    sortValue: SortingType,
): Array<Array<number>> =>
    // @ts-expect-error
    flow(
        unselectedHorses(reductionTerms.ranking),
        sortRanking(game, sortValue),
        // @ts-expect-error
        mapWithIndex((rankPerRace, index) => [
            ...selectedHorses(reductionTerms.ranking, currentlySelectedHorses)[index],
            ...rankPerRace,
        ]),
    )(currentlySelectedHorses);

const LETTER_ORDER = ["A", "B", "C", "D", "E"];

const getNextLetterIndex = (letter: LetterRank, letterArray: Array<LetterRank>): number =>
    (indexOf(letter, letterArray) + 1) % letterArray.length;

export const getNextLetter = (letter: LetterRank): LetterRank =>
    // @ts-expect-error
    LETTER_ORDER[getNextLetterIndex(letter, LETTER_ORDER)];

export const getPreviousLetter = (letter: LetterRank): LetterRank => {
    // @ts-expect-error
    const reversedLetterOrder: Array<LetterRank> = reverse(LETTER_ORDER);
    return reversedLetterOrder[getNextLetterIndex(letter, reversedLetterOrder)];
};

export const GRADING_STATUS_FINAL = "FINAL" as const;
export const GRADING_STATUS_LIVE = "LIVE" as const;

export const RESERVE_TYPE_NEXT_RANK = {
    label: "Nästa rankade",
    value: "NEXT_RANK",
};

export const RESERVE_TYPES = [
    RESERVE_TYPE_NEXT_RANK,
    {
        label: "Topprankade",
        value: "BEST_RANK",
    },
    {
        label: (gameType: GameType) => `${gameType}% (fallande)`,
        value: "STANDARD",
    },
];

export const getApplicableSystemMultipliers = (gameType: GameType) => {
    switch (gameType) {
        case "V75":
        case "V86":
        case "V5":
        case "GS75":
            return [1, 2, 5, 10, 20, 50, 100];
        case "V64":
        case "V65":
        case "V4":
        case "V3":
            return [1, 2, 3, 4, 5, 10, 50, 100];
        default:
            return [];
    }
};

export type LetterRankRestrictions = Array<{
    enabled: boolean;
    letterRestrictions: {
        [key in LetterRank]: MinMax;
    };
}>;

export type SortingType = keyof typeof SortingValues;

type Amounts = {
    sellingFee: number;
    totalCost: number;
};

export type PlaceReducedBetResponse = {
    amounts: Amounts;
    numberOfCoupons: number;
    numberOfCombinations: number;
    placedAt: string;
};

/**
 * Response from the batch bet service "jobs" endpoint (`/horse-batch-betting/api/v1/jobs/<id>`)
 *
 * This response has the same structure as the ReducedBatchBetResponse
 *
 * @param id the batch-betting service job ID
 * @param batchBetId
 * The ID generated in the betting system, which can be used in other (older) APIs. It will only
 * exist once the job is completed with status `DONE`
 */
export type ReducedBatchBetJobResponse = ReducedBatchBetResponse;

export type Customizations = Array<string>;

export type ReductionMetadataApiResponse = {
    reductionMetadata: ReductionMetadata;
    coupons: [];
};
