import {isString, delay} from "lodash";
import dayjs from "dayjs";
import * as Analytics from "@atg-shared/analytics";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {MessageMapping} from "@atg-shared/response-mapping/apiMessages";
import type {HarryFlavor} from "@atg-horse-shared/bet-types";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import {GameTypes} from "@atg-horse-shared/game-types";
import type {
    TrioCoupon,
    Coupon,
    ReducedCoupon,
    CouponType,
    HarryCouponTypes,
} from "@atg-horse-shared/coupon-types";
import {CouponTypes} from "@atg-horse-shared/coupon-types";
import type {ReductionTerms} from "@atg-horse-shared/reduced-bet-types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import * as GameSelectors from "atg-horse-game/domain/gameSelectors";
import * as CouponApi from "./couponApi";
import type {Bracket} from "./harry/harryBoyFeeReducer";
import * as CouponLocalApi from "./couponLocalApi";
import * as CouponUtils from "./coupon";
import * as CouponSelectors from "./couponSelectors";
import type {CouponValidation} from "./couponValidation";
import {
    getCostForSystem,
    getDefaultFixedSystem,
    getInitialCustomSystem,
    restoreSystem,
    SAVED_CUSTOM_SYSTEM,
    SAVED_FIXED_SYSTEM,
    saveSystem,
    resetSystem,
} from "./top7SystemUtils";

export const ADD_COUPON = "coupon/addCoupon" as const;
export const REQUEST_COUPON = "coupon/requestCoupon" as const;
export const RECEIVE_COUPON = "coupon/receiveCoupon" as const;
export const REMOVE_COUPON = "coupon/removeCoupon" as const;
export const REQUEST_SHAREABLE_COUPON = "coupon/requestShareableCoupon" as const;
export const RECEIVE_SHAREABLE_COUPON = "coupon/receiveShareableCoupon" as const;
export const COUPON_SHARED = "coupon/couponShared" as const;
export const TOGGLE_START = "coupon/toggleStart" as const;
export const TOP7_REORDER_STARTS = "coupon/top7ReorderStarts" as const;
export const TOGGLE_ALL_STARTS = "coupon/toggleAllStarts" as const;
export const TOGGLE_MULTIPLE_STARTS = "coupon/toggleMultipleStarts" as const;
export const TOGGLE_RESERVE = "coupon/toggleReserve" as const;
export const SELECT_RESERVE_MODE = "coupon/selectReserveMode" as const;
export const SELECT_BET_METHOD = "coupon/selectBetMethod" as const;
export const UPDATE_TOP7_COUPON = "coupon/updateTop7Coupon" as const;
export const CHANGE_STAKE = "coupon/changeStake" as const;
export const TOGGLE_BANKER = "coupon/toggleBanker" as const;
export const CHANGE_HARRY_BET_LIMIT = "coupon/changeHarryBetLimit" as const;
export const SELECT_HARRY_OPEN = "coupon/selectHarryOpen" as const;
export const CHANGE_FLEX_BET_COST = "coupon/changeFlexBetCost" as const;
export const SELECT_RACE_BET_TYPE = "coupon/selectRaceBetType" as const;
export const SELECT_RAKET_SYSTEM = "coupon/selectRaketSystem" as const;
export const CLEAR_COUPON = "coupon/clearCoupon" as const;
export const NEW_COUPON = "coupon/newCoupon" as const;
export const COPY_COUPON = "coupon/copyCoupon" as const;
export const SHOW_BASE_HORSES = "coupon/showBaseHorses" as const;
export const COUPON_SYNCED = "coupon/couponSynced" as const;
export const COUPON_MODIFIED_TIMESTAMP_UPDATED = "coupon/modifiedUpdated" as const;
export const START_SYNCING_COUPON = "coupon/startSyncingCoupon" as const;
export const COUPON_SYNC_DELAYED = "coupon/couponSyncDelayed" as const;
export const START_SYNCING_LOCAL_COUPONS = "coupon/startSyncingLocalCoupons" as const;
export const ALL_LOCAL_COUPONS_SYNCED = "coupon/allLocalCouponsSynced" as const;
export const SHOW_SUBMIT_ERRORS = "coupon/showSubmitErrors" as const;
export const SET_BOOST_SELECTED = "coupon/setBoostSelected" as const;
export const SET_ONLY_VX = "coupon/setOnlyVX" as const;
export const SET_SYSTEMS = "coupon/setSystems" as const;
export const RESTORE_COUPON = "coupon/restoreCoupon" as const;
export const RESTORE_VR_COUPON = "coupon/restoreVrCoupon" as const;
export const SET_HARRY_SUBSCRIPTION_DELIVERY_OPTION =
    "coupon/setHarrySubscriptionDeliveryOption" as const;
export const SET_HARRY_SUBSCRIPTION_AMOUNT = "coupon/setHarrySubscriptionAmount" as const;
export const SET_HARRY_BAG = "coupon/setHarryBag" as const;
export const SET_HARRY_SUBSCRIPTION_SELECTED =
    "coupon/setHarrySubscriptionSelected" as const;
export const SET_COUPON_ID = "coupon/setCouponId" as const;
export const SET_COUPON_RACES = "coupon/setCouponRace" as const;
export const UPDATE_COUPON_VALIDATION = "coupon/updateCouponValidation" as const;
export const COUPON_CHANGE = "coupon/couponChange" as const;
export const FETCH_COUPON = "coupon/fetchCoupon" as const;
export const CANCEL_COUPON_SYNC = "coupon/cancelCouponSync" as const;
export const SYNC_ALL_PENDING_COUPONS = "coupon/syncAllPendingCoupons" as const;
export const REQUEST_COUPON_BY_ID = "coupon/requestCouponById" as const;
export const RECEIVE_COUPON_BY_ID = "coupon/receiveCouponById" as const;
export const TRIGGER_COUPON_SYNC = "TRIGGER_COUPON_SYNC" as const;
export const SYNCED_COUPON_MISSING = "coupon/syncedCouponMissing" as const;
export const CHANGE_HARRY_FLAVOUR = "coupon/CHANGE_HARRY_FLAVOUR" as const;

type UpdateCouponValidationAction = {
    type: typeof UPDATE_COUPON_VALIDATION;
    payload: {
        cid: string;
        couponValidation: CouponValidation;
    };
};

export type SetCouponIdAction = {
    type: typeof SET_COUPON_ID;
    payload: {
        cid: string;
        couponId: string;
    };
};

export type SetBoostSelectedAction = {
    type: typeof SET_BOOST_SELECTED;
    payload: {
        cid: string;
        boostSelected: boolean;
    };
};

export type SetSystemsAction = {
    type: typeof SET_SYSTEMS;
    payload: {
        cid: string;
        couponAttributes: {
            systems: number;
        };
    };
};

export type SetOnlyVxAction = {
    type: typeof SET_ONLY_VX;
    payload: {
        cid: string;
        couponAttributes: {
            betMethod?: string | null | undefined;
        };
    };
};

export type ShowSubmitErrorsAction = {
    type: typeof SHOW_SUBMIT_ERRORS;
    payload: {
        cid: string;
        showSubmitErrors?: boolean;
        flashSubmitErrors?: boolean;
    };
};

export type RemoveCouponAction = {
    type: typeof REMOVE_COUPON;
    payload: {
        cid: string;
        id: string;
        coupon: Coupon;
    };
};

export type ShowBaseHorsesAction = {
    type: typeof SHOW_BASE_HORSES;
    payload: {
        cid: string;
        showBaseHorses: boolean;
    };
};

export type SelectRaketSystemAction = {
    type: typeof SELECT_RAKET_SYSTEM;
    payload: {
        cid: string;
        couponAttributes: {
            system: {
                [key: string]: any;
            };
        };
    };
};

export type SelectRaceBetTypeAction = {
    type: typeof SELECT_RACE_BET_TYPE;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        raceAttributes: {
            betType: string;
        };
    };
};

export type ChangeFlexBetCostAction = {
    type: typeof CHANGE_FLEX_BET_COST;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: {
            flexBetCost: number;
        };
    };
};

export type SelectHarryOpenAction = {
    type: typeof SELECT_HARRY_OPEN;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        prefix: string | null | undefined;
        harryOpen: boolean;
    };
};

export type ChangeHarryBetLimitAction = {
    type: typeof CHANGE_HARRY_BET_LIMIT;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: {
            harryBetLimit: number;
        };
    };
};

export type CheckBankerAction = {
    type: typeof TOGGLE_BANKER;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: {
            banker: boolean;
        };
    };
};

export type ToggleBankerAction = {
    type: typeof TOGGLE_BANKER;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: {
            banker: boolean;
        };
    };
};

export type UpdateTop7CouponAttributesAction = {
    type: typeof UPDATE_TOP7_COUPON;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: Record<string, any>;
        feeBrackets: Array<Bracket> | null | undefined;
    };
};

export type ChangeStakeAction = {
    type: typeof CHANGE_STAKE;
    payload: {
        cid: string;
        couponAttributes: {
            stake: number;
        };
    };
};

export type SelectBetMethodAction = {
    type: typeof SELECT_BET_METHOD;
    payload: {
        cid: string;
        coupon: Coupon;
        couponAttributes: {
            betMethod: string | null;
            harryBetLimit?: number;
        };
        stake?: number;
    };
};

export type ToggleAllStartsAction = {
    type: typeof TOGGLE_ALL_STARTS;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        starts: {
            [key: string]: any;
        };
        prefix?: string;
    };
};

export type ToggleMultipleStartsAction = {
    type: typeof TOGGLE_MULTIPLE_STARTS;
    payload: {
        cid: string;
        bets: Array<string>;
        raceNumber: number;
    };
};

export type SelectReserveModeAction = {
    type: typeof SELECT_RESERVE_MODE;
    payload: {
        cid: string;
        reserveMode: boolean;
    };
};

export type Top7ReorderStartsAction = {
    type: typeof TOP7_REORDER_STARTS;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        startIds: {
            [key: string]: any;
        };
    };
};

export type ToggleReserveAction = {
    type: typeof TOGGLE_RESERVE;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        startId: string;
    };
};

type ToggleStartAction = {
    type: typeof TOGGLE_START;
    payload: {
        cid: string;
        couponRace: {
            [key: string]: any;
        };
        startId: string;
        prefix: string;
        position?: number;
    };
};

export type CouponSharedAction = {
    type: typeof COUPON_SHARED;
    payload: {
        cid: string;
        coupon: Coupon;
    };
};

export type ReceiveShareableCouponErrorAction = {
    type: typeof RECEIVE_SHAREABLE_COUPON;
    error: boolean;
    payload: {
        cid: string;
        status: any;
    };
};

export type ReceiveShareableCouponAction = {
    type: typeof RECEIVE_SHAREABLE_COUPON;
    payload: {
        cid: string;
        coupon: Coupon;
    };
};

export type RequestShareableCouponAction = {
    type: typeof REQUEST_SHAREABLE_COUPON;
    payload: {
        cid: string;
        coupon: Coupon;
    };
};

export const CopyCouponTargets = {
    COPY_NORMAL_COUPON: "COPY_NORMAL_COUPON",
    COPY_REDUCED_COUPON: "COPY_REDUCED_COUPON",
    COPY_COUPON_TO_REDUCED: "COPY_COUPON_TO_REDUCED",
} as const;

export type CopyCouponTargetType = keyof typeof CopyCouponTargets;

type CopyCouponActionPayload = {
    cid: string;
    coupon: Coupon;
    target: "COPY_NORMAL_COUPON";
};
type CopyCouponReducedActionPayload = {
    cid: string;
    coupon: ReducedCoupon;
    reductionTerms: ReductionTerms;
    target: "COPY_REDUCED_COUPON";
};

type CopyCouponToReducedActionPayload = {
    cid: string;
    coupon: ReducedCoupon;
    reductionTerms: ReductionTerms;
    game: Game;
    target: "COPY_COUPON_TO_REDUCED";
};

export type CopyCouponAction = {
    type: typeof COPY_COUPON;
    payload:
        | CopyCouponActionPayload
        | CopyCouponReducedActionPayload
        | CopyCouponToReducedActionPayload;
};
export type SetCouponRacesAction = {
    type: typeof SET_COUPON_RACES;
    payload: {
        cid: string;
        coupon: Coupon;
        races: Array<{
            [key: string]: any;
        }>;
        fromCoupon?: Coupon;
    };
};

export type NewCouponAction = {
    type: typeof NEW_COUPON;
    payload: {
        cid: string;
        gameId: string | null | undefined;
        game: {
            [key: string]: any;
        }; // should be Game
        type: CouponTypes;
        localOnly: boolean;
    };
};

export type ClearCouponAction = {
    type: typeof CLEAR_COUPON;
    payload: {
        cid: string;
        coupon: Coupon;
        game: {
            [key: string]: any;
        }; // should be Game
    };
};

export type AllLocalCouponsSyncedAction = {
    type: typeof ALL_LOCAL_COUPONS_SYNCED;
    payload: {
        [key: string]: any;
    };
};

export type StartSyncingLocalCouponsAction = {
    type: typeof START_SYNCING_LOCAL_COUPONS;
    payload: {
        numCoupons: number;
    };
};

export type SyncCouponErrorAction = {
    type: typeof COUPON_SYNCED;
    error: boolean;
    payload: {
        cid: string;
        status: any;
    };
};

type CouponModifiedTimestampUpdated = {
    type: typeof COUPON_MODIFIED_TIMESTAMP_UPDATED;
    payload: {
        cid: string;
        modified: string;
    };
};

export type CouponSyncedAction = {
    type: typeof COUPON_SYNCED;
    payload: {
        cid: string;
        coupon: Coupon;
        reductionTerms: ReductionTerms | null | undefined;
    };
};

type StartSyncingCouponAction = {
    type: typeof START_SYNCING_COUPON;
    payload: {
        cid: string;
    };
};

type CouponSyncDelayedAction = {
    type: typeof COUPON_SYNC_DELAYED;
    payload: {
        cid: string;
    };
};

type SyncedCouponMissingAction = {
    type: typeof SYNCED_COUPON_MISSING;
    payload: {
        cid: string;
    };
};

type AddCouponAction = {
    type: typeof ADD_COUPON;
    payload: {
        cid: string;
        coupon: Coupon;
    };
};

export type ReceiveCouponErrorAction = {
    type: typeof RECEIVE_COUPON;
    error: boolean;
    payload: {
        id: string | null | undefined;
        cid: string;
        status: any;
        types: Array<CouponType>;
    };
};

type ReceiveStartedCouponErrorAction = {
    type: typeof RECEIVE_COUPON;
    error: boolean;
    payload: {
        id: string;
        cid: string;
        status: any;
        types: Array<CouponType>;
    };
};

export type ReceiveCouponAction = {
    type: typeof RECEIVE_COUPON;
    payload: {
        id: string;
        cid: string;
        type?: CouponTypes | HarryCouponTypes;
        coupon: Coupon | ReducedCoupon;
    };
};

export type RequestCouponAction = {
    type: typeof REQUEST_COUPON;
    payload: {
        id: string | null | undefined;
        cid: string;
        game: {
            [key: string]: any;
        }; // should be Game
        localOnly: boolean;
        gameId: string;
        type: CouponType;
    };
};

export type TriggerCouponSyncAction = {
    type: typeof TRIGGER_COUPON_SYNC;
    payload: {
        cid: string;
        keepReductionMetadata?: boolean;
    };
};

export type SetHarrySubscriptionSelectedAction = {
    type: typeof SET_HARRY_SUBSCRIPTION_SELECTED;
    payload: {
        subscriptionSelected: boolean;
        subscriptionCost: number;
        cid: string;
    };
};

export type SetHarryBagAction = {
    type: typeof SET_HARRY_BAG;
    payload: {
        bag: any;
        cid: string;
    };
};

export type SetHarrySubscriptionAmount = {
    type: typeof SET_HARRY_SUBSCRIPTION_AMOUNT;
    payload: {cid: string; amount: number};
};

export type SetHarrySubscriptionDeliveryOption = {
    type: typeof SET_HARRY_SUBSCRIPTION_DELIVERY_OPTION;
    payload: {cid: string; deliveryOption: string};
};

export type CouponChangedCommonAction =
    | ClearCouponAction
    | ToggleStartAction
    | Top7ReorderStartsAction
    | ToggleReserveAction
    | ToggleAllStartsAction
    | SelectRaceBetTypeAction
    | SetBoostSelectedAction
    | SetSystemsAction
    | SetOnlyVxAction
    | ChangeStakeAction
    | ChangeFlexBetCostAction
    | ChangeHarryBetLimitAction
    | SelectBetMethodAction
    | SelectRaketSystemAction
    | ToggleBankerAction
    | UpdateTop7CouponAttributesAction
    | SelectHarryOpenAction
    | SetHarrySubscriptionSelectedAction
    | SetHarryBagAction
    | SetHarrySubscriptionAmount
    | SetHarrySubscriptionDeliveryOption
    | SetCouponRacesAction
    | TriggerCouponSyncAction;

export type CouponChangedAction = {
    type: typeof COUPON_CHANGE;
    payload: {
        cid: string;
        coupon: Coupon;
    };
};

export type FetchCouponAction = {
    type: typeof FETCH_COUPON;
    payload: {
        cid: string;
        game: {
            [key: string]: any;
        }; // should be Game
        shouldStartPurchaseFlow: boolean;
        couponId: string | null | undefined;
        forceFetch: boolean;
        localOnly: boolean;
        pdfView: boolean;
        types: Array<CouponType>;
        hasCouponIdQuery: boolean;
    };
};

type CancelCouponSyncAction = {
    type: typeof CANCEL_COUPON_SYNC;
    payload: {
        couponId: string;
    };
};

type ChangeHarryFlavourAction = {
    type: typeof CHANGE_HARRY_FLAVOUR;
    payload: {
        cid: string;
        harryFlavour?: HarryFlavor;
    };
};

// NOTE: only used in tests to set up the state (for now)
export const updateCouponValidation = (
    cid: string,
    couponValidation: CouponValidation,
): UpdateCouponValidationAction => ({
    type: UPDATE_COUPON_VALIDATION,
    payload: {cid, couponValidation},
});

/**
 * @todo consider using `atg-domain/apiMessages.js` instead
 */
function getMessageFromResponse(response: any) {
    if (isString(response)) {
        return response;
    }
    if (response.data && response.data.errorMessage) {
        return response.data.errorMessage;
    }
    if (response.responseJSON && response.responseJSON.errorMessage) {
        return response.responseJSON.errorMessage;
    }
    return null;
}

/**
 * @todo consider using `atg-domain/apiMessages.js` instead
 */
const DEFAULT_MESSAGES: MessageMapping = {
    default: "Ett oförutsett tekniskt fel har inträffat",
    "0": "Anslutningen till servern bröts. Kontrollera nätverket.",
    "400": "Ett oförutsett tekniskt fel har inträffat",
    "401": "Inloggning krävs för att utföra operationen.",
    "404": "Resursen du söker efter kunde ej hittas.",
    "500": "Ett oförutsett tekniskt fel har inträffat",
    "503": "Tjänsten är inte tillgänglig just nu, försök igen senare.",
};

const RECEIVE_COUPON_MESSAGES: MessageMapping = {
    ...DEFAULT_MESSAGES,
    "404": "Kunde inte finna kupongen",
};

const STARTED_COUPON_MESSAGES: MessageMapping = {
    ...DEFAULT_MESSAGES,
    "404": "Det finns inga påbörjade kuponger för det här spelet",
};

/**
 * @todo consider using `atg-domain/apiMessages.js` instead
 */
const getResponseStatus = (
    response: {
        [key: string]: any;
    },
    messages: {
        [key: string]: any;
    } = DEFAULT_MESSAGES,
): {
    [key: string]: any;
} => {
    if (!response) return {};

    const httpStatus =
        response.status === undefined
            ? response.meta && response.meta.code
            : response.status;

    const message =
        messages[httpStatus] ||
        getMessageFromResponse(response) ||
        DEFAULT_MESSAGES[httpStatus] ||
        DEFAULT_MESSAGES["500"];

    return {
        code: "failed",
        isLoading: false,
        error: {
            httpCode: httpStatus,
            message,
        },
    };
};

const getStatusFromResponse = (
    response: {
        [key: string]: any;
    },
    messages: any = DEFAULT_MESSAGES,
): {
    [key: string]: any;
} => {
    const status = getResponseStatus(response, messages);
    Analytics.deprecated_logEvent({
        event: "couponResponseStatus",
        userError: `${status.error.message}, httpCode:${status.error.httpCode}`,
    });
    return status;
};

export const setCouponId = (cid: string, couponId: string): SetCouponIdAction => ({
    type: SET_COUPON_ID,
    payload: {
        cid,
        couponId,
    },
});

export const setBoostSelected = (
    cid: string,
    boostSelected: boolean,
): SetBoostSelectedAction => ({
    type: SET_BOOST_SELECTED,
    payload: {
        cid,
        boostSelected,
    },
});

export const setSystems = (cid: string, systems: number): SetSystemsAction => ({
    type: SET_SYSTEMS,
    payload: {
        cid,
        couponAttributes: {
            systems,
        },
    },
});

export const setOnlyVx = (cid: string, onlyVx: boolean): SetOnlyVxAction => ({
    type: SET_ONLY_VX,
    payload: {
        cid,
        couponAttributes: {
            betMethod: onlyVx ? "onlyVx" : null,
        },
    },
});

export const showSubmitErrors = (
    cid: string,
    shouldShowSubmitErrors?: boolean,
    shouldFlashSubmitErrors?: boolean,
): ShowSubmitErrorsAction => ({
    type: SHOW_SUBMIT_ERRORS,
    payload: {
        cid,
        showSubmitErrors: shouldShowSubmitErrors,
        flashSubmitErrors: shouldFlashSubmitErrors,
    },
});

export const removeCoupon = (coupon: Coupon): RemoveCouponAction => ({
    type: REMOVE_COUPON,
    payload: {
        cid: coupon.cid,
        id: coupon.id,
        coupon,
    },
});

export const showBaseHorses = (
    coupon: Coupon,
    _showBaseHorses: boolean,
): ShowBaseHorsesAction => ({
    type: SHOW_BASE_HORSES,
    payload: {
        cid: coupon.cid,
        showBaseHorses: _showBaseHorses,
    },
});

export const showBaseHorsesByCid = (
    cid: string,
    _showBaseHorses: boolean,
): ShowBaseHorsesAction => ({
    type: SHOW_BASE_HORSES,
    payload: {
        cid,
        showBaseHorses: _showBaseHorses,
    },
});

export const selectRaketSystem = (
    coupon: Coupon,
    system: {
        [key: string]: any;
    },
): SelectRaketSystemAction => ({
    type: SELECT_RAKET_SYSTEM,
    payload: {
        cid: coupon.cid,
        couponAttributes: {
            system,
        },
    },
});

export const selectRaceBetType = (
    couponRace: {
        [key: string]: any;
    },
    betType: string,
): SelectRaceBetTypeAction => ({
    type: SELECT_RACE_BET_TYPE,
    payload: {
        cid: couponRace.cid,
        couponRace,
        raceAttributes: {
            betType,
        },
    },
});

export const changeFlexBetCost = (
    coupon: TrioCoupon,
    flexBetCost: number,
): ChangeFlexBetCostAction => ({
    type: CHANGE_FLEX_BET_COST,
    payload: {
        cid: coupon.cid,
        coupon,
        couponAttributes: {
            flexBetCost,
        },
    },
});

export const selectHarryOpen = (
    couponRace: {
        [key: string]: any;
    },
    prefix: string | null | undefined,
    harryOpen: boolean,
): SelectHarryOpenAction => ({
    type: SELECT_HARRY_OPEN,
    payload: {
        cid: couponRace.cid,
        couponRace,
        prefix,
        harryOpen,
    },
});

export const changeHarryBetLimit = (
    coupon: Coupon,
    harryBetLimit: number,
): ChangeHarryBetLimitAction => ({
    type: CHANGE_HARRY_BET_LIMIT,
    payload: {
        cid: coupon.cid,
        coupon,
        couponAttributes: {
            harryBetLimit,
        },
    },
});

export const checkBanker = (coupon: Coupon): CheckBankerAction => ({
    type: TOGGLE_BANKER,
    payload: {
        cid: coupon.cid,
        coupon,
        couponAttributes: {
            banker: true,
        },
    },
});

export const toggleBanker = (coupon: Coupon & {banker: boolean}): ToggleBankerAction => ({
    type: TOGGLE_BANKER,
    payload: {
        cid: coupon.cid,
        coupon,
        couponAttributes: {
            banker: !coupon.banker,
        },
    },
});

export const updateTop7CouponAttributes = (
    coupon: Coupon,
    propertiesToChange: Record<string, any>,
    isFixedTemplates: boolean,
    feeBrackets?: Array<Bracket> | null,
): UpdateTop7CouponAttributesAction => {
    if (propertiesToChange.systemId) {
        const systemTypeKey = isFixedTemplates ? SAVED_FIXED_SYSTEM : SAVED_CUSTOM_SYSTEM;
        saveSystem(systemTypeKey, propertiesToChange.systemId);
    }

    return {
        type: UPDATE_TOP7_COUPON,
        payload: {
            cid: coupon.cid,
            coupon,
            couponAttributes: propertiesToChange,
            feeBrackets,
        },
    };
};

export const changeStake = (cid: string, stake: number): ChangeStakeAction => ({
    type: CHANGE_STAKE,
    payload: {
        cid,
        couponAttributes: {
            stake,
        },
    },
});

/*
 * allows to select the bet method
 * @param coupon - coupon that is being modified
 * @param betMethod: "harry" | "flex" | null (for "usual" bets)
 * @param harryBetLimit: only needed if the bet method is harry. May be missing if needs calculation.
 * @param stake: stake for the coupon. Used for single races, like LD or top7.
 */
export const selectBetMethod = (
    coupon: Coupon,
    betMethod: string | null,
    harryBetLimit?: number,
    stake?: number,
): SelectBetMethodAction => ({
    type: SELECT_BET_METHOD,
    payload: {
        cid: coupon.cid,
        coupon,
        couponAttributes: {
            betMethod,
            harryBetLimit,
        },
        stake,
    },
});

export const toggleAllStarts = (
    couponRace: {
        [key: string]: any;
    },
    starts: {
        [key: string]: any;
    },
    prefix?: string,
): ToggleAllStartsAction => ({
    type: TOGGLE_ALL_STARTS,
    payload: {
        cid: couponRace.cid,
        couponRace,
        starts,
        prefix,
    },
});

export const toggleMultipleStarts = (
    cid: string,
    bets: Array<string>,
    raceNumber: number,
): ToggleMultipleStartsAction => ({
    type: TOGGLE_MULTIPLE_STARTS,
    payload: {
        cid,
        bets,
        raceNumber,
    },
});

export const selectReserveMode = (
    cid: string,
    reserveMode: boolean,
): SelectReserveModeAction => ({
    type: SELECT_RESERVE_MODE,
    payload: {
        cid,
        reserveMode,
    },
});

export const top7ReorderStarts = (
    couponRace: {
        [key: string]: any;
    },
    startIds: {
        [key: string]: any;
    },
): Top7ReorderStartsAction => ({
    type: TOP7_REORDER_STARTS,
    payload: {
        cid: couponRace.cid,
        couponRace,
        startIds,
    },
});

export const toggleReserve = (
    couponRace: {
        [key: string]: any;
    },
    startId: string,
): ToggleReserveAction => ({
    type: TOGGLE_RESERVE,
    payload: {
        cid: couponRace.cid,
        couponRace,
        startId,
    },
});

const privateToggleStart = (
    couponRace: {
        [key: string]: any;
    },
    startId: string,
    prefix: string,
    position?: number,
): ToggleStartAction => ({
    type: TOGGLE_START,
    payload: {
        cid: couponRace.cid,
        couponRace,
        startId,
        prefix,
        position,
    },
});

/**
 * Trigger coupon sync
 * @param cid Client id of coupon
 * @param keepReductionMetadata Boolean that forces reduction metadata to be synced even when restrictions are not set.
 * For example, this is required when placing a reduced Tillsammans bet.
 * @returns {{payload: {keepReductionMetadata: boolean, cid: string}, type: "TRIGGER_COUPON_SYNC"}}
 */
export const triggerCouponSync = (
    cid: string,
    keepReductionMetadata?: boolean,
): TriggerCouponSyncAction => ({
    type: TRIGGER_COUPON_SYNC,
    payload: {
        cid,
        keepReductionMetadata,
    },
});

export const couponShared = (coupon: Coupon): CouponSharedAction => ({
    type: COUPON_SHARED,
    payload: {
        cid: coupon.cid,
        coupon,
    },
});

export const receiveShareableCouponError = (
    cid: string,
    res: any,
): ReceiveShareableCouponErrorAction => ({
    type: RECEIVE_SHAREABLE_COUPON,
    error: true,
    payload: {
        cid,
        status: getStatusFromResponse(res),
    },
});

export const receiveShareableCoupon = (
    cid: string,
    coupon: Coupon,
): ReceiveShareableCouponAction => ({
    type: RECEIVE_SHAREABLE_COUPON,
    payload: {
        cid,
        coupon,
    },
});

export const requestShareableCoupon = (coupon: Coupon): RequestShareableCouponAction => ({
    type: REQUEST_SHAREABLE_COUPON,
    payload: {
        cid: coupon.cid,
        coupon,
    },
});

export const copyCoupon = (
    coupon: Coupon,
    target: CopyCouponTargetType,
    reductionTerms?: ReductionTerms | null | undefined,
    game?: Game,
): CopyCouponAction => ({
    type: COPY_COUPON,
    payload: {
        cid: coupon.cid,
        coupon,
        // @ts-expect-error "Type 'undefined' is not assignable to type reductionTerms"
        reductionTerms,
        target,
        // @ts-expect-error "Type 'undefined' is not assignable to type game"
        game,
    },
});

export const setCouponRaces = (
    coupon: Coupon,
    races: Array<{
        [key: string]: any;
    }>,
    fromCoupon?: Coupon,
): SetCouponRacesAction => ({
    type: SET_COUPON_RACES,
    payload: {
        cid: coupon.cid,
        coupon,
        races,
        fromCoupon,
    },
});

export const newCoupon = ({
    game,
    cid = CouponUtils.nextCid(),
    // default to new cid
    type = CouponTypes.PRIVATE,
    // default to private coupon
    localOnly = false,
}: {
    game: {
        [key: string]: any;
    }; // should be Game
    cid?: string;
    type?: CouponTypes;
    localOnly?: boolean;
}): NewCouponAction => {
    if (game?.type === "top7") {
        resetSystem();
    }
    return {
        type: NEW_COUPON,
        payload: {
            cid,
            gameId: game?.id,
            game,
            type,
            localOnly,
        },
    };
};

function actionCreatorWithGame(actionCreator: (...args: Array<any>) => any): any {
    return (coupon: Coupon) =>
        (
            dispatch: (...args: Array<any>) => any,
            getState: (...args: Array<any>) => any,
        ) => {
            const gameId = coupon.game.id;
            const game = GameSelectors.getGameById(getState(), gameId);
            dispatch(actionCreator(coupon, game));
        };
}

const privateClearCoupon = (
    coupon: {
        [key: string]: any;
    },
    game: Game,
): ClearCouponAction => ({
    type: CLEAR_COUPON,
    payload: {
        cid: coupon.cid,
        // @ts-expect-error [key: string]: any; is not assignable to type coupon
        coupon,
        game,
    },
});

export const clearCoupon = actionCreatorWithGame(privateClearCoupon);

export const allLocalCouponsSynced = (): AllLocalCouponsSyncedAction => ({
    type: ALL_LOCAL_COUPONS_SYNCED,
    payload: {},
});

export const startSyncingLocalCoupons = (
    numCoupons: number,
): StartSyncingLocalCouponsAction => ({
    type: START_SYNCING_LOCAL_COUPONS,
    payload: {
        numCoupons,
    },
});

export const syncCouponError = (
    cid: string,
    res: {
        [key: string]: any;
    },
): SyncCouponErrorAction => ({
    type: COUPON_SYNCED,
    error: true,
    payload: {
        cid,
        status: getStatusFromResponse(res),
    },
});

export const couponModifiedTimestampUpdated = (
    cid: string,
    modified: string,
): CouponModifiedTimestampUpdated => ({
    type: COUPON_MODIFIED_TIMESTAMP_UPDATED,
    payload: {
        cid,
        modified,
    },
});

/**
 * Dispatched when coupon is synced
 * @param {string} cid - cid of a coupon
 * @param {Coupon} coupon - coupon that was synced. NOTE: it is not a part of a server response.
 * @param {ReductionTerms} reductionTerms - reduction terms in case the coupon was reduced
 */
export const couponSynced = (
    cid: string,
    coupon: Coupon,
    reductionTerms: ReductionTerms | null | undefined,
): CouponSyncedAction => ({
    type: COUPON_SYNCED,
    payload: {
        cid,
        coupon,
        reductionTerms,
    },
});

/**
 * Dispatched when a coupon is starting to sync, ie actual network request is made
 * @param {string} cid
 */
export const startSyncingCoupon = (cid: string): StartSyncingCouponAction => ({
    type: START_SYNCING_COUPON,
    payload: {
        cid,
    },
});

/**
 * Dispatched when delaying a coupon sync by 3 seconds after a user selected horses, aka debounce.
 * @param {string} cid
 */
export const couponSyncDelayed = (cid: string): CouponSyncDelayedAction => ({
    type: COUPON_SYNC_DELAYED,
    payload: {
        cid,
    },
});

/**
 * Dispatched when the coupon that was synced was already removed from redux by the time we got a sync response
 * @param {string} cid
 */
export const syncedCouponMissing = (cid: string): SyncedCouponMissingAction => ({
    type: SYNCED_COUPON_MISSING,
    payload: {
        cid,
    },
});

export const addCoupon = (cid: string, coupon: Coupon): AddCouponAction => ({
    type: ADD_COUPON,
    payload: {cid, coupon},
});

export const receiveCouponError = (
    cid: string,
    couponId: string | null | undefined,
    res: any,
    types: Array<CouponType>,
): ReceiveCouponErrorAction => ({
    type: RECEIVE_COUPON,
    error: true,
    payload: {
        id: couponId,
        cid,
        status: getResponseStatus(res, RECEIVE_COUPON_MESSAGES),
        types,
    },
});

export const receiveStartedCouponError = (
    cid: string,
    couponId: string,
    res: any,
    types: Array<CouponType>,
): ReceiveStartedCouponErrorAction => ({
    type: RECEIVE_COUPON,
    error: true,
    payload: {
        id: couponId,
        cid,
        status: getResponseStatus(res, STARTED_COUPON_MESSAGES),
        types,
    },
});

export const receiveCoupon = (cid: string, coupon: Coupon): ReceiveCouponAction => ({
    type: RECEIVE_COUPON,
    payload: {
        id: coupon.id,
        cid,
        coupon: CouponUtils.addCidToCoupon(coupon, cid),
        type: coupon.type,
    },
});

export const requestCoupon = (
    cid: string,
    game: {
        [key: string]: any;
    },
    couponId: string | null | undefined,
    localOnly: boolean,
    type: CouponType,
): RequestCouponAction => ({
    type: REQUEST_COUPON,
    payload: {
        id: couponId,
        cid,
        game,
        localOnly,
        gameId: game.id,
        type,
    },
});

export const toggleStart =
    (
        couponRace: any,
        startId: string,
        prefix: any,
        raketBetType?: string,
        position?: number,
    ) =>
    (dispatch: (...args: Array<any>) => any, getState: (...args: Array<any>) => any) => {
        const state = getState();
        const couponSettings = state.couponSettings[couponRace.cid];
        const coupon = state.coupons[couponRace.cid];
        // TODO: Move this into reducer logic instead
        if (couponSettings.reserveMode && coupon.game.type !== GameTypes.top7) {
            dispatch(toggleReserve(couponRace, startId));
            return;
        }
        if (!raketBetType) {
            dispatch(privateToggleStart(couponRace, startId, prefix, position));
            return;
        }

        // raket
        const selected = CouponUtils.isSelected(couponRace, startId);
        if (!selected || !couponRace.betType || couponRace.betType === raketBetType) {
            dispatch(privateToggleStart(couponRace, startId, ""));
        }

        if (couponRace.betType !== raketBetType)
            dispatch(selectRaceBetType(couponRace, raketBetType));
    };

/**
 * ToggleStart action with ids instead of couponRace as arguments.
 * Avoids triggering a render with every coupon update.
 */
export const toggleStartByIds =
    (
        cid: string,
        raceId: string,
        startId: string,
        prefix: any,
        raketBetType: string,
        position?: number,
    ) =>
    (dispatch: (...args: Array<any>) => any, getState: (...args: Array<any>) => any) => {
        const state = getState();
        const coupon = CouponSelectors.getCoupon(state, cid);
        const couponRace = coupon && CouponUtils.getRaceById(coupon, raceId);
        dispatch(toggleStart(couponRace, startId, prefix, raketBetType, position));
    };

export const cleanUpOldLocalOnlyCoupon = (
    key: any,
    couponJson: {
        [key: string]: any;
    },
    liveDate: any,
) => {
    const isOldCoupon = dayjs(couponJson.date).diff(dayjs(liveDate), "days") < -1;
    if (!isOldCoupon) return;
    CouponLocalApi.removeCoupon(key);
};

export function flashSubmitErrors(cid: string) {
    return (
        dispatch: (...args: Array<any>) => any,
        getState: (...args: Array<any>) => any,
    ): any => {
        const isShowingErrors = CouponSelectors.shouldShowSubmitErrors(getState(), cid);

        if (isShowingErrors) {
            dispatch(showSubmitErrors(cid, undefined, true));
            delay(() => dispatch(showSubmitErrors(cid, undefined, false)), 200);
            return;
        }

        dispatch(showSubmitErrors(cid, true));
        delay(() => {
            dispatch(showSubmitErrors(cid, false));
        }, 5000);
    };
}

export const changeTop7SystemType = (
    coupon: Coupon,
    isFixedTemplates: boolean,
    isMobile: boolean,
) => {
    const betMethod = coupon.betMethod === "harry" ? null : coupon.betMethod;
    const savedSystem = isFixedTemplates
        ? restoreSystem(SAVED_FIXED_SYSTEM)
        : restoreSystem(SAVED_CUSTOM_SYSTEM);
    const defaultSystem =
        isFixedTemplates || isMobile
            ? getDefaultFixedSystem(coupon)
            : getInitialCustomSystem(coupon);

    const systemCost =
        savedSystem !== null
            ? getCostForSystem(savedSystem)
            : getCostForSystem(defaultSystem);

    const isSavedSystemAndOnboardingCompleted = savedSystem !== null;

    const newProperties = {
        systemId: isSavedSystemAndOnboardingCompleted ? savedSystem : defaultSystem,
        stake: systemCost,
        betMethod,
        userDefinedSystem: !isFixedTemplates,
    };

    return updateTop7CouponAttributes(coupon, newProperties, isFixedTemplates, null);
};

type FetchCouponProps = {
    cid?: string | null | undefined;
    couponId?: string | null | undefined;
    forceFetch?: boolean;
    game: Game;
    localOnly?: boolean;
    shouldStartPurchaseFlow?: boolean;
    pdfView?: boolean;
    types?: Array<CouponType>;
    hasCouponIdQuery?: boolean;
};

/**
 * Fetch a coupon, either from localStorage or from the coupon service.
 *
 * @param {Object} options Mandatory and optional arguments to the function
 * @param options.cid optional cid. If `cid` is `undefined` a new coupon will be created
 * @param options.game game corresponding to the coupon
 * @param options.couponId optional. if provided, will get coupon by id
 * @param options.forceFetch do not check if it is already fetched or localOnly. Logic has to take care of that.
 * @param options.localOnly only fetch from local storage (eg for live view)
 * @param options.shouldStartPurchaseFlow start purchase flow directly after receiving the coupon
 * @param options.pdfView should open pdf view
 * @param options.types optional array of typs to fetch from /latest. will default to private and private_team
 * @param options.hasCouponIdQuery there is a coupon id in url. custom logic for that (e.g. syncing to the server).
 */
export const fetchCoupon = ({
    cid,
    game,
    couponId,
    forceFetch = false,
    localOnly = false,
    shouldStartPurchaseFlow = false,
    pdfView = false,
    types = [CouponTypes.PRIVATE, CouponTypes.PRIVATE_TEAM],
    hasCouponIdQuery = false,
}: FetchCouponProps): FetchCouponAction => ({
    type: FETCH_COUPON,
    payload: {
        cid: cid || CouponUtils.nextCid(), // if cid is not passed in, create a new one
        couponId,
        forceFetch,
        game,
        localOnly,
        shouldStartPurchaseFlow,
        pdfView,
        types,
        hasCouponIdQuery,
    },
});

export const fetchCouponById = ({couponId}: {couponId: string}): any => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_COUPON_BY_ID,
        receiveAction: RECEIVE_COUPON_BY_ID,
        callApi: call(CouponApi.getCoupon, couponId),
        context: {couponId},
    },
});

export const couponChanged = (cid: string, coupon: Coupon): CouponChangedAction => ({
    type: COUPON_CHANGE,
    payload: {
        cid,
        coupon,
    },
});

export const cancelCouponSync = (couponId: string): CancelCouponSyncAction => ({
    type: CANCEL_COUPON_SYNC,
    payload: {couponId},
});

export const couponChangeHarryFlavour = (
    cid: string,
    harryFlavour?: HarryFlavor,
): ChangeHarryFlavourAction => ({
    type: CHANGE_HARRY_FLAVOUR,
    payload: {
        cid,
        harryFlavour,
    },
});
