// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
import type {ReductionTerms} from "@atg-horse-shared/reduced-bet-types";
import type {SharedBetConditions} from "./sharedBet";

/**
 * When a user creates a new "ANDELSSPEL"
 */
export const SHARED_BET_CREATE_PRODUCT = "sharedBet/CREATE_PRODUCT" as const;

/**
 * When user is buying more shares to an "ANDELSSPEL"
 */
export const SHARED_BET_SHARE_PRODUCT = "sharedBet/SHARE_PRODUCT" as const;

/**
 * When the user is placing an "ANDELSSPEL" bet
 */
export const SHARED_BET_COUPON_PRODUCT = "sharedBet/COUPON_PRODUCT" as const;

// export type SharedBetCreateProduct = {
//     type: typeof SHARED_BET_CREATE_PRODUCT;
//     game: {
//         [key: string]: any;
//     };
//     cid: string;
//     coupon: {
//         [key: string]: any;
//     };
// };

// export type SharedBetShareProduct = {
//     type: typeof SHARED_BET_SHARE_PRODUCT;
//     coupon: {
//         [key: string]: any;
//     };
//     game: {
//         [key: string]: any;
//     };
//     conditions: {
//         [key: string]: any;
//     };
//     hideReceipt: boolean;
//     cid: string;
//     reductionTerms?: void;
// };

// export type SharedBetCouponProduct = {
//     type: typeof SHARED_BET_COUPON_PRODUCT;
//     game: {
//         [key: string]: any;
//     };
//     cid: string;
//     coupon: {
//         [key: string]: any;
//     };
//     conditions: SharedBetConditions;
// };

export type SharedBetCreateProduct = {
    type: typeof SHARED_BET_CREATE_PRODUCT;
    game: Game;
    cid: string;
    coupon: Record<string, unknown>;
    reductionTerms?: ReductionTerms | null;
};

export type SharedBetShareProduct = {
    type: typeof SHARED_BET_SHARE_PRODUCT;
    coupon: Record<string, unknown>;
    game: Game;
    conditions: Record<string, unknown>;
    hideReceipt: boolean;
    cid: string;
    reductionTerms?: void;
};

export type SharedBetCouponProduct = {
    type: typeof SHARED_BET_COUPON_PRODUCT;
    game: Game;
    cid: string;
    coupon: Record<string, unknown>;
    conditions: SharedBetConditions;
    reductionTerms?: ReductionTerms;
};

export const sharedBetCreateProduct = (
    game: Game,
    coupon: Record<string, any>,
    reductionTerms?: ReductionTerms | null,
): SharedBetCreateProduct => ({
    type: SHARED_BET_CREATE_PRODUCT,
    game,
    cid: coupon.cid,
    coupon,
    reductionTerms,
});

export const sharedBetShareProduct = (
    game: Game,
    coupon: Record<string, any>,
    conditions: SharedBetConditions,
    hideReceipt = false,
): SharedBetShareProduct => ({
    type: SHARED_BET_SHARE_PRODUCT,
    coupon,
    game,
    conditions,
    hideReceipt,
    cid: coupon.cid,
});

export const sharedBetCouponProduct = (
    game: Game,
    coupon: any,
    conditions: SharedBetConditions,
    reductionTerms?: ReductionTerms,
): SharedBetCouponProduct => {
    const confirmCoupon = CouponUtils.cloneCoupon(
        coupon.teamId ? coupon : {...coupon, teamId: conditions.teamId},
    );

    return {
        type: SHARED_BET_COUPON_PRODUCT,
        game,
        cid: confirmCoupon.cid,
        coupon: confirmCoupon,
        conditions,
        reductionTerms,
    };
};
