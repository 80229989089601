import {omit, cloneDeep, set} from "lodash";
import {type BagItemDto} from "@atg-horse-shared/harry-bags-api";
import {GameTypes, type GameType} from "@atg-horse-shared/game-types";
import type {
    HarryBagGroup,
    HarryBag,
    SimpleHarryGame,
} from "@atg-horse-shared/utils/harry";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {CouponUtils} from "@atg-horse-shared/coupon";
import type {Coupon, DeliveryOption} from "@atg-horse-shared/coupon-types";
import type {Game} from "@atg-horse-shared/racing-info-api/game/types";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
    SHOP_SHARE_COUPON_PRODUCT,
    ShopShareCouponProduct,
} from "@atg-shop-shared/purchase-redux/src/shopShareCouponProducts";
// eslint-disable-next-line @nx/enforce-module-boundaries
import type {
    SHARED_BET_COUPON_PRODUCT,
    SHARED_BET_CREATE_PRODUCT,
    SHARED_BET_SHARE_PRODUCT,
    SharedBetShareProduct,
    SharedBetCreateProduct,
    SharedBetCouponProduct,
} from "@atg-horse/shared-bet";
import type {ReductionTerms} from "@atg-horse-shared/reduced-bet-types";

export const COUPON_PRODUCT = "COUPON_PRODUCT";
export const HARRY_PRODUCT = "HARRY_PRODUCT";
export const HARRY_BAG_PRODUCT = "HARRY_BAG_PRODUCT";
export const HARRY_BAG_PRODUCT_VARENNE = "HARRY_BAG_PRODUCT_VARENNE";
export const HARRY_SUBSCRIPTION_PRODUCT = "HARRY_SUBSCRIPTION_PRODUCT";
export const TOP7_PRODUCT = "TOP7_PRODUCT";
export const FILE_BET_PRODUCT = "FILE_BET_PRODUCT";

export type ProductType =
    | typeof COUPON_PRODUCT
    | typeof HARRY_PRODUCT
    | typeof HARRY_BAG_PRODUCT
    | typeof HARRY_SUBSCRIPTION_PRODUCT
    | typeof TOP7_PRODUCT
    | typeof FILE_BET_PRODUCT
    | typeof SHARED_BET_COUPON_PRODUCT
    | typeof SHARED_BET_SHARE_PRODUCT
    | typeof SHARED_BET_CREATE_PRODUCT
    | typeof SHOP_SHARE_COUPON_PRODUCT;

export type Subscription = {
    showDeliveryOptions?: boolean;
    betType: GameType;
    amount?: number;
    deliveryOption: DeliveryOption;
    description?: string;
};

type SubscriptionType = "subscription";

export type SubscriptionCoupon = {
    cid: string;
    type: SubscriptionType;
    localOnly: boolean; // should always be true for subscription
} & Subscription;

export type CouponProduct = {
    type: typeof COUPON_PRODUCT;
    game: Game;
    cid: string;
    coupon: Coupon | null | undefined;
    reductionTerms: ReductionTerms | null | undefined;
};

export type HarryProduct = {
    type: typeof HARRY_PRODUCT;
    cid: string;
    coupon: Coupon;
    game: Game;
    gameType: GameType;
    amount: number;
    boost?: boolean;
};

export type Top7Product = {
    type: typeof TOP7_PRODUCT;
    game: Game | undefined;
    amount?: number;
    harryBetLimt?: number;
    coupon: Coupon;
    cid: string;
    reductionTerms?: void;
};

export type HarryBagCoupon = {
    cid: string;
    type: "bag";
    localOnly: boolean;
    version?: number;
} & HarryBag;

export type HarryBagProduct = {
    type: typeof HARRY_BAG_PRODUCT;
    cid: string;
    coupon: HarryBag | null | undefined;
    group: HarryBagGroup;
};

export type HarryBagProductVarenne = {
    type: typeof HARRY_BAG_PRODUCT_VARENNE;
    bag: BagItemDto;
    game: SimpleHarryGame | Game;
    campaignType: "Mustaschkampen" | "Harry-10-Kr" | "Other";
};

export type HarrySubscriptionProduct = {
    type: typeof HARRY_SUBSCRIPTION_PRODUCT;
    subscription: Subscription;
    cid: string;
    coupon: SubscriptionCoupon;
};

export type FileBetProduct = {
    type: typeof FILE_BET_PRODUCT;
    game: Game;
    bet: {
        [key: string]: any;
    };
};

export type Product =
    | CouponProduct
    | Top7Product
    | HarryProduct
    | HarryBagProduct
    | HarryBagProductVarenne
    | HarrySubscriptionProduct
    | FileBetProduct
    | SharedBetShareProduct
    | SharedBetCreateProduct
    | SharedBetCouponProduct
    | ShopShareCouponProduct;

/*
 * Create a product object with a "read only" copy of the user's coupon.
 *
 * Note: The coupon copy will be available in the `purchase` state instead of the usual `coupons` state. At the time of writing however it will sometimes exist both places [HRS1-7667].
 */
export const couponProduct = (
    game: Game,
    coupon?: Coupon | null,
    reductionTerms?: ReductionTerms,
): CouponProduct => {
    // The clone here is to make sure that no other part of the codebase can accidentally update the coupon while the user is moving through the purchase flow. Essentially we're creating a "read-only" copy. This product will be placed to the redux store and accessible via PurchaseSelectors when PurchaseActions.purchase is dispatched
    const confirmCoupon = CouponUtils.cloneCoupon(coupon, {localOnly: true});
    const clonedReductionTerms = reductionTerms ? cloneDeep(reductionTerms) : undefined;
    return {
        type: COUPON_PRODUCT,
        game,
        cid: confirmCoupon.cid,
        // TODO: consider removing these. For now leaving this here to align with other products, since on START_PURCHASE_FLOW, this coupon is inserted to the store and in later stages, the coupon from the store (mentioned above) should be used (to avoid confusion), at least with this setup. Alternative could be not to enter this "cloned" coupon into redux.
        // ref: [HRS1-7667]
        coupon: omit(confirmCoupon, "teamId") as Coupon,
        reductionTerms: clonedReductionTerms,
    };
};

/**
 * @todo improve flow types so that it's not possible to send a top7 game here, since that one should be handled separately by `top7Product`
 */
export const harryProduct = (
    game: Game,
    amount = 0,
    {boost = false}: {boost?: boolean} = {},
): HarryProduct => {
    const cid = CouponUtils.nextCid();
    const coupon = CouponUtils.createHarryCoupon({
        cid,
        game,
        localOnly: true,
        harryBetLimit: amount,
        boost,
    });
    return {
        type: HARRY_PRODUCT,
        coupon,
        cid,
        game,
        gameType: game.type,
        amount,
        boost,
    };
};

export const top7Product = (
    game?: Game,
    amount?: number,
    harryBetLimit?: number,
    systemId?: string,
): Top7Product => {
    const cid = CouponUtils.nextCid();
    const top7Game = {...game, type: GameTypes.top7};
    const coupon = CouponUtils.createHarryCoupon({
        cid,
        game: top7Game,
        localOnly: true,
        stake: amount,
        harryBetLimit,
    });
    return {
        type: TOP7_PRODUCT,
        cid,
        coupon: set(coupon, "systemId", systemId),
        game: top7Game as Game,
        amount,
    };
};

export const harryBagProduct = (group: HarryBagGroup): HarryBagProduct => {
    const cid = CouponUtils.nextCid();

    let coupon = {
        cid,
        type: "bag",
        version: 0,
        cost: 0,
        localOnly: true,
        bets: [],
    };

    // default coupon to first bag if only one is present
    if (group.bags.length === 1) {
        // @ts-expect-error TODO: adjust coupon type for bags.
        coupon = {
            ...coupon,
            ...group.bags[0],
            version: group.version,
        };
    }

    return {
        type: HARRY_BAG_PRODUCT,
        cid,
        coupon,
        group,
    };
};

export const harryBagProductVarenne = (
    bag: BagItemDto,
    game: SimpleHarryGame | Game,
    campaignType: "Mustaschkampen" | "Harry-10-Kr" | "Other",
): HarryBagProductVarenne => ({
    type: HARRY_BAG_PRODUCT_VARENNE,
    game,
    bag,
    campaignType,
});

export const harrySubscriptionProduct = (
    subscription: Subscription,
    amount?: number,
): HarrySubscriptionProduct => {
    const cid = CouponUtils.nextCid();
    const coupon: SubscriptionCoupon = {
        cid,
        localOnly: true, // should always be true for subscription
        type: "subscription",
        // @ts-expect-error spread below should override it, but now I am not sure it always does, so leaving this here.
        deliveryOption: "NONE",
        amount,
        ...subscription, // so it will add a number of props here, like showDeliveryOptions and others...
    };
    return {
        type: HARRY_SUBSCRIPTION_PRODUCT,
        subscription,
        cid,
        coupon,
    };
};

export const fileBetProduct = (
    game: Game,
    bet: {
        [key: string]: any;
    },
): FileBetProduct => ({
    type: FILE_BET_PRODUCT,
    game,
    bet,
});
