import {
    flow,
    map,
    uniq,
    compact,
    find,
    times,
    isEmpty,
    isArray,
    isFinite,
    without,
    includes,
    reject,
    filter,
    take,
    union,
    isObject,
    cloneDeep,
    flatMap,
    some,
    flatten,
    capitalize,
    reduce,
    join,
} from "lodash/fp";
import dayjs from "dayjs";
import {mergePlain} from "@atg/utils";
import {formatCurrency} from "@atg-shared/currency";
import {formatReceiptCode, toNonBreakingString} from "@atg/utils/strings";
import {ReceiptType} from "@atg-horse-shared/bet-types";
import log from "@atg-shared/log";
import {
    getHarryFlavorInfo,
    mapHarryFlavorByLegacyName,
} from "@atg-horse-shared/utils/harry";
import {parseGameId} from "@atg-horse-shared/utils/gameid";
// eslint-disable-next-line @nx/enforce-module-boundaries
import {getTeamName} from "@atg-horse/shared-bet";
import betDefs from "../redux/betDefs";

// @ts-expect-error convert does not exist on map
const mapWithIndex = map.convert({cap: false});

export function createId(bet: any, options: any = {}) {
    if (!bet) return null;

    const pattern = options.minutePrecision ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD";
    return `${dayjs(bet.timestamp).format(pattern)}_${bet.serialNumber}`;
}

// Only use this with bets loaded from /users/bets endpoint
export function isStandardBetOverview(bet: any) {
    return !(bet.trackNames === "Virtuell" || bet.type === "group");
}

export function isStandardBet(bet: any) {
    return !(!bet.game || bet.type === "group");
}

export const isCodeBet = (bet: any) => bet && bet.betCode;

export const DEFAULT_RECEIPT_STATE: any = {
    showSneak: false,
    sneakedRows: [],
    boostSneaked: false,
    selectedTab: "receipt",
    receiptType: ReceiptType.BetReceipt,
    played: false,
    hideDividend: false,
};

export function getTop7Results(race: any) {
    return times((i) => {
        const positionResults = race.positionResults[i + 1];
        const resultNumbers = map("number", positionResults);
        if (positionResults.length === 1) {
            return resultNumbers.join("");
        }

        return `(${resultNumbers.join(", ")})`;
    }, 7).join(", ");
}

export function getResultsByRace(bet: any, race: any) {
    if (race.cancelled) return "Alla";
    if (race.results && isArray(race.results)) {
        return race.results
            .map((result: any) => {
                if (isArray(result.combination)) {
                    return join("/", result.combination);
                }
                return result.number || result;
            })
            .join(", ");
    }
    // Top 7 results
    if (race.positionResults) {
        return getTop7Results(race);
    }
    if (bet.payments) {
        return flow(map("winning"), uniq)(bet.payments || bet.stake).join(", ");
    }
    return "";
}

export function getResultForPlacement(results: any, placement: any) {
    if (!results) return "";
    return results[placement.id + 1].map((winner: any) => winner.number).join(", ");
}

export function getBetMethodLabel(bet: any) {
    const betDef = betDefs[bet.game.type];

    if (bet.betMethod === "harry")
        return getHarryFlavorInfo(mapHarryFlavorByLegacyName(bet?.harryFlavour)).label;

    return betDef.allowedBetMethodsLabels[bet.betMethod];
}

export function getTracks(bet: any) {
    const tracks = bet.game.tracks || [];
    if (tracks.length) return map("name", tracks).join(", ");
    if (bet.trackNames) return bet.trackNames;

    return null;
}

export function getShare(bet: any) {
    if (bet.shareInfo) {
        return getTeamName(bet.shareInfo.initiator);
    }

    if (bet.shares > 1) return "Andel";

    return null;
}

export function isSingleRace(bet: any) {
    return isArray(bet.races) && bet.races.length === 1;
}

export function getSingleRaceLabel(bet: any) {
    if (!isSingleRace(bet)) return null;

    const {raceNumber} = bet.races[0];
    if (!raceNumber) return null;

    return `Lopp ${bet.races[0].raceNumber}`;
}

export function getGameTimeLabel(bet: any) {
    const {startTime} = bet.game;
    if (!startTime) return null;

    return `Start: ${dayjs(startTime).format("HH:mm")}`;
}

export function getFormattedGameDate(bet: any) {
    return capitalize(dayjs(bet.game.startTime).format("dddd YYYY-MM-DD"));
}

function createCodeInfo(bet: any) {
    return {
        label: "Rättningskod",
        code: bet.betCode,
    };
}

function createRegistered(bet: any) {
    if (bet.timestamp && bet.timestamp.split("T")[1] === "00:00:00.000")
        return {label: null, time: null};

    return {
        label: "Inlämnat",
        time: dayjs(bet.timestamp).format("YYYY-MM-DD[,] HH:mm"),
    };
}

export function getRegisteredInfo(bet: any) {
    return bet.betCode ? createCodeInfo(bet) : createRegistered(bet);
}

export function getReceiptCode(bet: any) {
    return formatReceiptCode(bet.checkBetCode);
}

export function getDetails(bet: any) {
    if (!bet.races) return null;

    const gameType = bet.game.type;
    const {flexCostSpec, costSpec} = betDefs[gameType];
    if (bet.betMethod === "flex") return flexCostSpec(bet);
    return costSpec(bet);
}

export function areRacesCompleted(bet: any) {
    // if new bet history is on, we check the ownerPayout status to find out if the race is finished, since not all bet types return races
    if (bet.hasNewBetHistory) return Boolean(bet.ownerPayout);

    if (!bet.races) return false;

    const noOfCompletedRaces = bet.races.filter((race: any) => race.result).length;
    return noOfCompletedRaces === bet.races.length;
}

export function isSneakCompleted(bet: any, state: any) {
    const {getSneakableRowsAmount} = betDefs[bet.game.type];
    const hasBoost = includes("boost", bet.addOns);
    const isBoostSneaked = hasBoost ? state.boostSneaked : true;
    return getSneakableRowsAmount(bet) === state.sneakedRows.length && isBoostSneaked;
}

export function hasPayout(bet: any) {
    const {payout} = bet;
    return payout && isFinite(payout.amount);
}

export function showDividend(bet: any, state: any) {
    const hasResults = state.selectedTab === "result" && areRacesCompleted(bet);
    return (
        // with forceBetHistory flag we do not get payout. but still should show payout if game is cancelled (it will say "cancelled" then)
        hasResults ||
        (isSneakCompleted(bet, state) && state.showSneak) ||
        state.showWinners ||
        (!bet.checkable && hasPayout(bet)) ||
        (!bet.checkable && bet.game.cancelled)
    );
}

export function hasUnconfirmedResult(bet: any) {
    return areRacesCompleted(bet) && !bet.payments;
}

export function isSneaking(bet: any, state: any) {
    return state.showSneak || bet.betCode;
}

export function isSneakingOrViewingResults(bet: any, state: any) {
    return isSneaking(bet, state) || state.selectedTab === "result";
}

export function hasPayoutDelayed(bet: any, state: any) {
    // for the new HBH, payoutStatus "PENDING" means that the payment was delayed. Thus important to update it or refetch when speed payout is done.
    if (bet.hasNewBetHistory && bet.ownerPayout?.payoutStatus === "PENDING") return true;

    if (state.speedPayoutStatus === "pending" || state.speedPayoutStatus === "success")
        return false;

    const payout = bet.payout ? bet.payout.amount : 0; // bet.payout can be modified after speed payout is done
    const delayedPayout = bet.payoutDelayed ? bet.payoutDelayed.amount : 0;
    // something to verify with Björn before replicating in new ClassicReceipt component.
    return delayedPayout > payout;
}

export function shouldShowAmount(bet: any, state: any) {
    return (
        state.selectedTab === "result" ||
        (!bet.checkable && hasPayout(bet)) ||
        isSneakCompleted(bet, state) ||
        bet.betCode
    );
}

export function arePaymentsUnavailable(bet: any) {
    if (!hasUnconfirmedResult(bet)) return null;

    return "Resultatet är ännu ej fastställt";
}

function isGameCancelled(bet: any) {
    return bet.game.cancelled;
}

export function isCancelledOrNoPayments(bet: any) {
    if (isGameCancelled(bet)) {
        return "Spelet inställt.";
    }

    const paymentsUnavailableMsg = arePaymentsUnavailable(bet);
    if (paymentsUnavailableMsg) {
        return paymentsUnavailableMsg;
    }

    return null;
}

export function getAmount(amount = 0, bet: any, state: any) {
    return shouldShowAmount(bet, state)
        ? toNonBreakingString(formatCurrency(amount, {forceDecimals: true}))
        : "Dolt";
}

export function isSneaked(receipt: any, row: any) {
    return find({id: row.id}, receipt.sneakedRows) !== undefined;
}

export function hideResult(receipt: any, row: any) {
    return !receipt.showSneak || !isSneaked(receipt, row);
}

export function hasUndecidedHorses(bet?: any) {
    if (!bet || !bet.boxedBets) return false;

    return flow(flatten, some("undecided"))(bet.boxedBets);
}

export function createPick(bet: any, unusedReserve: any, sneakState?: any) {
    if (!bet) return null;

    const horse = bet.horse || {};
    const horseName = horse.name || "";

    const pick = {
        number: bet.number,
        banker: false,
        name: horseName,
        win: bet.win,
        scratched: bet.scratched,
        moved: bet.moved,
        given: bet.given,
        isReserve: bet.isReserve,
        isUndecided: bet.undecided,
        unusedReserve,
    };

    if (pick.win) {
        return {
            ...pick,
            ...sneakState,
        };
    }

    return pick;
}

export function getBankerWithName(banker: any, sneakState: any) {
    const horse = banker.horse || {};
    const horseName = horse.name ? horse.name : "";
    const nationality = horse.nationality ? ` (${horse.nationality})` : "";

    const bankerWithName = {
        toPlainText() {
            return `${this.number} ${this.name} ${this.nationality}`;
        },
        number: banker.number,
        name: horseName,
        nationality,
        banker: true,
        win: banker.win,
        scratched: banker.scratched,
        moved: banker.moved,
    };

    if (bankerWithName.win) {
        return {
            ...bankerWithName,
            ...sneakState,
        };
    }

    return bankerWithName;
}

export function getHorsePicks(selection: any, banker: any, sneakState: any) {
    return mapWithIndex(
        (bet: any, index: number) =>
            banker && index === 0
                ? getBankerWithName(bet, sneakState)
                : createPick(bet, false, sneakState),
        selection,
    );
}

export function horses(receipt: any, race: any, bets: any) {
    const betType = receipt.bet.betType || receipt.bet.game.type;
    const betDef = betDefs[betType];
    const reserve = find({isReserve: true}, bets);
    const withoutGiven = without(reserve, bets);
    const banker = bets && withoutGiven.length === 1;
    const getSneakStateFunc = betDef.getSneakState;
    const sneakState = getSneakStateFunc(receipt);
    const picks = getHorsePicks(bets, banker, sneakState[race.id]);
    return {
        hasResult: race.result === true,
        hideResult: receipt.selectedTab === "receipt" && hideResult(receipt, race),
        picks,
    };
}

export function selectColumns(receiptType: any, columns: any) {
    return columns.map((column: any) =>
        column.selectByReceiptType ? column[receiptType] : column,
    );
}

export function computeNoOfCorrectRows(receipt: any) {
    const {bet} = receipt;
    const betDef = betDefs[bet.game.type];

    const {calculateNoOfCorrect} = betDef;
    const noOfCorrect = calculateNoOfCorrect(receipt);
    return {
        correctRows: noOfCorrect,
        totalRows: bet.races.length,
    };
}

export function shouldShowCorrectRowsData(receipt: any) {
    const betDef = betDefs[receipt.bet.game.type];
    const {bet} = receipt;
    const isCheckable = bet.checkable;
    const isReceiptTab = receipt.selectedTab === "receipt";
    const anyRowSneaked = isSneaking(bet, receipt) && receipt.sneakedRows.length > 0;

    return betDef.showNoOfCorrect && isCheckable && (!isReceiptTab || anyRowSneaked);
}

const hasNonGivenReserves = flow(reject("given"), isEmpty);

const noReserves = (bet: any) =>
    flow(
        map("reserves"),
        filter((reserves) => !hasNonGivenReserves(reserves)),
        compact,
    )(bet.races).length === 0;

export function hideColumns(receipt: any, columns: any) {
    const {bet} = receipt;
    return reject((column) => {
        switch (column.id) {
            case "reserves":
                if (bet.cancelled) return noReserves(bet);
                return (
                    noReserves(bet) ||
                    receipt.selectedTab === "result" ||
                    receipt.showSneak ||
                    isCodeBet(bet)
                );
            case "sneak":
                return (
                    receipt.selectedTab === "result" ||
                    !receipt.showSneak ||
                    isCodeBet(bet) ||
                    bet.cancelled
                );
            case "boost-sneak":
                return (
                    receipt.selectedTab === "result" ||
                    !(receipt.showSneak || isCodeBet(bet))
                );
            case "result":
            case "boost-result":
                return receipt.selectedTab === "receipt" || bet.cancelled;
            case "winner":
                return (
                    receipt.selectedTab === "receipt" || isCodeBet(bet) || bet.cancelled
                );
            case "video":
                return (
                    (receipt.selectedTab === "receipt" &&
                        !receipt.showSneak &&
                        !isCodeBet(bet)) ||
                    bet.cancelled
                );
            case "harry-open":
                return (
                    bet.betMethod !== "harry" ||
                    receipt.receiptType !== ReceiptType.Confirm
                );
            case "horses":
                return isCodeBet(bet);
            case "code-horses":
                return !isCodeBet(bet);
            default:
                return false;
        }
    }, columns);
}

export function createReserves(receipt: any, race: any, reserves: any) {
    const numScratched = filter("scratched", race.bets).length;
    const picks = reject("given", reserves).map((bet, index) => {
        const unusedReserve = race && numScratched < index + 1;
        return createPick(bet, unusedReserve);
    });

    return {
        hasResult: race.result === true,
        hideResult: receipt.selectedTab === "receipt" && hideResult(receipt, race),
        picks,
    };
}

export function getBetsWithGivenReserves(betRace: any) {
    const {bets, reserves} = betRace;
    const numScratched = filter("scratched", bets).length;
    const givenReserves = flow(
        filter((reserve: any) => !reserve.scratched),
        map((reserve) => ({
            ...reserve,
            isReserve: true,
        })),
        take(numScratched),
    )(reserves);

    return union(givenReserves, bets);
}

export function activateReserves(receipt: any, race: any) {
    const activate = isSneaked(receipt, race) || receipt.selectedTab === "result";
    if (!activate) return race.bets;

    return getBetsWithGivenReserves(race);
}

export function getColumns(receipt: any) {
    if (!receipt.bet.game) return null;

    const columns = selectColumns(
        receipt.receiptType,
        betDefs[receipt.bet.game.type].columns,
    );
    return hideColumns(receipt, columns);
}

export function hasPendingData(bet: any) {
    return (
        bet &&
        !(
            bet.type === "group" ||
            (areRacesCompleted(bet) && (hasPayout(bet) || bet.payments))
        )
    );
}

export function getRows(receipt: any, columns: any) {
    if (!receipt.bet.game) return null;

    const rowGenerator = betDefs[receipt.bet.game.type].getRows;
    if (!rowGenerator) return null;

    return rowGenerator(receipt, columns);
}

export function createBetReceipt(bet: any, state: any) {
    if (!bet || !bet.races) {
        log.warn("Place bet: Could not create receipt with data:", {bet});
        return null;
    }

    const races = bet.races
        .filter((race: any) => !isEmpty(race))
        .map((race: any, index: number) => ({id: index, ...race}));

    return {
        id: bet.id,
        bet: {
            ...bet,
            races,
        },
        ...state,
    };
}

function createFileBetReceipt(bet: any, state: any) {
    const receipt = {
        id: bet.id,
        bet,
        body: {},
    };
    return mergePlain(receipt, state);
}

export function create(bet: any, state?: any) {
    if (bet.type === "group") return createFileBetReceipt(bet, state);

    return createBetReceipt(bet, state);
}

export function createConfirmReceipt(bet: any) {
    return create(bet, {
        ...DEFAULT_RECEIPT_STATE,
        receiptType: ReceiptType.Confirm,
    });
}

function transformCouponHorses(race: any) {
    function addHorseNames(propName: string) {
        if (!race[propName]) return;
        race[propName] = map((bet) => {
            if (!bet) return null;

            return isObject(bet.horse)
                ? bet
                : {
                      number: bet.number,
                      horse: {name: bet.name},
                  };
        }, race[propName]);
    }

    [
        "bets",
        "baseBets",
        "firstPlaceBets",
        "secondPlaceBets",
        "thirdPlaceBets",
        "reserves",
    ].forEach(addHorseNames);
}

export function createCouponPreviewReceipt(coupon: any) {
    const previewCoupon = cloneDeep(coupon);
    previewCoupon.cost =
        previewCoupon.betMethod === "harry"
            ? previewCoupon.harryBetLimit
            : previewCoupon.betCost;
    previewCoupon.races.forEach(transformCouponHorses);
    previewCoupon.races.forEach((race: any) => {
        race.raceNumber = race.number;
    });
    return createConfirmReceipt(previewCoupon);
}

export function hasScratchedHorses(bet: any) {
    return flow(flatMap("bets"), some("scratched"))(bet.races);
}

export function key(bet: any) {
    const {timestamp, serialNumber} = bet;
    const [date] = timestamp.split("T");
    return [date, serialNumber].join("_");
}

export const mapBetsById = (newBets: any) =>
    reduce(
        (betsById, bet) => {
            const id = bet.id || bet.serialNumber;
            return {
                ...betsById,
                [id]: bet,
            };
        },
        {},
        newBets,
    );

export const mapReceiptsById = (newReceipts: any, receiptState = DEFAULT_RECEIPT_STATE) =>
    reduce(
        (receiptsById, receipt) => {
            const id = receipt.id || receipt.serialNumber;
            return {
                ...receiptsById,
                [id]: create(receipt, receiptState),
            };
        },
        {},
        newReceipts,
    );

export const getBetsToCorrect = (bets: any, game: any) => {
    // @ts-expect-error parseGameId can return null
    const {raceNumber: gameRaceNumber} = parseGameId(game.id);
    return filter((bet) => {
        if (bet.game.status === "settled") return false;
        // @ts-expect-error parseGameId can return null
        const {raceNumber} = parseGameId(bet.game.id);
        return raceNumber === gameRaceNumber;
    }, bets);
};
